const Enum = require('enum');

exports.planCapabilities = new Enum({
  DEVICES_MANAGEMENT: 1,
  LOCATIONS: 2,
  SOFTWARE_INVENTORY: 3,
  HARDWARE_INVENTORY: 4,
  UPDATES_INVENTORY: 5,
  SYSTEM_STATUS: 6,
  BIOS_PASSWORD_MANAGER: 8,
  AMT_MANAGEMENT: 9,
  LOCAL_PASSWORD_MANAGER: 10,
  REMOTE_DESKTOP: 11,
  DISK_ENCRYPTION: 12,
  BOOT_ORDER: 13,
  UWF: 14,
  FIXED_AGENT_VERSION: 15,
  GEOFENCING: 16,
  HARDWARE_EMAIL_REPORT: 17,
});
