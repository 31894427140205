import feathersClient, { makeServicePlugin, BaseModel } from '@/store/feathersClient';

class EmployeeProfiles extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'EmployeeProfiles'
}

const state = {
  hours: { defaultTime: true, startTime: null, endTime: null },
  perimeters: [],
};

const getters = {
  getUpdateHours: (currentState) => currentState.hours,
  getLocationBounds: (currentState) => currentState.perimeters,
};
const actions = {
  updateHours(context, hours) {
    context.commit('updateHours', hours);
  },
  updateLocationBounds(context, data) {
    context.commit('updateLocationBounds', data);
  },
};
const mutations = {
  updateHours(currentStateParam, hours) {
    const currentState = currentStateParam;
    currentState.hours = hours;
    return currentState;
  },
  updateLocationBounds(currentStateParam, data) {
    const currentState = currentStateParam;
    currentState.perimeters = data;
    return currentState;
  },
};

const servicePath = 'employee-profiles';
const servicePlugin = makeServicePlugin({
  Model: EmployeeProfiles,
  service: feathersClient.service(servicePath),
  servicePath,
  state,
  getters,
  actions,
  mutations,
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
