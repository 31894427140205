const Enum = require('enum');

exports.action = new Enum({
  MOUSE_MOVE: 0,
  MOUSE_UP: 1,
  MOUSE_DOWN: 2,
  KEY_UP: 4,
  KEY_DOWN: 5,
  KEY_TAP: 9,
  TYPE_STRING: 6,
  DISCONNECT: 7,
  CAD: 8,
  LOCK: 10,
  MOUSE_SCROLL: 11,
  CHANGE_SCREEN: 12,
  REMOVE_AUDIOCALL: 13,
  RECEIVE_FILE_FROM_AGENT: 15,
  SEND_FILE_TO_AGENT: 16,
  SELECT_FOLDER: 17,
  CANCEL_TRANSFER_FILE: 18,
  VERIFY_FILE_TRANSFER: 19,
});

exports.mouseButton = new Enum({
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
});

exports.ipcAction = new Enum({
  PING: '0',
  PONG: '1',
  EXECUTION: '2',
  EXECUTION_PREFIX: 'EXECUTION-',
  COMMAND: '3',
  COMMAND_PREFIX: 'COMMAND-',
  LAUNCH_SCREEN_RECORDING: '4',
  KILL_SCREEN_RECORDING: '5',
  START_SCREEN_RECORDING: '6',
  SCREEN_SIZE: '7',
  VIDEO_SIZE: '8',
  REMOTE_DESKTOP_ACTION: '9',
  CONNECTION_SUCCESSFUL: '10',
  KILL_YOURSELF: '11',
  CONNECTION: '12',
  REMOTE_DESKTOP_CONNECTION: '13',
  CHILD_CONNECTION: '14',
  ERROR: '15',
  REMOTE_DESKTOP_CHANGE_SCREEN: '16',
  MIC_SAMPLES: '17',
  START_MICROPHONE_RECORDING: '18',
  STOP_MICROPHONE_RECORDING: '19',
  POWERSHELL_COMMAND_PREFIX: 'PWS_COMMAND-',
  POWERSHELL_COMMAND: '20',
  REGISTRY_UTIL_PREFIX: 'REG_UTIL-',
  REGISTRY_UTIL: '21',
  COMMAND_FROM_FILE: '22',
});

exports.handleData = new Enum({
  ERROR: 0,
  SCREEN_CAPTURE_PROCESS_READY: 1,
  SCREENS_INFORMATION: 2,
  DRAW_TEXT_IN_CANVAS: 3,
  RECEIVE_FILE_FROM_AGENT: 5,
  SELECT_FOLDER: 6,
  TRANSFER_FILE_ERROR: 7,
  CANCEL_TRANSFER_FILE: 8,
  FILE_TRANSFER_FINISHED: 9,
  BLOCK_AUDIOCALL: 10,
  UNBLOCK_AUDIOCALL: 11,
  CUSTOM_MESSAGE: 12,
  FILE_TRANSFER_VERIFIED: 13,
});

exports.successResults = new Enum({
  SUCCESSFULLY_CONNECTED: {
    message: 'Remote desktop currently connected.',
    canvasMessage: 'Secure encrypted peer-to-peer tunnel established. Waiting for the remote display image',
  },
  REQUESTING_USER_CONSENT: {
    message: 'Requesting user access.',
    canvasMessage: 'Secure encrypted peer-to-peer tunnel established. Waiting for the remote user to approve the connection',
  },
});

exports.fileTypes = new Enum({
  FILE: 'File',
  FOLDER: 'Folder',
  DISK: 'Disk',
});

exports.notifications = new Enum({
  REQUEST_USER_CONSENT: {
    value: 1,
    name: 'Display user consent confirmation',
  },
  SHOW_NOTIFICATION: {
    value: 2,
    name: 'Only show connection notifications',
  },
});

exports.customMessage = new Enum({
  NO_FULL_HARD_DISK_ACCESS: 1,
});

exports.operation = new Enum({
  REMOTE_DESKTOP: {
    id: 'remoteDesktop',
    transport: 'udp',
  },
  FILE_TRANSFER: {
    id: 'fileTransfer',
    transport: 'tcp',
  },
});
