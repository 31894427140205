import { mapGetters, mapActions } from 'vuex';
import ErrorMixin from './error';

export default {
  name: 'UserMixin',
  mixins: [ErrorMixin],
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    user() {
      return this.$store.getters['auth/user'];
    },
    userIsInACompany() {
      return !!this.currentCompany;
    },
    userHasCompanyAccess() {
      if (this.currentCompany) {
        const userMembershipInStore = this.findMembershipsInStore({
          query: {
            companyId: this.currentCompany.id,
            userId: this.user.id,
            $limit: 1,
          },
        });
        return userMembershipInStore && userMembershipInStore.data.length > 0;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('memberships', { findMemberships: 'find' }),
    async fetchMembershipsFromApi() {
      if (!this.currentCompany || this.user) return;
      this.findMemberships({
        query: {
          $limit: 1,
          userId: this.user.id,
          companyId: this.currentCompany.id,
        },
      });
    },
  },
};
