export default {
  enumToArray(baseEnum) {
    const finalEnum = [];

    Object.keys(baseEnum).forEach((baseEnumKey) => {
      if (baseEnum[baseEnumKey].value !== undefined) {
        const enumValue = {};
        Object.keys(baseEnum[baseEnumKey].value).forEach((enumKeyValue) => {
          enumValue[enumKeyValue] = baseEnum[baseEnumKey].value[enumKeyValue];
        });
        finalEnum.push(enumValue);
      }
    });
    return finalEnum;
  },
};
