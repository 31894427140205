
/* eslint-disable no-underscore-dangle */
class VtulError extends Error {
  constructor(code = '', message = '', name = '') {
    super();

    this._name = name;
    this.message = message;
    this.code = code;
  }

  static throwError(enumError, customMessage = '') {
    const error = this;
    const { code, message, name } = enumError.value;
    error._name = name;
    error.code = code;
    error.message = customMessage || message;
    throw error;
  }
}

module.exports = VtulError;
