const Enum = require('enum');

exports.actionCategory = new Enum({
  POWER: 0,
  COMMANDS_AND_SCRIPTS: 1,
  AMT: 2,
  OS_MANAGEMENT: 3,
  INVENTORY: 4,
  UPDATES: 5,
  PASSWORD_MANAGER: 6,
  LOCAL_PASSWORD: 7,
  BIOS_MANAGEMENT: 8,
  GEOLOCATION_AND_LOCK: 9,
  NOTIFY: 10,
  LOGS: 11,
  ENCRYPTION: 12,
  SOFTWARE: 13,
});
