export default class EvironmentSetter {
  static getEnvironmentByIndex(index) {
    if (process.env[index]) {
      return process.env[index];
    }
    if (window.appConfig && window.appConfig[index]) {
      return window.appConfig[index];
    }
    return '';
  }
}
