const Enum = require('enum');

exports.roles = new Enum({
  OWNER: {
    dbValue: 0,
    name: 'Owner',
  },
  ADMIN: {
    dbValue: 1,
    name: 'Admin',
  },
  FULL_MEMBER: {
    dbValue: 2,
    name: 'Full member',
  },
  SUPPORT_MEMBER: {
    dbValue: 3,
    name: 'Support member',
  },
  REPORTS_VIEWER: {
    dbValue: 4,
    name: 'Reports viewer',
  },
});
exports.origin = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  REGISTER_PAGE: {
    dbValue: 1,
    name: 'Register page',
  },
  CAMPAIGNS: {
    dbValue: 2,
    name: 'Campaigns',
  },
  INVITATION: {
    dbValue: 3,
    name: 'Invitation',
  },
  ACTIVE_DIRECTORY: {
    dbValue: 4,
    name: 'Active directory',
  },
  ROOT_ACCOUNT: {
    dbValue: 5,
    name: 'Root account',
  },
});
