import feathers, { socketio } from '@feathersjs/client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from 'feathers-vuex';
import EnvironmentSetter from '@/helpers/environmentSetter';

const socket = io(EnvironmentSetter.getEnvironmentByIndex('VUE_APP_API'), {
  transports: ['websocket'],
  upgrade: false,
  path: `${EnvironmentSetter.getEnvironmentByIndex('VUE_APP_VERSION_PATH')}socket.io/`,
});

let timeout = 20;
const customTimeout = EnvironmentSetter.getEnvironmentByIndex('VUE_APP_CUSTOM_TIMEOUT');
if (customTimeout) {
  timeout = parseInt(customTimeout, 10);
}

const feathersClient = feathers()
  .configure(socketio(socket, {
    timeout: timeout * 1000,
  }))
  .configure(auth({
    path: '/authentication/users',
    storageKey: 'boardgent-jwt',
  }))
  .hooks({
    before: {
      all: [
        // Prevent saving temp records
        iff(
          (context) => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp'),
        ),
      ],
    },
  });

export default feathersClient;

// Setting up feathers-vuex
const {
  makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex,
} = feathersVuex(feathersClient,
  {
    serverAlias: 'boardgentApi',
    whitelist: ['$not'],
  });

export {
  makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex,
};
