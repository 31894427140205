<template>
  <span>
    <span>{{ timeFromNow }}</span>
    <span v-if="!noDate"> ({{ since | moment(!dateWithoutHours) }})</span>
  </span>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    since: {
      type: String,
      required: true,
    },
    interval: {
      type: Number,
      default: 50000,
    },
    format: {
      type: String,
      default: undefined,
    },
    noFuture: {
      type: Boolean,
      default: false,
    },
    noPast: {
      type: Boolean,
      default: false,
    },
    noDate: {
      type: Boolean,
      default: false,
    },
    dateWithoutHours: {
      type: Boolean,
      default: false,
    },
    withoutSuffix: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeFromNow: null,
    };
  },
  watch: {
    since() {
      this.getTimeFromNow();
    },
  },
  created() {
    this.getTimeFromNow();
    setInterval(this.getTimeFromNow, this.interval);
  },
  destroyed() {
    clearInterval(this.getTimeFromNow);
  },
  methods: {
    getTimeFromNow() {
      if (!this.since) {
        this.timeFromNow = '';
        return;
      }

      if (this.format) {
        const now = moment(new Date(Date.now()));
        const uptime = moment(new Date(this.since));
        this.timeFromNow = `${now.diff(uptime, 'day')}:${moment.utc(now.diff(uptime)).format(this.format)}`;
      } else if (this.noFuture && moment(this.since).isAfter()) {
        this.timeFromNow = moment().fromNow(this.withoutSuffix);
      } else if (this.noPast && moment(this.since).isBefore()) {
        this.timeFromNow = moment().add(1, 'seconds').fromNow(this.withoutSuffix);
      } else {
        this.timeFromNow = moment(this.since).fromNow(this.withoutSuffix);
      }
    },
  },
};
</script>
