const Enum = require('enum');

exports.settings = new Enum({
  GRACE_PERIOD: {
    key: 'b9b58c3f-24d6-47a6-98b5-43a809560e1b',
    name: 'Grace period',
  },
  DEFAULT_CAPABILITIES: {
    key: '8e30572b-06cf-44c0-bfbe-042cc9b6fdad',
    name: 'Default capabilities',
  },
});

exports.views = new Enum({
  DEVICE_GROUPS: 0,
  ROLES: 1,
});

exports.unitsTime = new Enum({
  SECONDS: {
    name: 'Seconds',
    value: 0,
  },
  MINUTES: {
    name: 'Minutes',
    value: 1,
  },
});

exports.parametersToExecuteWithDelay = new Enum({
  NOW: {
    id: 1,
    name: 'Now',
  },
  AFTER_SOME_TIME: {
    id: 2,
    name: 'After some time',
  },
});

exports.foldersToClean = new Enum({
  DOWNLOADS: {
    id: 1,
    name: 'Downloads',
  },
  DOCUMENTS: {
    id: 2,
    name: 'Documents',
  },
  PICTURES: {
    id: 3,
    name: 'Pictures',
  },
  DESKTOP: {
    id: 4,
    name: 'Desktop',
  },
  MUSIC: {
    id: 5,
    name: 'Music',
  },
  VIDEOS: {
    id: 6,
    name: 'Videos',
  },
  // RECYCLE_BIN: {
  //   id: 7,
  //   name: 'Recycle bin',
  // },
});

exports.parametersToDeleteFiles = new Enum({
  MOVE_TO_RECYCLE_BIN: {
    id: 1,
    name: 'Move to Recycle Bin',
  },
  PERMANENTLY_DELETE: {
    id: 2,
    name: 'Permanently delete',
  },
});

exports.frequencies = new Enum({
  OFF: {
    id: 0,
    name: 'Off',
    minutes: 0,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  FIVE_MINUTES: {
    id: 1,
    name: '5 minutes',
    minutes: 5,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  TEN_MINUTES: {
    id: 19,
    name: '10 minutes',
    minutes: 10,
    views: [
      this.views.ROLES.value,
    ],
  },
  FIFTEEN_MINUTES: {
    id: 2,
    name: '15 minutes',
    minutes: 15,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  TWENTY_MINUTES: {
    id: 20,
    name: '20 minutes',
    minutes: 20,
    views: [
      this.views.ROLES.value,
    ],
  },
  THIRTY_MINUTES: {
    id: 3,
    name: '30 minutes',
    minutes: 30,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  ONE_HOUR: {
    id: 4,
    name: 'hour',
    minutes: 60,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  TWO_HOUR: {
    id: 5,
    name: '2 hours',
    minutes: 120,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  FOUR_HOURS: {
    id: 6,
    name: '4 hours',
    minutes: 240,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  HEIGHT_HOURS: {
    id: 7,
    name: '8 hours',
    minutes: 480,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  TWELVE_HOURS: {
    id: 9,
    name: '12 hours',
    minutes: 720,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  TWENTY_FOUR_HOURS: {
    id: 8,
    name: 'day',
    minutes: 1440,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  TWO_DAYS: {
    id: 10,
    name: '2 days',
    minutes: 2880,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  THREE_DAYS: {
    id: 11,
    name: '3 days',
    minutes: 4320,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  FIVE_DAYS: {
    id: 12,
    name: '5 days',
    minutes: 7200,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  ONE_WEEK: {
    id: 13,
    name: 'week',
    minutes: 10080,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  TWO_WEEKS: {
    id: 14,
    name: '2 weeks',
    minutes: 20160,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  ONE_MONTH: {
    id: 15,
    name: 'month',
    minutes: 43800,
    views: [
      this.views.DEVICE_GROUPS.value,
      this.views.ROLES.value,
    ],
  },
  TWO_MONTHS: {
    id: 16,
    name: '2 months',
    minutes: 87600,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  SIX_MONTHS: {
    id: 17,
    name: '6 months',
    minutes: 262800,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
  ONE_YEAR: {
    id: 18,
    name: 'year',
    minutes: 525600,
    views: [
      this.views.DEVICE_GROUPS.value,
    ],
  },
});
