const Enum = require('enum');

exports.operationalApiErrors = new Enum({
  UPLOAD_PROFILE_ERROR: {
    name: 'UPLOAD_PROFILE_ERROR',
    code: 'OAP00001',
    message: 'This device doesn\'t support Intel® AMT.',
  },
  UPDATE_CHANNEL_ERROR: {
    name: 'UPDATE_CHANNEL_ERROR',
    code: 'OAP00002',
    message: 'Could not update channel:',
  },
  CREATE_STRIPE_COMPANY_ERROR: {
    name: 'CREATE_STRIPE_COMPANY_ERROR',
    code: 'OAP00003',
    message: 'Error creating company stripe:',
  },
  VALIDATION_ACCESS_ERROR: {
    name: 'VALIDATION_ACCESS_ERROR',
    code: 'OAP00004',
    message: 'It was not possible to validate user permissions, please try again later.',
  },
  PERMISSION_DENIED: {
    name: 'PERMISSION_DENIED',
    code: 'OAP00005',
    message: 'The user does not have permissions to perform this operation.',
  },
  EMAIL_ALREADY_REGISTERED: {
    name: 'EMAIL_ALREADY_REGISTERED',
    code: 'OAP00006',
    message: 'Email address already registered.',
  },
  INVALID_TOKEN: {
    name: 'INVALID_TOKEN',
    code: 'OAP00007',
    message: 'Invalid token.',
  },
  TOKEN_EXPIRED: {
    name: 'TOKEN_EXPIRED',
    code: 'OAP00008',
    message: 'Token Expired.',
  },
  INVALID_ACTION: {
    name: 'INVALID_ACTION',
    code: 'OAP00009',
    message: 'Invalid action.',
  },
  INVALID_EMAIL: {
    name: 'INVALID_EMAIL',
    code: 'OAP00010',
    message: 'That email address is invalid.',
  },
  NOT_INVITE_YOURSELF: {
    name: 'NOT_INVITE_YOURSELF',
    code: 'OAP00011',
    message: 'You cannot invite yourself.',
  },
  ERROR_SEND_INVITATION: {
    name: 'ERROR_SEND_INVITATION',
    code: 'OAP00012',
    message: 'Unable to send the invitation.',
  },
  INVITATION_NOT_SENT: {
    name: 'INVITATION_NOT_SENT',
    code: 'OAP00013',
    message: 'Unable to send the invitation.',
  },
  UNEXPECTED_INVITATION_ERROR: {
    name: 'UNEXPECTED_INVITATION_ERROR',
    code: 'OAP00014',
    message: 'An unexpected error occurred while trying to send the invitation.',
  },
  GET_AMT_POWER_STATUS_ERROR: {
    name: 'GET_AMT_POWER_STATUS_ERROR',
    code: 'OAP00015',
    message: 'Error getting AMT Power Status.',
  },
  GET_AMT_LOGS_ERROR: {
    name: 'GET_AMT_LOGS_ERROR',
    code: 'OAP00016',
    message: 'Error getting AMT logs.',
  },
  REQUIRE_PARAMS_FOR_GET_CLASS: {
    name: 'REQUIRE_PARAMS_FOR_GET_CLASS',
    code: 'OAP00017',
    message: 'Required define the parameters for the use of Get Class.',
  },
  REQUIRE_PARAMS_FOR_ENUM_CLASS: {
    name: 'REQUIRE_PARAMS_FOR_ENUM_CLASS',
    code: 'OAP00018',
    message: 'Required define the parameters for the use of Enum Class.',
  },
  MPS_SERVER_CONNECTION_ERROR: {
    name: 'MPS_SERVER_CONNECTION_ERROR',
    code: 'OAP00019',
    message: 'Error connecting to the Intel® AMT MPS Server.',
  },
  DEVICE_NOT_CONNECTED: {
    name: 'DEVICE_NOT_CONNECTED',
    code: 'OAP00020',
    message: 'This device is not connected right now.',
  },
  ERROR_CONNECTING_AMT: {
    name: 'ERROR_CONNECTING_AMT',
    code: 'OAP00021',
    message: 'Error connecting to Intel® AMT, please execute “Add Boardgent MPS Server” to confirm the credential.',
  },
  AMT_CIRA_UNSUPPORTED: {
    name: 'AMT_CIRA_UNSUPPORTED',
    code: 'OAP00022',
    message: 'This device does not support Intel® AMT CIRA.',
  },
  AMT_CREDENTIALS_UNSTORED: {
    name: 'AMT_CREDENTIALS_UNSTORED',
    code: 'OAP00015',
    message: 'Intel® AMT Credentials are not stored in Boardgent.',
  },
  UNCONFIGURE_INTEL_AMT: {
    name: 'UNCONFIGURE_INTEL_AMT',
    code: 'OAP00023',
    message: 'Intel® AMT is not configured in this device.',
  },
  UNSUPPORTED_INTEL_AMT: {
    name: 'UNSUPPORTED_INTEL_AMT',
    code: 'OAP00024',
    message: 'Intel® AMT is not supported in this device.',
  },
  ERROR_GET_AMT_INFO: {
    name: 'ERROR_GET_AMT_INFO',
    code: 'OAP00025',
    message: 'Error getting AMT Info.',
  },
  ERROR_GET_BIOS_INFO: {
    name: 'ERROR_BIOS_AMT_INFO',
    code: 'OAP00026',
    message: 'Error getting BIOS Info.',
  },
  OFFLINE_DEVICE: {
    name: 'OFFLINE_DEVICE',
    code: 'OAP00027',
    message: 'Automatically canceled because the device was offline and the parameter \'Run only in online devices\' was enabled.',
    messageShort: 'Canceled because the device was offline',
  },
  EXPIRED_INVITATION: {
    name: 'EXPIRED_INVITATION',
    code: 'OAP00028',
    message: 'This invite link is not longer active',
  },
  INVALID_INVITATION: {
    name: 'INVALID_INVITATION',
    code: 'OAP00029',
    message: 'This invite link is not valid',
  },
  IS_ALREADY_INVITED: {
    name: 'IS_ALREADY_INVITED',
    code: 'OAP00030',
    message: 'The user has already been invited',
  },
  INVALID_EMAIL_FOR_INVITATION: {
    name: 'INVALID_EMAIL_FOR_INVITATION',
    code: 'OAP00031',
    message: 'This email is not valid for this invitation',
  },
  NEED_USER_CREATION: {
    name: 'NEED_USER_CREATION',
    code: 'OAP00032',
    message: 'The user must register to complete the linking process.',
  },
  INVITATION_RECENTLY_SEND: {
    name: 'INVITATION_RECENTLY_SEND',
    code: 'OAP00033',
    message: 'The invitation was reccently sent.',
  },
  VERIFIER_IS_NOT_WORKING: {
    name: 'VERIFIER_IS_NOT_WORKING',
    code: 'OAP00034',
    message: 'We can not validate your email at this moment.',
  },
  INVALID_COMPANY_TOKEN: {
    name: 'INVALID_COMPANY_TOKEN',
    code: 'OAP00035',
    message: 'Invalid Company Token.',
  },
  UNABLE_UPDATE_AMT_INFO: {
    name: 'UNABLE_UPDATE_AMT_INFO',
    code: 'OAP00036',
    message: 'Unable to update amt-info.',
  },
  UNAUTHORIZE_ACCESS: {
    name: 'UNAUTHORIZE_ACCESS',
    code: 'OAP00037',
    message: 'You are not authorized to access this company.',
  },
  MISSING_COMPANY_ID: {
    name: 'MISSING_COMPANY_ID',
    code: 'OAP00038',
    message: 'Missing company ID in the request.',
  },
  DEVICE_NOT_BELOG_TO_COMPANY: {
    name: 'DEVICE_NOT_BELOG_TO_COMPANY',
    code: 'OAP00039',
    message: 'The devices does not belog to the provided company.',
  },
  INVALID_USER_DATA: {
    name: 'INVALID_USER_DATA',
    code: 'OAP00040',
    message: 'Invalid user data.',
  },
  INVALID_PASSWORD: {
    name: 'INVALID_PASSWORD',
    code: 'OAP00041',
    message: 'The current password is not valid.',
  },
  INCORRECT_EMAIL: {
    name: 'INCORRECT_EMAIL',
    code: 'OAP00042',
    message: 'Incorrect email or password.',
  },
  AUTHENTICATION_ERROR: {
    name: 'AUTHENTICATION_ERROR',
    code: 'OAP00043',
    message: 'An authentication error has occurred.',
  },
  MISSING_USER_ID: {
    name: 'MISSING_USER_ID',
    code: 'OAP00044',
    message: 'Missing user ID in the request.',
  },
  INVALID_USER_TOKEN: {
    name: 'INVALID_USER_TOKEN',
    code: 'OAP00045',
    message: 'Invalid user token.',
  },
  COMPANY_HAS_NO_ACTIVE_SUBSCRIPTION: {
    name: 'COMPANY_HAS_NO_ACTIVE_SUBSCRIPTION',
    code: 'OAP00046',
    message: 'The company has no active subscription.',
  },
  DEVICE_LIMIT_REACHED: {
    name: 'DEVICE_LIMIT_REACHED',
    code: 'OAP00047',
    message: 'Device limit reached.',
  },
  INVALID_PARAMETERS: {
    name: 'INVALID_PARAMETERS',
    code: 'OAP00048',
    message: 'Invalid or insufficient parameters to execute this action.',
  },
  INVALID_ACTION_FOR_PLAN: {
    name: 'INVALID_ACTION_FOR_PLAN',
    code: 'OAP00049',
    message: 'You need to upgrade to one of our paid plans to execute this action.',
  },
  INCORRECT_OTP: {
    name: 'INCORRECT_OTP',
    code: 'OAP00050',
    message: 'Two-factor authentication failed.',
  },
  CANCELED_EXECUTION: {
    name: 'CANCELED_EXECUTION',
    code: 'OAP00051',
    message: 'Execution canceled by a user.',
  },
  INVALID_REASON: {
    name: 'INVALID_REASON',
    code: 'OAP00052',
    message: 'The reason to get this information is required.',
  },
  TOO_MANY_REQUEST: {
    name: 'TOO_MANY_REQUEST',
    code: 'OAP00053',
    message: 'Too many requests.',
  },
  ERROR_ENABLING_SUPPORT_ACCESS_TEAM: {
    name: 'ERROR_ENABLING_SUPPORT_ACCESS_TEAM',
    code: 'OAP00054',
    message: 'Error enabling access to the support team',
  },
  ERROR_DISABLING_SUPPORT_ACCESS_TEAM: {
    name: 'ERROR_DISABLING_SUPPORT_ACCESS_TEAM',
    code: 'OAP00055',
    message: 'Error removing the access to the support team',
  },
  ERROR_GETTING_DEFAULT_GROUP: {
    name: 'ERROR_GETTING_DEFAULT_GROUP',
    code: 'OAP00056',
    message: 'Error getting company default group',
  },
  ERROR_PARSER_STRING_TO_INT_HARDWARE_TYPE: {
    name: 'ERROR_PARSER_STRING_TO_INT_HARDWARE_TYPE',
    code: 'OAP00057',
    message: 'Error updating data type',
  },
  DEVICE_NOT_REGISTER: {
    name: 'DEVICE_NOT_REGISTER',
    code: 'OAP00058',
    message: 'Device not found in database',
  },
  WAITING_FOR_APPROVAL: {
    name: 'WAITING_FOR_APPROVAL',
    code: 'OAP00059',
    message: 'The device is waiting for approval',
  },
  DEVICE_NOT_APPROVED: {
    name: 'DEVICE_NOT_APPROVED',
    code: 'OAP00060',
    message: 'The device was not approved',
  },
  ERROR_GETTING_DEVICE_SIGNATURE: {
    name: 'ERROR_GETTING_DEVICE_SIGNATURE',
    code: 'OAP00061',
    message: 'Error getting device signature',
  },
  INVALID_DEVICE_CERTIFICATE: {
    name: 'INVALID_DEVICE_CERTIFICATE',
    code: 'OAP00062',
    message: 'The certificate is not valid to authenticate the device',
  },
  DEVICE_NOT_REGISTER_WAITING_FOR_APPROVAL: {
    name: 'DEVICE_NOT_REGISTER_WAITING_FOR_APPROVAL',
    code: 'OAP00063',
    message: 'Device not found in database, waiting for approval.',
  },
  ERROR_UNKNOWN_REPORT_TYPE: {
    name: 'ERROR_UNKNOWN_REPORT_TYPE',
    code: 'OAP00064',
    message: 'Unknown report type.',
  },
});

exports.programmerApiErrors = new Enum({
  INSUFFICIENT_DATA: {
    name: 'INSUFFICIENT_DATA',
    code: 'PAP00001',
    message: 'Insufficient data.',
  },
  GENERAL_SIGNUP_ERROR: {
    name: 'GENERAL_SIGNUP_ERROR',
    code: 'PAP00002',
    message: 'An error prevented signup.',
  },
  NO_PARAMS_TO_ASSOCIATE: {
    name: 'NO_PARAMS_TO_ASSOCIATE',
    code: 'PAP00003',
    message: 'There is no user or device to associate.',
  },
  GENERAL_ERROR: {
    name: 'GENERAL_ERROR',
    code: 'PAP00004',
    message: 'An error has ocurred, please try again later.',
  },
  GET_EMAILS_ERROR: {
    name: 'GET_EMAILS_ERROR',
    code: 'PAP00005',
    message: 'Invalid email or emails data.',
  },
  UNFOUND_PREVIOUS_INVITATION: {
    name: 'UNFOUND_PREVIOUS_INVITATION',
    code: 'PAP00006',
    message: 'Previous invitation unfounded.',
  },
  UNEXPECTED_CHANGE_PASSWORD_ERROR: {
    name: 'UNEXPECTED_CHANGE_PASSWORD_ERROR',
    code: 'PAP00007',
    message: 'Unexpected error when changing credentials.',
  },
  ERROR_ASSIGNING_MEMBERSHIP: {
    name: 'ERROR_ASSIGNING_MEMBERSHIP',
    code: 'PAP00008',
    message: 'Error assigning membership.',
  },
  ERROR_AUTHENTICATING_WITH_AD: {
    name: 'ERROR_AUTHENTICATING_WITH_AD',
    code: 'PAP00009',
    message: 'Error authenticating user with AD.',
  },
  ERROR_UPLOADING_FILES_TO_STORAGE: {
    name: 'ERROR_UPLOADING_FILES_TO_STORAGE',
    code: 'PAP00010',
    message: 'Unexpected error uploading file',
  },
});
