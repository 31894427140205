import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import store from '@/store';
import feathersClient from '@/store/feathersClient';
import Subscription from '@/helpers/subscription';
import { version } from '../../package.json';

import EnvrionmentSetter from '../helpers/environmentSetter';
import rolesEnum from '../../../cross/src/roles.enum';
import { httpErrorCodes } from '../../../cross/src/errors/httpErrorCodes';

// Neccesary to prevent router exceptions in router push/replace. We saw exceptions in login and
// executeAction views. See: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/login',
    component: Vue.component('login', () => import('@/views/Login.vue')),
    meta: { requiresAuth: false },
  },
  {
    path: '/register/:token?/:companyId?',
    component: Vue.component('register', () => import('@/views/Register.vue')),
    meta: { requiresAuth: false },
  },
  {
    name: 'createcompany',
    path: '/createcompany',
    component: Vue.component('createCompany', () => import('@/views/CreateCompany.vue')),
    meta: { requiresAuth: true, requiresCompany: false, requiresSubscription: false },
  },
  {
    name: 'user',
    path: '/user',
    component: Vue.component('user', () => import('@/views/User.vue')),
    meta: { requiresAuth: true, requiresCompany: false, requiresSubscription: false },
    events: ['user'],
  },
  {
    path: '/resetpwd/:token',
    component: Vue.component('resetPassword', () => import('@/views/ResetPassword.vue')),
    meta: { requiresAuth: false, requiresCompany: false, requiresSubscription: false },
  },
  {
    path: '/verifyemail/:token',
    component: Vue.component('verifyEmail', () => import('@/views/VerifyEmail.vue')),
    meta: { worksLoggedInAndLoggedOut: true },
  },
  {
    path: '/companyinvite/:token',
    component: Vue.component('companyInvite', () => import('@/views/CompanyInvitation.vue')),
    meta: { worksLoggedInAndLoggedOut: true },
  },
  {
    name: 'companies',
    path: '/companies',
    component: Vue.component('changeCompany', () => import('@/views/ChangeCompany.vue')),
    meta: { requiresAuth: true, requiresCompany: true, requiresSubscription: false },
  },
  {
    path: '/:companyId/unauthorized',
    component: Vue.component('unauthorizedView', () => import('@/views/UnauthorizedView.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
    },
  },
  {
    path: '/:companyId',
    redirect: { name: 'devices' },
  },
  {
    path: '/:companyId/dashboard',
    redirect: { name: 'devices' },
  },
  {
    name: 'settings',
    path: '/:companyId/settings',
    component: Vue.component('companySettings', () => import('@/views/CompanySettings.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: false,
      userPermissions: {
        allows: [
          rolesEnum.permissions.COMPANY_SETTINGS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'billing',
    path: '/:companyId/billing',
    component: Vue.component('billing', () => import('@/views/Billing.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: false,
      requiresBgCloud: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.BILLING.value.dbValue,
        ],
        denegate: [
        ],
      },
    },
  },
  {
    name: 'upgradeplan',
    path: '/:companyId/upgradeplan',
    component: Vue.component('expiredSubscription', () => import('@/views/ExpiredSubscription.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: false,
    },
  },
  {
    name: 'selectplan',
    path: '/:companyId/selectplan',
    component: Vue.component('expiredSubscription', () => import('@/views/ExpiredSubscription.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: false,
    },
  },
  {
    name: 'team',
    path: '/:companyId/companyusers',
    component: Vue.component('companyUsers', () => import('@/views/CompanyUsers.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: false,
      events: ['invitations', 'memberships'],
      userPermissions: {
        allows: [
          rolesEnum.permissions.BOARDGENT_USERS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'groups',
    path: '/:companyId/groups/:page?',
    component: Vue.component('employeesProfile', () => import('@/views/EmployeesProfile.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      events: ['employee-profiles'],
      userPermissions: {
        allows: [
          rolesEnum.permissions.DEVICE_GROUPS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'group',
    path: '/:companyId/group/:profileId',
    component: Vue.component('createProfile', () => import('@/views/CreateProfile.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
    },
    userPermissions: {
      allows: [
        rolesEnum.permissions.DEVICE_GROUPS.value.dbValue,
        rolesEnum.permissions.MODIFY_DEVICE_GROUPS.value.dbValue,
      ],
    },
  },
  {
    name: 'duplicategroup',
    path: '/:companyId/group/:profileId/:duplicate',
    component: Vue.component('createProfile', () => import('@/views/CreateProfile.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
    },
    userPermissions: {
      allows: [
        rolesEnum.permissions.DEVICE_GROUPS.value.dbValue,
        rolesEnum.permissions.MODIFY_DEVICE_GROUPS.value.dbValue,
      ],
    },
  },
  {
    name: 'creategroup',
    path: '/:companyId/creategroup',
    component: Vue.component('createProfile', () => import('@/views/CreateProfile.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.MODIFY_DEVICE_GROUPS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'devices',
    path: '/:companyId/devices/:page?',
    component: Vue.component('deviceList', () => import('@/views/DeviceList.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.DEVICE_LIST.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'device',
    path: '/:companyId/device/:deviceId/:tab?',
    component: Vue.component('deviceDetails', () => import('@/views/DeviceDetails.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      events: ['device'], // Subscribe to a single device
      userPermissions: {
        allows: [
          rolesEnum.permissions.DEVICE_DETAILS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'executions',
    path: '/:companyId/executions/:page?',
    component: Vue.component('executions', () => import('@/views/Executions.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      events: ['execution-groups', 'executions'],
      userPermissions: {
        allows: [
          rolesEnum.permissions.SEE_EXECUTIONS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'admin-log',
    path: '/:companyId/adminLog/:page?',
    component: Vue.component('executions', () => import('@/views/AdminLog.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      events: ['admin-log'],
      userPermissions: {
        allows: [
          rolesEnum.permissions.SEE_EXECUTIONS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'map',
    path: '/:companyId/map/:page?',
    component: Vue.component('companyLocations', () => import('@/views/CompanyLocations.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.LOCATIONS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'addDevice',
    path: '/:companyId/addDevice',
    component: Vue.component('AddDevice', () => import('@/views/AddDevice.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
    },
  },
  {
    name: 'roles',
    path: '/:companyId/roles/:page?',
    component: Vue.component('roles', () => import('@/views/Roles.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      events: ['roles'],
      userPermissions: {
        allows: [
          rolesEnum.permissions.MODIFY_ROLES.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'createRole',
    path: '/:companyId/createrole',
    component: Vue.component('createRole', () => import('@/views/ManageRoles.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.MODIFY_ROLES.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'editRole',
    path: '/:companyId/editRole/:roleId',
    component: Vue.component('editRole', () => import('@/views/ManageRoles.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.MODIFY_ROLES.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'exports',
    path: '/:companyId/exports',
    component: Vue.component('exports', () => import('@/views/Exports.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
      userPermissions: {
        allows: [
          rolesEnum.permissions.EXPORT_REPORTS.value.dbValue,
        ],
      },
    },
  },
  {
    name: 'devicesToBeApproved',
    path: '/:companyId/devicesToBeApproved',
    component: Vue.component('exports', () => import('@/views/DevicesToBeApprovedList.vue')),
    meta: {
      requiresAuth: true,
      requiresCompany: true,
      requiresSubscription: true,
    },
  },
];

const executeRoute = {
  component: Vue.component('executeAction', () => import('@/views/ExecuteAction.vue')),
  meta: {
    requiresAuth: true,
    requiresCompany: true,
    requiresSubscription: true,
    userPermissions: {
      allows: [
        rolesEnum.permissions.POWER_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.INVENTORY_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.SOFTWARE_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.UPDATE_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.OS_MANAGEMENT_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.COMMANDS_AND_SCRIPTS.value.dbValue,
        rolesEnum.permissions.AMT_MANAGEMENT_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.BIOS_MANAGEMENT_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.NOTIFY_EXECUTIONS.value.dbValue,
        rolesEnum.permissions.LOGS_EXECUTIONS.value.dbValue,
      ],
    },
    events: ['amt-info'],
  },
};

const executeMultiDevices = { ...executeRoute };
executeMultiDevices.name = 'execute';
executeMultiDevices.path = '/:companyId/execute/:actionTab?/:tabPage?';
routes.push(executeMultiDevices);

const executeSigleDevice = { ...executeRoute };
executeSigleDevice.name = 'executedevice';
executeSigleDevice.path = '/:companyId/executedevice/:actionTab?/:tabPage?';
routes.push(executeSigleDevice);

const executionRoute = {
  component: Vue.component('executionDetails', () => import('@/views/ExecutionDetails.vue')),
  meta: {
    requiresAuth: true,
    requiresCompany: true,
    requiresSubscription: true,
    events: ['executions'],
  },
};

const executionFromLog = { ...executionRoute };
executionFromLog.name = 'execution';
executionFromLog.path = '/:companyId/execution/:executionGroupId/:page?';
routes.push(executionFromLog);

const executionFromDevices = { ...executionRoute };
executionFromDevices.name = 'executiondevices';
executionFromDevices.path = '/:companyId/executiondevices/:executionGroupId/:page?';
routes.push(executionFromDevices);

const executionFromDevice = { ...executionRoute };
executionFromDevice.name = 'executiondevice';
executionFromDevice.path = '/:companyId/executiondevice/:executionGroupId/:page?';
routes.push(executionFromDevice);

Vue.use(Router);
const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (from.params.tab || from.params.page || from.params.actionTab || from.params.tabPage) {
      return {};
    }
    return { x: 0, y: 0 };
  },
  routes,
});

Sentry.init({
  Vue,
  dsn: 'https://cc3c5241d4e04e978c5d1987833d8471@sentry.io/1243231',
  release: version,
  environment: process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'boardgent.com', /^\//],
    }),
  ],
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error
      && error.code
      && httpErrorCodes.enums.find((errorCode) => errorCode.value === error.code)
    ) {
      return null;
    }
    return event;
  },
});

async function userIsAuthenticated() {
  try {
    await store.dispatch('auth/authenticate');
    return true;
  } catch {
    return false;
  }
}

async function getCompanyFromAPI(companyId) {
  try {
    const company = await store.dispatch('companies/get', companyId);
    // We must await the return to generate exception and return false
    return company;
  } catch (error) {
    return false;
  }
}

async function getAllAccessibleCompaniesFromAPI() {
  try {
    const result = await store.dispatch('companies/find');
    return result.data;
  } catch (error) {
    return false;
  }
}

function changeAppChannels(company, to) {
  const currentUser = store.getters['auth/user'];
  if (currentUser) {
    const companyId = to ? to.params.companyId : undefined;
    const events = to && to.meta.events ? to.meta.events : [];
    feathersClient.service('update-channels').update(currentUser.id, {
      devicesIds: to ? [to.params.deviceId] : undefined,
      companyId: companyId || company ? company.id : undefined,
      events: events.concat(['devices-to-be-approved']),
    });
  }
}

function goToCompany(company, to, next) {
  if (to.meta.requiresSubscription
     && !Subscription.validateActiveSubscription(company)) {
    return next(`/${company.id}/upgradeplan`);
  }
  changeAppChannels(company, to);
  return next();
}

/* eslint callback-return: "error" */
router.beforeEach(async (to, from, next) => {
  if (to.meta.worksLoggedInAndLoggedOut) {
    return next();
  }

  const authenticated = await userIsAuthenticated();

  // Define what to do when user visits root
  if (to.path === '/') {
    if (authenticated) return next('/companies');
    return next('/login');
  }

  // If the user is not authenticated
  if (!authenticated) {
    let returnUrl = null;
    if (from.path === '/') {
      if (to && to.redirectedFrom && to.redirectedFrom !== '/') returnUrl = to.redirectedFrom;
      else if (to && to.path && to.path !== '/') returnUrl = to.path;
    }

    // If authentication fails send to login if requiresAuth
    if (to.meta.requiresAuth && returnUrl) {
      return next(`/login?returnUrl=${returnUrl}`);
    }
    return next();
  }

  if (to.meta.userPermissions) {
    let currentMembership = store.getters['memberships/getCurrentMembership'];
    let currentRole = store.getters['roles/getCurrentRole'];
    if (!currentMembership) {
      currentMembership = await feathersClient.service('memberships').find({
        query: {
          $limit: 1,
          companyId: (to.params.companyId) ? to.params.companyId : store.getters['companies/getCurrentCompany'].id,
          userId: store.getters['auth/user'].id,
        },
      });
      store.commit('memberships/changeCurrentMembership', currentMembership);
    }
    if (currentMembership.data.length < 1) {
      // There is not access to that company
      return next('/companies');
    }
    if (!currentRole) {
      currentRole = await feathersClient.service('roles').find({
        query: {
          id: currentMembership.data[0].roleId,
          $limit: 1,
          companyId: (to.params.companyId) ? to.params.companyId : store.getters['companies/getCurrentCompany'].id,
        },
      });
      store.commit('roles/changeCurrentRole', currentRole);
    }
    if (to.meta.userPermissions.allows.length > 0) {
      const { isOwner } = currentMembership.data[0];
      const haveAccess = isOwner ? true : currentRole.data[0].permissions.some(
        (permission) => to.meta.userPermissions.allows.includes(permission),
      );
      if (!haveAccess) {
        return next(`/${to.params.companyId}/unauthorized`);
      }
    }
  }

  // If the user is authenticated and the view not requires authentication
  if (!to.meta.requiresAuth) {
    // If contains returnurl send to that url
    if (to.query.returnUrl) return next(to.query.returnUrl);
    // If not contains return url, send to companies
    return next('/');
  }

  // If Boardgent is running on premise.
  // If the vtul cloud environment variable exits send to that url
  if (to.meta.requiresBgCloud && !EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_CLOUD')) {
    return next('/');
  }

  // If route not requires company, show the page
  if (!to.meta.requiresCompany) return next();

  const companyInStore = store.getters['companies/getCurrentCompany'];

  if (companyInStore) return goToCompany(companyInStore, to, next);

  // Use the companyId in URL
  if (to.params.companyId) {
    const companyFromUrl = await getCompanyFromAPI(to.params.companyId);
    if (!companyFromUrl) return next('/'); // Trying to access to not allowed company
    store.commit('companies/changeCurrentCompany', companyFromUrl);
    return goToCompany(companyFromUrl, to, next);
  }

  const allAccessibleCompanies = await getAllAccessibleCompaniesFromAPI();
  if (!allAccessibleCompanies) {
    // Network error, could not get companies from API
    changeAppChannels();
    return next();
  }
  if (allAccessibleCompanies.length === 0) {
    return next('/createcompany');
  }

  changeAppChannels();
  return next();
});

router.afterEach((to) => {
  const currentUser = store.getters['auth/user'];
  const currentCompany = store.getters['companies/getCurrentCompany'];

  const pathParts = to.fullPath.split('/');
  let pageName = pathParts[1];
  if (pageName.length === 36) {
    [,, pageName] = pathParts;
  }

  const validUser = !currentUser || !currentUser.isTestData;
  const validCompany = !currentCompany || !currentCompany.isTestData;
  if (!validUser || !validCompany) return;
  try {
    // eslint-disable-next-line no-undef
    const localAnalytics = window.analytics;
    if (localAnalytics) {
      localAnalytics.page(pageName, to.params);
    }
  } catch (error) {
    /* Err */
  }
});

export default router;
