import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VeeValidate from 'vee-validate';
import fullscreen from 'vue-fullscreen';
import Vue2Filters from 'vue2-filters';
import PortalVue from 'portal-vue';
import '@mdi/font/css/materialdesignicons.css';
import Buefy from 'buefy';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment';
import bytesSize from 'byte-size';
import axios from 'axios';
import { StatusIndicator } from 'vue-status-indicator';
import io from 'socket.io-client';
import SimpleSignalClient from 'simple-signal-client';
import VueAxios from 'vue-axios';
import autosize from 'autosize';
import VueMeta from 'vue-meta';
import VueShepherd from 'vue-shepherd';
import VueScreen from 'vue-screen';
import IdleVue from 'idle-vue';
import { H } from 'highlight.run';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import router from './router';
import store from './store';
import timeago from './components/cross/Timeago.vue';
import Layout from './Layout.vue';
import EnvrionmentSetter from './helpers/environmentSetter';
import en from '../locale/en';
import { version } from '../package.json';

Vue.config.productionTip = true;

VeeValidate.Validator.localize({ en });

VeeValidate.Validator.extend('verify_devicename', {
  getMessage: 'The new device name must not have special characters or spaces.',
  validate: (value) => {
    const strongRegex = /^(?!.*[\\/:*?"<>|,~!@#$%^&'.(){}_ =+`;\\[])/;
    return strongRegex.test(value);
  },
});

VeeValidate.Validator.extend('verify_emails_list', {
  getMessage: 'Please make sure you have written the emails correctly and separated them by commas or semicolons.',
  validate: (value) => {
    if (value.includes(',') && value.includes(';')) return false;
    const strongRegex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?(,|;))*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
    return strongRegex.test(value);
  },
});

Vue.directive('autosize', (el) => {
  autosize(el.childNodes[0]);
  autosize.update(el.childNodes[0]);
});

Vue.use(PortalVue);
Vue.use(VueShepherd);
Vue.use(VeeValidate);
Vue.use(fullscreen);
Vue.use(Vue2Filters);
Vue.use(VueClipboard);
Vue.use(VueAxios, axios);
Vue.use(StatusIndicator);
Vue.use(VueScreen, 'bulma');
Vue.use(VueMeta);

Vue.use(Buefy, {
  defaultIconPack: 'mdi',
});

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 10 * 60 * 1000,
  startAtIdle: false,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_GOOGLE_MAPS_API_KEY'),
    libraries: 'places',
  },
});

// Global Component Registration
Vue.component('timeago', timeago);
Vue.component('Breadcrumb', Breadcrumb);

// Global Filters
Vue.filter('moment', (date, showTime = true) => {
  if (!date) return '';
  let format = 'LL';
  if (showTime) format = 'LLL';
  return moment(date).format(format);
});

Vue.filter('duration', (ms) => moment.duration(ms).humanize());

Vue.filter('bytesSize', (bytes, metric) => {
  if (!bytes) return '';
  let units = metric;
  if (metric === 'iec') units = 'iecSimple'; // Overwrite iec scale
  return bytesSize(bytes, {
    units,
    precision: 1,
    customUnits: {
      // This scale uses IEC math, not but not show the i letter in short abbreviations
      iecSimple: [
        {
          from: 0, to: 1024 ** 1, unit: 'B', long: 'bytes',
        },
        {
          from: 1024 ** 1, to: 1024 ** 2, unit: 'kB', long: 'kibibytes',
        },
        {
          from: 1024 ** 2, to: 1024 ** 3, unit: 'MB', long: 'mebibytes',
        },
        {
          from: 1024 ** 3, to: 1024 ** 4, unit: 'GB', long: 'gibibytes',
        },
        {
          from: 1024 ** 4, to: 1024 ** 5, unit: 'TB', long: 'tebibytes',
        },
        {
          from: 1024 ** 5, to: 1024 ** 6, unit: 'PB', long: 'pebibytes',
        },
        {
          from: 1024 ** 6, to: 1024 ** 7, unit: 'EB', long: 'exbibytes',
        },
        {
          from: 1024 ** 7, to: 1024 ** 8, unit: 'ZB', long: 'zebibytes',
        },
        {
          from: 1024 ** 8, to: 1024 ** 9, unit: 'YB', long: 'yobibytes',
        },
      ],
    },
  });
});

// Global directives
Vue.directive('focus', {
  inserted(el) {
    el.getElementsByTagName('input')[0].focus();
  },
});

// Global variables
const isCloud = EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_CLOUD');
Vue.prototype.$isBgCloud = isCloud && isCloud === 'true';
Vue.prototype.$websiteUrl = EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_BOARDGENT_WEBSITE');
Vue.prototype.$enrichWorker = EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_ENRICH_WORKER');
Vue.prototype.$adDomain = EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_AD_DOMAIN');
Vue.prototype.$signalServerPassword = EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_SIGNAL_SERVER_PASSWORD');
const signalSocket = io(EnvrionmentSetter.getEnvironmentByIndex('VUE_APP_WEBRTC_SIGNAL'));
Vue.prototype.$signalClient = new SimpleSignalClient(signalSocket, {
  connectionTimeout: 30 * 1000,
});
Vue.prototype.$signalSocket = signalSocket;

// Analytics scripts setup
if (process.env.NODE_ENV === 'production' && isCloud) {
  Vue.prototype.$highlight = H;
  Vue.prototype.$highlight.init('4g8lxjd5', {
    environment: process.env.NODE_ENV,
    version,
    enableSegmentIntegration: false,
    enableCanvasRecording: false,
    backendUrl: 'https://highlight.boardgent.com/',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  });

  // Integrate Highlight with Sentry
  Vue.prototype.$highlight.getSessionURL().then((sessionUrl) => {
    Sentry.setContext('highlight', {
      url: sessionUrl,
    });
  }).catch((error) => { throw new Error(error); });
}

const main = new Vue({
  router,
  store,
  mq: {
    phone: '(max-width: 768px)',
    tablet: '(max-width: 1023px)',
    desktop: '(min-width: 1024px)',
  },
  render: (h) => h(Layout),
}).$mount('#app');

export { main, router };
