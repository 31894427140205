const Enum = require('enum');
const deviceEnum = require('./device.enum');

exports.amtEHBC = new Enum({
  NOT_SUPPORTED: -1,
  UNCONFIGURED: 0,
  DISABLED: 1,
  ENABLED: 2,
});

exports.amtMode = new Enum({
  NOT_SUPPORTED: -1,
  UNCONFIGURED: 0,
  ADMIN_MODE: 1,
  CLIENT_MODE: 2,
  ENTERPRISE_MODE: 3,
});

exports.amtStatus = new Enum({
  NOT_SUPPORTED: {
    dbValue: -2,
    name: 'Not supported',
    color: deviceEnum.deviceStatusColor.GRAY,
    hidden: false,
    displayOrder: 8,
  },
  UNCONFIGURED: {
    dbValue: -1,
    name: 'Not provisoned',
    color: deviceEnum.deviceStatusColor.BLUE,
    hidden: false,
    displayOrder: 7,
  },
  OFFLINE: {
    dbValue: 0,
    name: 'Offline',
    color: deviceEnum.deviceStatusColor.RED,
    hidden: false,
    displayOrder: 2,
  },
  CONNECTING: {
    dbValue: 1,
    name: 'Connecting',
    color: deviceEnum.deviceStatusColor.YELLOW,
    hidden: true,
    displayOrder: 10,
  },
  ONLINE: {
    dbValue: 2,
    name: 'Online',
    color: deviceEnum.deviceStatusColor.GREEN,
    hidden: false,
    displayOrder: 1,
  },
  UNKNOW_CREDENTIALS: {
    dbValue: 3,
    name: 'Unknown credentials',
    color: deviceEnum.deviceStatusColor.YELLOW,
    hidden: false,
    displayOrder: 4,
  },
  CONNECTION_ERROR: {
    dbValue: 4,
    name: 'Connection error',
    color: deviceEnum.deviceStatusColor.YELLOW,
    hidden: false,
    displayOrder: 3,
  },
  CIRA_NOT_SUPPORTED: {
    dbValue: 5,
    name: 'CIRA not supported',
    color: deviceEnum.deviceStatusColor.BLUE,
    hidden: false,
    displayOrder: 6,
  },
  NO_VTUL_MPS_CONFIGURED: {
    dbValue: 6,
    name: 'Boardgent MPS not configured',
    color: deviceEnum.deviceStatusColor.BLUE,
    hidden: false,
    displayOrder: 5,
  },
  AMT_NOT_COMPATIBLE: {
    dbValue: 7,
    name: 'Not supported',
    color: deviceEnum.deviceStatusColor.GRAY,
    hidden: true,
    displayOrder: 9,
  },
});

exports.amtTLS = new Enum({
  NOT_SUPPORTED: {
    dbValue: -1,
    name: 'Not Supported',
  },
  DISABLED: {
    dbValue: 0,
    name: 'Disabled',
  },
  SERVER_ONLY: {
    dbValue: 1,
    name: 'Server Auth Only',
  },
  SERVER_NON_TLS: {
    dbValue: 2,
    name: 'Server Auth And Non Secure Connections',
  },
  MUTUAL_ONLY: {
    dbValue: 3,
    name: 'Mutual Auth Only',
  },
  MUTUAL_NON_TLS: {
    dbValue: 4,
    name: 'Mutual Auth And Non Secure Connections',
  },
});

exports.amtWirelessStatus = new Enum({
  NOT_SUPPORTED: {
    dbValue: -1,
    amtValue: '-',
  },
  UNKNOWN: {
    dbValue: 1,
    amtValue: '-',
  },
  DISABLED: {
    dbValue: 2,
    amtValue: 3,
  },
  ENABLED_TURNED_ON: {
    dbValue: 3,
    amtValue: 32768,
  },
  ENABLED_TURNED_ON_AND_SLEEP: {
    dbValue: 4,
    amtValue: 32769,
  },
});

exports.amtPowerStatus = new Enum({
  POWER_ON: {
    name: 'On',
    amtValue: 2,
  },
  SLEEP_LIGHT: {
    name: 'Sleep - Light',
    amtValue: 3,
  },
  SLEEP_DEEP: {
    name: 'Sleep - Deep',
    amtValue: 4,
  },
  POWER_CYCLE_OFF_SOFT: {
    name: 'Power Cycle (Off Soft)',
    amtValue: 5,
  },
  POWER_OFF_HARD: {
    name: 'Off Hard',
    amtValue: 6,
  },
  HIBERNATE: {
    name: 'Hibernate (Off Soft)',
    amtValue: 7,
  },
  POWER_OFF_SOFT: {
    name: 'Off - Soft',
    amtValue: 8,
  },
  POWER_CYCLE_POWER_OFF_HARD: {
    name: 'Power Cycle (Off Hard)',
    amtValue: 9,
  },
  MASTER_BUS_RESET: {
    name: 'Master Bus Reset',
    amtValue: 10,
  },
  DIAGNOSTIC_INTERRUPT: {
    name: 'Diagnostic Interrupt (NMI)',
    amtValue: 11,
  },
  POWER_OFF_SOFT_GRACEFUL: {
    name: 'Off - Soft Graceful',
    amtValue: 12,
  },
  POWER_OFF_HARD_GRACEFUL: {
    name: 'Off - Hard Graceful',
    amtValue: 13,
  },
  MASTER_BUS_RESET_GRACEFUL: {
    name: 'Master Bus Rest Graceful',
    amtValue: 14,
  },
  POWER_CYCLE_OFF_SOFT_GRACEFUL: {
    name: 'Power Cycle (Off Soft Graceful)',
    amtValue: 15,
  },
  POWER_CYCLE_OFF_HARD_GRACEFUL: {
    name: 'Power Cycle (Off Hard Graceful)',
    amtValue: 16,
  },
});
