import snackBarMessage from '@/helpers/snackBarMessage';
import toastMessage from '@/helpers/toastMessage';
import VtulError from '../../../cross/src/errors/VtulError';
import vtulEnums from '../../../cross/index';

const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'ErrorMixin',
  methods: {
    async throwVtulError(enumError, alertType, currentError, serverMessage = false) {
      const { code, message, name } = enumError.value;
      const uiMessage = serverMessage && currentError
        && currentError.message ? currentError.message : message;
      switch (alertType) {
        case alertBox.SNACKBAR.value:
          snackBarMessage.showError(uiMessage);
          break;
        case alertBox.TOAST.value:
        default:
          toastMessage.showError(uiMessage);
          break;
      }
      const longMessage = `UI message:${message}
        ${currentError && ` - Error message: ${currentError.message}`}`;
      const vtulError = new VtulError(code, longMessage, name);
      throw vtulError;
    },
  },
};
