const Enum = require('enum');

exports.bitlockerStatus = new Enum({
  Unknown: -2,
  None: -1,
  FullyDecrypted: 0,
  FullyEncrypted: 1,
  EncryptionInProgress: 2,
  DecryptionInProgress: 3,
  EncryptionSuspended: 4,
  DecryptionSuspended: 5,
  Locked: 6,
});

exports.protectionStatus = new Enum({
  Unprotected: {
    dbValue: 0,
    name: 'Unprotected',
  },
  Protected: {
    dbValue: 1,
    name: 'Protected',
  },
  Unknown: {
    dbValue: 2,
    name: 'Unknown',
  },
});

exports.bitlockerErrors = new Enum({
  FVE_E_BOOTABLE_CDDVD: {
    code: '2150694960',
    message: 'A bootable CD/DVD is found in this computer. Remove the CD/DVD and restart the action.',
  },
  FVE_E_POLICY_RECOVERY_PASSWORD_NOT_ALLOWED: {
    code: '2150695004',
    message: 'Group Policy settings do not permit the creation of a recovery password.',
  },
  ELEMENT_NOT_FOUND: {
    code: '2147943568',
    message: 'Element not found.',
  },
  ELEMENT_NOT_FOUND_OR_NOT_SIGNED: {
    code: '2147942402',
    message: 'The file you specified could not be found. This may be because it is not signed.',
  },
  THE_DATA_IS_INVALID: {
    code: '2147942413',
    message: 'The data is invalid.',
  },
});


exports.requirementStatus = new Enum({
  BAD: 0,
  GOOD: 1,
  WARNING: 2,
});

// Device encryption status
exports.encryptionStatus = new Enum({
  NotCompatible: {
    dbValue: 0,
    name: 'Not Compatible',
  },
  Compatible: {
    dbValue: 1,
    name: 'Compatible',
  },
  PartiallyEncrypted: {
    dbValue: 2,
    name: 'Partially encrypted',
  },
  Encrypted: {
    dbValue: 3,
    name: 'Encrypted',
  },
  CompatibleWithoutTPM: {
    dbValue: 4,
    name: 'Compatible without TPM',
  },
});

exports.bitLockerAutoReseal = new Enum({
  Enabled: {
    name: 'Yes, change the BitLocker recovery key',
    value: 1,
  },
  Disabled: {
    name: 'No, keep the BitLocker recovery key',
    value: 2,
  },
});

exports.encryptionMethod = new Enum({
  Unknown: {
    dbValue: -1,
    name: 'Unknown',
  },
  None: {
    dbValue: 0,
    name: 'None',
  },
  AES_128_WITH_DIFFUSER: {
    dbValue: 1,
    name: 'AES 128 with Diffuser',
  },
  AES_256_WITH_DIFFUSER: {
    dbValue: 2,
    name: 'AES 256 with Diffuser',
  },
  AES_128: {
    dbValue: 3,
    name: 'AES 128',
  },
  AES_256: {
    dbValue: 4,
    name: 'AES 256',
  },
  HARDWARE_ENCRYPTION: {
    dbValue: 5,
    name: 'Hardware Encryption',
  },
  XTS_AES_128: {
    dbValue: 6,
    name: 'XTS AES 128',
  },
  XTS_AES_256: {
    dbValue: 7,
    name: 'XTS AES 256',
  },
  uint32_1: {
    dbValue: 8,
    name: 'Unknown algorithm',
  },
});

exports.autoUnlockStatus = new Enum({
  Unknown: {
    dbValue: 0,
    name: 'Unknown',
  },
  Enabled: {
    dbValue: 1,
    name: 'Enabled',
  },
  Disabled: {
    dbValue: 2,
    name: 'Disabled',
  },
  NotSupported: {
    dbValue: 3,
    name: 'Not Supported',
  },
});
