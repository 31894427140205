import { SnackbarProgrammatic as Snackbar } from 'buefy';

export default {
  showError(message) {
    Snackbar.open({
      duration: 4000,
      message,
      type: 'is-danger',
      queue: false,
      actionText: null,
    });
  },
  showSuccess(message, action) {
    Snackbar.open({
      duration: 4000,
      message,
      queue: false,
      actionText: action ? action.text : null,
      onAction: () => {
        if (action) action.run();
      },
    });
  },
};
