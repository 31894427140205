import feathersClient, { makeServicePlugin, BaseModel } from '@/store/feathersClient';

class Roles extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Roles'
}

const servicePath = 'roles';

const state = {
  currentRole: null,
};
const getters = {
  getCurrentRole: (currentState) => currentState.currentRole,
};
const actions = {
  changeCurrentRole(context, role) {
    context.commit('changeCurrentRole', role);
  },
  deleteStoredCurrentRole(context, role) {
    context.commit('deleteStoredCurrentRole', role);
  },
};
const mutations = {
  changeCurrentRole(currentStateParam, role) {
    if (role !== Object(role)) return;
    const currentState = currentStateParam;
    currentState.currentRole = role;
  },
  deleteStoredCurrentRole(currentStateParam) {
    const currentState = currentStateParam;
    currentState.currentRole = null;
  },
};

const servicePlugin = makeServicePlugin({
  Model: Roles,
  service: feathersClient.service(servicePath),
  servicePath,
  state,
  getters,
  actions,
  mutations,
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
