<template>
  <div
    id="page-container"
  >
    <NavBar />
    <LogoutModal
      :show-component="showComponent"
      :message="`You have been idle for more than ${ frequenciesArray.find(
        x => x.minutes === userIdleTime/ (60 * 1000) + 10).name}.
      The session will time out in ${secondsToLogout}
      seconds and you will be redirected to login.`"
    />
    <div
      id="main-layout"
      :style="mainLayoutStyle"
    >
      <router-view />

      <template
        v-if="disconnected"
      >
        <ApiLost />
      </template>
    </div>
    <AlertNotification />
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import parseEnum from '@/helpers/parseEnums';
import RoleMixin from '@/mixins/roles';
import analyticsMixin from '@/mixins/analytics';
import NavBar from './components/layout/NavBar.vue';
import LogoutModal from './components/layout/logoutModal.vue';
import ApiLost from './components/errors/ApiLost.vue';
import feathersClient from './store/feathersClient';
import vtulEnums from '../../cross/index';
import AlertNotification from './components/layout/AlertNotification.vue';

const { frequencies } = vtulEnums.enum.settings;

export default {
  name: 'Boardgent',
  components: {
    NavBar,
    ApiLost,
    LogoutModal,
    AlertNotification,
  },
  mixins: [RoleMixin, analyticsMixin],
  data() {
    return {
      feathersClient,
      disconnected: false,
      mainlayoutMargin: { top: '0', left: '0' },
      logoutInterval: null,
      modalInterval: null,
      frequenciesEnum: frequencies,
      userIdleTime: null,
      showComponent: false,
      secondsToLogout: 10,
      frequenciesArray: parseEnum.enumToArray(frequencies),
      countDown: null,
    };
  },
  computed: {
    ...mapState(['mainLayoutStyle']),
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    user() {
      return this.$store.getters['auth/user'];
    },
    currentCompanyId() {
      return this.currentCompany ? this.currentCompany.id : null;
    },
    currentUserRole() {
      if (!this.user || !this.currentCompanyId) {
        return null;
      }
      const { currentRole } = this;
      if (!currentRole) {
        this.fetchCurrentUserMembershipFromApi();
        return null;
      }
      return currentRole;
    },
  },
  watch: {
    disconnected() {
      if (!this.disconnected) {
        this.$router.go();
      }
    },
    currentUserRole: {
      handler(role) {
        this.userIdleTime = null;
        if (role && role.maxIdleTime.enabled) {
          this.userIdleTime = (role.maxIdleTime.frequency - 10) * 60 * 1000;
        }
      },
      deep: true,
    },
  },
  onIdle() {
    if (this.userIdleTime !== null && !this.currentUserIsOwner) {
      const modalTime = this.userIdleTime === 0
        ? this.userIdleTime : this.userIdleTime - (10 * 1000);
      this.modalInterval = setTimeout(() => {
        this.showComponent = true;
        this.countDown = setInterval(() => { this.secondsToLogout -= 1; }, 1000);
      }, modalTime);
      this.logoutInterval = setTimeout(() => {
        this.logoutApp();
      }, this.userIdleTime + (10 * 1000));
    }
  },
  onActive() {
    this.resetIntervals();
  },
  mounted() {
    this.feathersClient.io.on('connect', () => {
      this.disconnected = false;
    });
    this.feathersClient.io.on('reconnect_error', () => {
      this.disconnected = true;
    });
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations(['setMainLayoutStyle', 'clearCurrentSelections']),
    resetIntervals() {
      this.showComponent = false;
      clearTimeout(this.logoutInterval);
      clearTimeout(this.modalInterval);
      clearInterval(this.countDown);
      this.secondsToLogout = 10;
      this.logoutInterval = null;
      this.modalInterval = null;
    },
    logoutApp() {
      this.resetIntervals();
      this.trackEvent(!this.user.isTestData, 'track', 'automatic-logout', {
        email: this.user.email,
        name: `${this.user.name} ${this.user.lastName}`,
        phoneNumber: this.user.phoneNumber,
        emailVerified: this.user.isEmailVerified,
        created_at: new Date(this.user.createdAt),
        origin: this.user.origin,
      });
      this.trackEvent(true, 'reset');
      this.clearCurrentSelections();
      this.logout().then(() => this.$router.go('/login')).catch((error) => { throw new Error(error); });
    },
  },
};
</script>

<style lang="scss">
::selection {
  background-color: #632d8e;
  color: #fff;
}

.title-dropdown {
  color: #000;
}

.card {
  border-radius: 3px !important;
}

.item-dropdown {
  color: rgba(0,0,0,.65);
}

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #632d8e;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$secondary: #38303d;
$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);
$secondary-invert: findColorInvert($secondary);
$boardgent-success: #2aac76;
$boardgent-success-invert: findColorInvert($boardgent-success);
$boardgent-danger: #cd2453;
$boardgent-danger-invert: findColorInvert($boardgent-danger);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')

$colors: mergeColorMaps(
    (
        "white": (
            $white,
            $black,
        ),
        "black": (
            $black,
            $white,
        ),
        "light": (
            $light,
            $light-invert,
        ),
        "dark": (
            $dark,
            $dark-invert,
        ),
        "primary": (
            $primary,
            $primary-invert,
            $primary-light,
            $primary-dark,
        ),
        "secondary": (
            $secondary,
            $secondary-invert,
            $secondary-light,
            $secondary-dark,
        ),
        "link": (
            $link,
            $link-invert,
            $link-light,
            $link-dark,
        ),
        "info": (
            $info,
            $info-invert,
            $info-light,
            $info-dark,
        ),
        "success": (
            $success,
            $success-invert,
            $success-light,
            $success-dark,
        ),
        "warning": (
            $warning,
            $warning-invert,
            $warning-light,
            $warning-dark,
        ),
        "danger": (
            $danger,
            $danger-invert,
            $danger-light,
            $danger-dark,
        ),
    ),
    $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

//Navbar
$navbar-breakpoint: 0;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Import VueShepherd styles
@import '~shepherd.js/dist/css/shepherd.css';

//Import Fonts
@font-face {
  font-family: 'Source Code Pro';
  src: url('./assets/fonts/sourceCodePro/SourceCodePro-Regular.ttf');
}

// ---Global Styles---
strong {
  color: #515151;
}
body, html, #page-container {
  min-height: 100%;
}

.welcome-tour {
  margin-top: -100px !important;
}

.product-tour-correction-in-x {
  margin: 14px 0px !important;
}

.product-tour-correction-in-y {
  margin: 0px 14px !important;
}

.button-to-right {
  margin-left: auto;
}

.b-tabs nav ul .is-active {
  font-weight: bold;
}

// Free material card with animations
.material-card-clickeable:hover {
  animation-name: hardShadow;
  animation-duration: 0.5s;
  -webkit-animation-name: hardShadow;
  -webkit-animation-duration: 0.5s;
  cursor: pointer;
  background-color: #F4F4F4;
  box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 3px 1px , rgba(60, 64, 67, 0.1) 0px 2px 8px 4px;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes hardShadow {
  from {
    background-color: #ffffff;
    box-shadow: rgba(60, 64, 67, 0.08) 0px 1px 1px 0px, rgba(60, 64, 67, 0.16) 0px 1px 3px 1px;
  }
  to {
    background-color: #F4F4F4;
    box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 3px 1px , rgba(60, 64, 67, 0.1) 0px 2px 8px 4px;
  }
}
@keyframes hardShadow {
  from {
    background-color: #ffffff;
    box-shadow: rgba(60, 64, 67, 0.08) 0px 1px 1px 0px, rgba(60, 64, 67, 0.16) 0px 1px 3px 1px;
  }
  to {
    background-color: #F4F4F4;
    box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 3px 1px , rgba(60, 64, 67, 0.1) 0px 2px 8px 4px;
  }
}

// Modal Card
.modal-material-card {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.08) 0px 1px 1px 0px, rgba(60, 64, 67, 0.16) 0px 1px 3px 1px;
}
.modal-card-header {
  padding: 18px 22px 12px;
}
.modal-card-header > p {
  color: #363636;
  font-size: 30px;
  font-weight: 600;
}
.modal-card-footer {
  padding: 10px;
  flex-shrink: 0;
}
.modal-card-form-container {
  max-width: 500px;
}
.modal-card-middle {
  max-width: 800px;
}
.modal-card-small {
  max-width: 500px;
  margin: auto;
}
.modal-card-body-overflow {
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: inherit;
  padding: 20px;
}

// Card
.material-card-content{
  padding: 16px;
}
.material-card {
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.material-card-overflow {
  border-radius: 6px;
  overflow: inherit;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.main-card-form-button {
  width: 100%;
}
.padding-card-footer {
  padding: 10px;
}
.card-form-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 500px;
}
.card-middle-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 800px;
}
.card-container {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  max-width: 1200px;
}

// Other
.force-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: auto;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboardSubtitle {
  color: #4a4a4a;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.25;
}

.centerAll {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.centerVertically {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.no-padding{
  padding: 0;
}

.noselect {
  user-select: none;
}

.no-wrap{
  white-space: nowrap;
  overflow: hidden;
}

@media screen {
  .bgImage {
    /* The image used */
    background-image: url("./assets/img/businessman-mac.jpg");

    position: absolute;
    top: -53px;
    left: 0;
    right: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100vh;
  }

}

.footerLine {
  margin:0.5rem 0;
  background-color:lightgray;
  height: 1px;
}

.link-style{
  padding:0px;
  color:#4a4a4a;
  text-decoration: underline;
  font-weight: 400;
}

.is-bgbuttonred:hover {
  background-color:#632d8e !important;
}

.is-bgbuttonwhite {
  background-color:white !important;
}

.errorBanner {
  margin:0px;
  padding:8px;
}

.pagination-list{
  flex-direction: row;
  justify-content: flex-end;
}

.password-style {
  background-color: #e8e8e8;
  font-size: 13px;
  padding: 4px 12px;
  margin-right:6px;
  border-radius: 5px;
  font-family: 'Source Code Pro', monospace;
  white-space: nowrap
}

@media screen and (max-width: 992px) {
  nav.pagination > a {
    max-width: 10px;
  }
}
.alignIconWithText {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 769px) {
  // Snackbar and Toast bottom are displayed above intercom
  .notices.is-bottom {
    padding-bottom: 78px !important;
  }
  .bgImage {
    left: -260px;
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.status-indicator{
  cursor: default!important
}
</style>
