const Enum = require('enum');
const kvmBehavior = require('./kvm.enum').kvmBehaviorOnExecution;
const actionCategories = require('./action.enum').actionCategory;
const { planCapabilities } = require('./planCapability.enum');
const { dellCommandMonitor } = require('./device.enum');

const paramsToRestartRequired = {
  checkboxText: 'Restart the device to apply the changes',
  name: 'handlerRestart',
  type: 'checkbox',
  default: false,
  position: 'is-centered',
  isRestartCheck: true,
};

exports.executionStatus = new Enum({
  SCHEDULED: 0,
  RUNNING: 1,
  FINISHED: 2,
});

exports.executionHelper = new Enum({
  CANCEL_EXECUTIONS: 0,
  FILTER_EXECUTIONS: 1,
});

exports.executeAction = new Enum({
  SHUTDOWN: {
    id: 1,
    name: 'Shutdown',
    order: 1,
    parameters: false,
    powerAction: true,
    serverSide: false,
    icon: 'power-off',
    help: 'power os',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    executeWithoutDelay: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  RESTART: {
    id: 2,
    name: 'Restart',
    order: 2,
    parameters: false,
    powerAction: true,
    serverSide: false,
    icon: 'power-cycle',
    help: 'power os',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    executeWithoutDelay: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  HIBERNATE: {
    id: 3,
    name: 'Hibernate',
    order: 4,
    parameters: false,
    serverSide: false,
    icon: 'power-standby',
    help: 'power os',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  SLEEP: {
    id: 4,
    name: 'Sleep',
    order: 3,
    parameters: false,
    serverSide: false,
    icon: 'power-sleep',
    help: 'power os',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.DISCONNECT.value,
    category: actionCategories.POWER.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  AMT_PROVISIONING: {
    id: 5,
    name: 'AMT provisioning',
    order: 1,
    parameters: [
      {
        checkboxText: 'Enable KVM redirection after provisioning',
        name: 'enableRedirection',
        type: 'checkbox',
        default: true,
        position: 'is-centered',
      },
      paramsToRestartRequired,
    ],
    serverSide: false,
    icon: 'cog-clockwise',
    help: 'configure provision activate',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.DISCONNECT.value,
    restartRequired: true,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [
      planCapabilities.AMT_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  AMT_UNPROVISIONING: {
    id: 6,
    name: 'AMT unprovisioning',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'cog-counterclockwise',
    help: 'unconfigure acuconfig disable',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.DISCONNECT.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  COMMAND: {
    id: 7,
    name: 'Command prompt/Terminal',
    order: 1,
    parameters: [{
      label: 'Command',
      name: 'command',
      type: 'textarea',
      placeholder: 'Write a command to execute',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'console',
    help: 'cmd bash sh execute',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.COMMANDS_AND_SCRIPTS.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_GEOLOCATION: {
    id: 8,
    name: 'Update geolocation now',
    order: 1,
    parameters: false,
    serverSide: false,
    icon: 'crosshairs-gps',
    help: 'location position gps map',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.GEOLOCATION_AND_LOCK.value,
    requiredCapability: [planCapabilities.LOCATIONS.value],
    onlyForOnPremise: false,
  },
  UPDATE_COMPUTER_INFO: {
    id: 9,
    name: 'Refresh operating system info',
    order: 12,
    parameters: false,
    serverSide: false,
    icon: 'laptop',
    help: 'inventory',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  UPDATE_SOFTWARE_INVENTORY: {
    id: 10,
    name: 'Refresh software inventory',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'apps',
    help: 'inventory',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
    ],
    onlyForOnPremise: false,
    highDatabaseUsage: true,
  },
  UPDATE_HARDWARE_INVENTORY: {
    id: 11,
    name: 'Refresh hardware inventory',
    order: 1,
    parameters: false,
    serverSide: false,
    icon: 'desktop-mac-dashboard',
    help: 'inventory',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
    highDatabaseUsage: true,
  },
  UPDATE_NETWORK_INTERFACES: {
    id: 12,
    name: 'Refresh network interfaces',
    order: 4,
    parameters: false,
    serverSide: false,
    icon: 'expansion-card',
    help: 'inventory',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  UPDATE_AGENT: {
    id: 13,
    name: 'Force agent update',
    order: 1,
    parameters: false,
    serverSide: false,
    icon: 'cloud-sync',
    help: 'upgrade',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    canExecuteWithoutAuthentication: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.UPDATES.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  UPDATE_OPERATING_SYSTEM: {
    id: 14,
    name: 'Update operating system',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'update',
    help: 'update wsus os hotfix',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.UPDATES.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_GROUP_POLICIES: {
    id: 15,
    name: 'Update group policies (GPOs)',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'file-sync',
    help: 'gpo gpupdate',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  WMI: {
    id: 16,
    name: 'WMI',
    order: 3,
    parameters: [{
      label: 'Command',
      name: 'command',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'console',
    help: 'wmic',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.COMMANDS_AND_SCRIPTS.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_USERS: {
    id: 17,
    name: 'Refresh users',
    order: 5,
    parameters: false,
    serverSide: false,
    icon: 'account-multiple',
    help: 'users',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  RENAME_DEVICE: {
    id: 18,
    name: 'Rename device',
    order: 1,
    parameters: [
      {
        label: 'New name',
        name: 'newName',
        type: 'text',
        horizontal: true,
        validate: 'required|min:1|verify_devicename',
      },
      paramsToRestartRequired,
    ],
    serverSide: false,
    warningMessage: 'In Windows, the NetBIOS name of a computer is limited to 15 characters. Windows will crop the NetBIOS name to 15 characters, which may cause name resolution conflicts.',
    icon: 'monitor-edit',
    help: 'change computername',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    restartRequired: true,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  POWERSHELL: {
    id: 19,
    name: 'PowerShell',
    order: 2,
    parameters: [{
      label: 'Command',
      name: 'command',
      type: 'textarea',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'powershell',
    help: 'ps',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.COMMANDS_AND_SCRIPTS.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  LIST_SYSTEM_UPDATES: {
    id: 20,
    name: 'Refresh installed OS updates',
    order: 6,
    parameters: false,
    serverSide: false,
    icon: 'view-list',
    help: 'update wsus os hotfix',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
    highDatabaseUsage: true,
  },
  GET_WINDOWS_FEATURE: {
    id: 21,
    name: 'Check status of Windows feature',
    order: 5,
    parameters: [{
      label: 'Feature',
      name: 'featureName',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'microsoft-windows',
    help: 'windows optional features',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_WINDOWS_FEATURE: {
    id: 22,
    name: 'Enable Windows feature',
    order: 6,
    parameters: [{
      label: 'Feature',
      name: 'featureName',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'cog-clockwise',
    help: 'windows optional features',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_WINDOWS_FEATURE: {
    id: 23,
    name: 'Disable Windows feature',
    order: 7,
    parameters: [{
      label: 'Feature',
      name: 'featureName',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: false,
    icon: 'cog-counterclockwise',
    help: 'windows optional features',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  FREEZE_WINDOWS: {
    id: 24,
    name: 'Freeze Windows',
    order: 22,
    parameters: false,
    serverSide: false,
    informativeMessage: 'This action is only supported on Windows Enterprise and Core editions.',
    icon: 'television-pause',
    help: 'uwfmgr.exe Unified Write Filter',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_FREEZE_WINDOWS: {
    id: 25,
    name: 'Disable freeze Windows',
    order: 24,
    parameters: false,
    serverSide: false,
    informativeMessage: 'This action is only supported on Windows Enterprise and Core editions.',
    icon: 'television-play',
    help: 'uwfmgr.exe Unified Write Filter',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_PROVISION_MODE: {
    id: 26,
    name: 'Get provision mode',
    order: 11,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'CCM ACM client control mode admin control mode',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_EHCB_STATE: {
    id: 27,
    name: 'Get EHCB state',
    order: 12,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'EHBC',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  AMT_DISCOVERY: {
    id: 28,
    name: 'Refresh AMT discovery',
    order: 3,
    parameters: false,
    serverSide: false,
    icon: 'chip',
    help: 'amt vpro AMTDiscovery AcuConfig meshcmd',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ADD_VTUL_MPS: {
    id: 29,
    name: 'Add Boardgent MPS server',
    order: 3,
    requestAmtPassword: true,
    serverSide: false,
    icon: 'cloud-outline',
    help: 'MPS CIRA Certificate',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  SYNCHRONIZE_WIFI_PROFILE: {
    id: 30,
    name: 'Synchronize WiFi profiles',
    order: 13,
    parameters: false,
    serverSide: false,
    icon: 'access-point-network',
    help: 'Sync wifi network',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_USER_CONSENT: {
    id: 31,
    name: 'Get user consent state',
    order: 14,
    parameters: false,
    serverSide: false,
    icon: 'account-key',
    help: 'User consent PIN',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  REMOVE_VTUL_MPS: {
    id: 32,
    name: 'Remove Boardgent MPS server',
    order: 4,
    parameters: false,
    restoreTLS: true,
    serverSide: false,
    icon: 'cloud-off-outline',
    help: 'MPS CIRA Certificate',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_TLS_STATE: {
    id: 33,
    name: 'Get TLS state',
    order: 15,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'AMT TLS SSL certificate',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_KVM_REDIRECTION: {
    id: 34,
    name: 'Get KVM redirection state',
    parameters: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_KVM_REDIRECTION: {
    id: 35,
    name: 'Enable KVM redirection',
    parameters: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_KVM_REDIRECTION: {
    id: 36,
    name: 'Disable KVM redirection',
    order: 6,
    parameters: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  SHOW_NOTIFICATION: {
    id: 37,
    name: 'Show notification to end-user',
    order: 1,
    parameters: [
      {
        label: 'Message',
        name: 'message',
        type: 'textarea',
        horizontal: true,
        validate: 'required',
      },
    ],
    serverSide: false,
    icon: 'message',
    help: 'message alert',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    isvPro: false,
    category: actionCategories.NOTIFY.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_COMPANY_SETTINGS: {
    id: 38,
    name: 'Update Boardgent company settings',
    parameters: false,
    serverSide: false,
    icon: 'cogs',
    help: 'Settings config',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_ENVIRONMENT_DETECTION: {
    id: 39,
    name: 'Get environment detection',
    order: 14,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'Domain Domains CIRA',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CHANGE_USER_CONSENT: {
    id: 40,
    name: 'Change User consent configuration',
    order: 7,
    parameters: false,
    changeOptInState: true,
    serverSide: false,
    icon: 'account-key',
    help: 'User consent PIN',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  // Update with the correct data when this action will be ready for UI
  FORCE_MPS_CONNECTION: {
    id: 41,
    name: 'Force MPS connection',
    order: 16,
    parameters: false,
    serverSide: false,
    icon: 'cloud-refresh',
    help: 'Reconnection AMT',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [
      planCapabilities.AMT_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  GET_AMT_WIRELESS: {
    id: 42,
    name: 'Get AMT wireless state',
    order: 17,
    parameters: false,
    serverSide: false,
    icon: 'access-point-network',
    help: 'WiFi Wi-Fi',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CHANGE_AMT_WIRELESS: {
    id: 43,
    name: 'Change AMT wireless configuration',
    order: 8,
    parameters: false,
    changeAmtWirelessStatus: true,
    serverSide: false,
    icon: 'access-point-network',
    help: 'WiFi Wi-Fi',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  WIPE_DISK: {
    id: 44,
    name: 'Wipe disk',
    order: 3,
    parameters: false,
    serverSide: false,
    warningMessage: 'The action will permanently destroy all data on the selected device; once the execution has started, it cannot be canceled.',
    icon: 'delete-variant',
    help: 'Delete Disk',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CONNECT_REMOTE_DESKTOP: {
    id: 45,
    name: 'Connect remote desktop',
    parameters: [{
      label: 'Offer',
      name: 'offer',
      type: 'text',
      validate: 'required',
    }],
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    hiddenActionResult: false,
    executeWithoutDelay: true,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  // GET_POWER_STATUS: {
  //   id: 46,
  //   order: 19,
  //   parameters: false,
  //   serverSide: false,
  //   isVisibleAction: false,
  //   isVisibleForFilter: false,
  //   isKvmAction: false,
  //   kvmBehavior: kvmBehavior.NONE.value,
  //   isvPro: true,
  //   requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
  // },
  LIST_MPS_SERVERS: {
    id: 47,
    name: 'List MPS servers',
    order: 28,
    parameters: false,
    serverSide: false,
    icon: 'cloud-search-outline',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CHANGE_LOCK_STATUS: {
    id: 48,
    name: 'Change lock status',
    order: 13,
    parameters: false,
    changeLockStatus: true,
    serverSide: false,
    serverAndClientSide: true,
    icon: 'lock',
    help: 'block disable',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    isvPro: false,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_WINDOWS_EVENT_LOGS: {
    id: 49,
    name: 'Get Windows event Log',
    order: 1,
    serverSide: false,
    icon: 'database-arrow-up',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    selectEventLog: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOGS.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CHECK_MACOS_PRIVACY_PERMISSIONS: {
    id: 50,
    name: 'Refresh Mac OS privacy permissions',
    order: 9,
    parameters: false,
    serverSide: false,
    icon: 'lock-open-check',
    help: 'inventory',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UNINSTALL_AGENT: {
    id: 51,
    name: 'Uninstall agent',
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  GET_MICROSOFT_DEFENDER_STATUS: {
    id: 52,
    name: 'Refresh Windows Defender status',
    order: 7,
    parameters: false,
    serverSide: false,
    icon: 'shield-bug',
    help: 'protection',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  GET_FIREWALL_STATUS: {
    id: 53,
    name: 'Refresh Windows Firewall status',
    order: 8,
    parameters: false,
    serverSide: false,
    icon: 'wall',
    help: 'hot',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  GET_UPDATES_STATUS: {
    id: 56,
    name: 'Check OS available updates',
    order: 3,
    parameters: false,
    serverSide: false,
    icon: 'autorenew',
    help: 'fragmentation',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.UPDATES.value,
    requiredCapability: [
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  GET_BIOS_PASSWORD: {
    id: 57,
    name: 'Get BIOS password',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  GET_BIOS_PASSWORD_HISTORY: {
    id: 58,
    name: 'Get BIOS password history',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  GET_LOCAL_ACCOUNT_PASSWORD: {
    id: 59,
    name: 'Get Local administrator password',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  GET_BOOT_ORDER: {
    id: 60,
    name: 'Refresh boot order',
    order: 13,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [planCapabilities.BOOT_ORDER.value],
    onlyForOnPremise: false,
  },
  UPDATE_AGENT_ENVIRONMENT_VARIABLES: {
    id: 61,
    name: 'Update agent environment variables',
    parameters: false,
    serverSide: false,
    icon: 'cogs',
    help: 'Settings config',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  // GET_BIOS_BACKUP_PASSWORD: { // Only for the AD version of password manager
  //   id: 62,
  //   name: 'Get BIOS Backup Password',
  //   order: 0,
  //   parameters: false,
  //   serverSide: true,
  //   isVisibleAction: false,
  //   isVisibleForFilter: false,
  //   isKvmAction: false,
  //   category: actionCategories.PASSWORD_MANAGER.value,
  //   requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
  // },
  // EXECUTE_AD_PASSWORD_MANAGER: { // Only for the AD version of password manager
  //   id: 63,
  //   name: 'Force Password Sync',
  //   order: 0,
  //   parameters: false,
  //   serverSide: false,
  //   icon: 'lock-reset',
  //   help: 'execute',
  //   isVisibleAction: true,
  //   isVisibleForFilter: true,
  //   isKvmAction: false,
  //   category: actionCategories.PASSWORD_MANAGER.value,
  //   requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
  // },
  // EXPIRE_AD_WINDOWS_ADMIN_PASSWORD: { // Only for the AD version of password manager
  //   id: 64,
  //   name: 'Expire Windows Admin Password Using AD',
  //   order: 0,
  //   parameters: true,
  //   serverSide: false,
  //   isVisibleAction: false,
  //   isVisibleForFilter: false,
  //   isKvmAction: false,
  //   category: actionCategories.PASSWORD_MANAGER.value,
  //   requiredCapability: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
  // },
  ENABLE_WINDOWS_AUTO_UPDATE: {
    id: 65,
    name: 'Enable Windows automatic updates',
    order: 5,
    informativeMessage: 'This action is not supported on Windows Server or Home editions.',
    parameters: false,
    serverSide: false,
    icon: 'toggle-switch',
    help: 'automatic',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    category: actionCategories.UPDATES.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_WINDOWS_AUTO_UPDATE: {
    id: 66,
    name: 'Disable Windows automatic updates',
    order: 6,
    informativeMessage: 'This action is not supported on Windows Server or Home editions.',
    parameters: false,
    serverSide: false,
    icon: 'toggle-switch-off-outline',
    help: 'automatic',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    category: actionCategories.UPDATES.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_WINDOWS_AUTO_UPDATE_STATUS: {
    id: 67,
    name: 'Get Windows automatic updates status',
    order: 4,
    informativeMessage: 'This action is not supported on Windows Server or Home editions.',
    parameters: false,
    serverSide: false,
    icon: 'cog-sync',
    help: 'automatic',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    category: actionCategories.UPDATES.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_AGENT_LOGS: {
    id: 68,
    name: 'Get Boardgent agent logs',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'Error message',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    category: actionCategories.LOGS.value,
    requiredCapability: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  CHECK_IF_THERE_IS_A_PASSWORD_IN_BIOS: {
    id: 69,
    name: 'Check if BIOS has password',
    order: 3,
    parameters: false,
    serverSide: false,
    icon: 'key',
    help: 'configured enabled',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  // CHECK_IF_BIOS_PASSWORD_IS_VALID_AD: { // Only for the AD version of password manager
  //   id: 70,
  //   name: 'Validate Current BIOS Password',
  //   order: 2,
  //   parameters: false,
  //   serverSide: false,
  //   icon: 'key-arrow-right',
  //   help: 'check ensure',
  //   isVisibleAction: true,
  //   isVisibleForFilter: true,
  //   isKvmAction: false,
  //   category: actionCategories.PASSWORD_MANAGER.value,
  //   requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
  // },
  // REMOVE_PASSWORD_MANAGER_REGISTRY_KEY_DATES: { // Only for the AD version of password manager
  //   id: 71,
  //   name: 'Delete Password Sync Registries',
  //   order: 3,
  //   parameters: false,
  //   serverSide: false,
  //   icon: 'text-box-remove-outline',
  //   help: 'remove registry keys',
  //   isVisibleAction: false,
  //   isVisibleForFilter: false,
  //   isKvmAction: false,
  //   category: actionCategories.PASSWORD_MANAGER.value,
  //   requiredCapability: [
  //     planCapabilities.BIOS_PASSWORD_MANAGER.value,
  //   ],
  // },
  GET_TPM_STATUS: {
    id: 72,
    name: 'Refresh TPM configuration',
    order: 11,
    parameters: false,
    serverSide: false,
    icon: 'chip',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_DISKS_INFORMATION: {
    id: 73,
    name: 'Refresh disk configuration',
    order: 10,
    parameters: false,
    serverSide: false,
    icon: 'harddisk',
    help: 'fragmentation',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
    highDatabaseUsage: true,
  },
  CREATE_SYSTEM_PARTITION: {
    id: 74,
    name: 'Create system partition',
    order: 4,
    parameters: [paramsToRestartRequired],
    serverSide: false,
    icon: 'harddisk-plus',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    restartRequired: true,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
  },
  FORCE_CHANGE_LOCAL_ACCOUNT_PASSWORD: {
    id: 77,
    name: 'Change OS local administrator password now',
    order: 1,
    icon: 'lock-reset',
    help: 'account',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  FORCE_CHANGE_BIOS_PASSWORD: {
    id: 78,
    name: 'Change BIOS administrator password now',
    order: 2,
    icon: 'lock-reset',
    help: 'boot',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  CHECK_IF_BIOS_PASSWORD_IS_VALID: {
    id: 79,
    name: 'Confirm match between BIOS and Boardgent passwords',
    order: 4,
    parameters: false,
    serverSide: false,
    icon: 'key-arrow-right',
    help: 'check ensure',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  CHANGE_BOOT_TYPE: {
    id: 80,
    name: 'Change boot type',
    order: 1,
    parameters: false,
    changeBootType: true,
    serverSide: false,
    icon: 'ray-start-arrow',
    help: 'uefi bios legacy',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.BIOS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
  },
  VALIDATE_IF_CAN_CONVERT_TO_GPT: {
    id: 81,
    name: 'Validate if can convert disk to GPT',
    order: 24,
    parameters: false,
    serverSide: false,
    icon: 'repeat',
    help: 'fragmentation',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  CONVERT_MBR_TO_GPT: {
    id: 82,
    name: 'Convert disk MBR to GPT',
    order: 8,
    parameters: false,
    serverSide: false,
    icon: 'repeat',
    help: 'fragmentation',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  RESUME_BIOS_MANAGEMENT: {
    id: 83,
    name: 'Confirm BIOS password',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UNINSTALL_ONEDRIVE: {
    id: 84,
    name: 'Uninstall Onedrive',
    order: 10,
    help: 'remove',
    parameters: [paramsToRestartRequired],
    serverSide: false,
    icon: 'cloud-off-outline',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    restartRequired: true,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UNINSTALL_MCAFEE: {
    id: 85,
    name: 'Uninstall McAfee',
    icon: 'shield-off',
    order: 11,
    help: 'Antivirus defender remove',
    parameters: [paramsToRestartRequired],
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    restartRequired: true,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_AMT_PASSWORD: {
    id: 86,
    name: 'Get AMT password',
    order: 5,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_LOCAL_USER_PASSWORD_HISTORY: {
    id: 87,
    name: 'Get local administrator password history',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  CHECK_WMI_CLASSES: {
    id: 88,
    name: 'Check WMI classes',
    order: 0,
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  FETCH_ALL_AVAILABLE_EXECUTIONS: {
    id: 89,
    name: 'Fetch all available executions',
    order: 0,
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
  },
  MANAGE_BOOT_ORDER: {
    id: 90,
    name: 'Force apply boot order',
    icon: 'flag-checkered',
    order: 4,
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.BIOS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.BOOT_ORDER.value],
    onlyForOnPremise: false,
  },
  GET_BACKUP_BIOS_PASSWORD: {
    id: 91,
    name: 'Get BIOS password before restart',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  GET_BITLOCKER_ENCRYPTION_KEYS: {
    id: 92,
    name: 'Get BitLocker encryption keys',
    icon: 'folder-key',
    order: 1,
    help: 'Encrypt keys',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_BITLOCKER_RECOVERY_PASSWORD: {
    id: 93,
    name: 'Get BitLocker recovery password',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_BITLOCKER_RECOVERY_PASSWORD_HISTORY: {
    id: 94,
    name: 'Get BitLocker recovery password history',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  CHANGE_SECURE_BOOT: {
    id: 95,
    name: 'Change secure boot',
    order: 2,
    parameters: false,
    changeSecureBoot: true,
    serverSide: false,
    icon: 'upload-lock',
    help: 'uefi bios legacy boot mode',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.BIOS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
  },
  ENABLE_BITLOCKER: {
    id: 96,
    name: 'Enable BitLocker',
    icon: 'lock',
    order: 3,
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  DISABLE_BITLOCKER: {
    id: 97,
    name: 'Disable BitLocker',
    icon: 'lock-open',
    order: 4,
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  ENABLE_BITLOCKER_AUTOUNLOCK: {
    id: 98,
    name: 'Enable BitLocker auto-unlock',
    icon: 'lock-open',
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  DISABLE_BITLOCKER_AUTOUNLOCK: {
    id: 99,
    name: 'Disable BitLocker auto-unlock',
    icon: 'lock-open',
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  REFRESH_BITLOCKER_REQUIREMENTS: {
    id: 100,
    name: 'Refresh BitLocker requirements',
    icon: 'format-list-checks',
    order: 5,
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  UPDATE_AGENT_DEPENDENCIES: {
    id: 101,
    name: 'Update agent dependencies',
    order: 8,
    parameters: false,
    serverSide: false,
    icon: 'archive-arrow-down-outline',
    help: 'upgrade',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.UPDATES.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  UNINSTALL_ANYDESK: {
    id: 102,
    name: 'Uninstall AnyDesk',
    order: 12,
    help: 'remove',
    parameters: [
      {
        checkboxText: 'Delete all AnyDesk files in this device',
        name: 'removeExe',
        type: 'checkbox',
        default: true,
        position: 'is-centered',
      },
      paramsToRestartRequired,
    ],
    serverSide: false,
    icon: 'remote-desktop',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    restartRequired: true,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CHANGE_TPM_STATUS: {
    id: 103,
    name: 'Change TPM chip configuration',
    order: 3,
    parameters: false,
    changeTpmStatus: true,
    serverSide: false,
    icon: 'chip',
    help: 'enable disable security chip',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.BIOS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    onlyForOnPremise: false,
  },
  LOCK_DEVICE: {
    id: 104,
    name: 'Lock device',
    icon: 'shield-lock',
    lockDevice: 'true',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.GEOLOCATION_AND_LOCK.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  INSTALL_CHROME: {
    id: 105,
    name: 'Install Google Chrome',
    order: 9,
    icon: 'google-chrome',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_BITLOCKER_AUTO_RESEAL: {
    id: 106,
    name: 'Enable reseal BitLocker',
    order: 6,
    help: 'BitLocker',
    serverSide: false,
    icon: 'file-key',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  DISABLE_BITLOCKER_AUTO_RESEAL: {
    id: 107,
    name: 'Disable reseal BitLocker',
    order: 7,
    help: 'BitLocker',
    serverSide: false,
    icon: 'file-key-outline ',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  CHECK_BITLOCKER_AUTO_RESEAL_STATUS: {
    id: 108,
    name: 'Get reseal BitLocker status',
    order: 8,
    help: 'BitLocker',
    serverSide: false,
    icon: 'lock-clock',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  CHECK_IF_BITLOCKER_KEYS_CHANGED: {
    id: 109,
    name: 'Check if BitLocker keys changed',
    order: 9,
    icon: 'keys',
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  GET_OLD_BOARDGENT_AGENT_LOGS: {
    id: 110,
    name: 'Get old Boardgent agent logs',
    order: 4,
    parameters: false,
    serverSide: false,
    icon: 'text-box-search',
    help: 'Error message',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isSupportExecution: false,
    category: actionCategories.LOGS.value,
    requiredCapability: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: true,
  },
  GET_PASSWORDS_FROM_OLD_AGENT: {
    id: 111,
    name: 'Get BIOS passwords from old Boardgent agent',
    order: 5,
    icon: 'key-change',
    help: 'ad active directory history',
    parameters: [
      {
        name: 'useLastPasswordAsCurrentPassword',
        type: 'checkbox',
        default: false,
        checkboxText: 'Use the last password found as the current BIOS password',
      },
      // {
      //   label: 'Force use the last password found as the current BIOS password',
      //   name: 'forceSetLastPassword',
      //   type: 'checkbox',
      //   default: false,
      //   horizontal: false,
      // },
    ],
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOGS.value,
    requiredCapability: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
    ],
    onlyForOnPremise: true,
  },
  FREEZE_OS: {
    id: 112,
    name: 'Enable freeze Windows',
    order: 13,
    parameters: false,
    serverSide: false,
    informativeMessage: 'This action is only supported on Windows Enterprise and Core editions.',
    icon: 'cog-pause-outline',
    help: 'uwfmgr Unified Write Filter',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.UWF.value],
    onlyForOnPremise: false,
  },
  DISABLE_FREEZE_OS: {
    id: 113,
    name: 'Disable freeze Windows',
    order: 14,
    parameters: false,
    serverSide: false,
    informativeMessage: 'This action is only supported on Windows Enterprise and Core editions.',
    icon: 'cog-play-outline',
    help: 'uwfmgr Unified Write Filter',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.UWF.value],
    onlyForOnPremise: false,
  },
  GET_FREEZE_OS_STATUS: {
    id: 114,
    name: 'Get freeze Windows status',
    order: 15,
    parameters: false,
    serverSide: false,
    icon: 'cog-refresh-outline',
    help: 'uwfmgr Unified Write Filter',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.UWF.value],
    onlyForOnPremise: false,
  },
  COMMIT_FREEZE: {
    id: 115,
    name: 'Commit file/registry',
    order: 16,
    parameters: [
      {
        label: 'File/Registry',
        name: 'file',
        type: 'text',
        horizontal: true,
        validate: 'required',
      },
    ],
    serverSide: false,
    icon: 'text-box-plus-outline',
    help: 'uwfmgr Unified Write Filter',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.UWF.value],
    onlyForOnPremise: false,
  },
  COMMIT_DELETE_FREEZE: {
    id: 116,
    name: 'Commit delete file/registry',
    order: 17,
    parameters: [
      {
        label: 'File/Registry',
        name: 'file',
        type: 'text',
        horizontal: true,
        validate: 'required',
      },
    ],
    serverSide: false,
    icon: 'text-box-remove-outline',
    help: 'uwfmgr Unified Write Filter',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.UWF.value],
    onlyForOnPremise: false,
  },
  PAUSE_ENCRYPTION_OR_DECRYPTION: {
    id: 117,
    name: 'Pause encryption or decryption',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  RESUME_ENCRYPTION_OR_DECRYPTION: {
    id: 118,
    name: 'Resume encryption or decryption',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  UNLOCK_PARTITION: {
    id: 119,
    name: 'Unlock partition',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  ADD_TPM_KEY: {
    id: 120,
    name: 'Add TPM Key',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  ENABLE_PROTECTION: {
    id: 121,
    name: 'Enable Protection',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  DISABLE_PROTECTION: {
    id: 122,
    name: 'Disable Protection',
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
  },
  SETUP_WALLPAPER: {
    id: 123,
    name: 'Force apply wallpaper',
    order: 18,
    parameters: [paramsToRestartRequired],
    serverSide: false,
    icon: 'wallpaper',
    help: 'background photo image',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    restartRequired: true,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CLEAN_FOLDERS: {
    id: 124,
    name: 'Clean folders',
    order: 18,
    parameters: false,
    cleanFolders: true,
    serverSide: false,
    icon: 'delete',
    help: 'clean folders',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  RESTART_AGENT: {
    id: 125,
    name: 'Force restart agent',
    order: 20,
    parameters: false,
    serverSide: false,
    icon: 'restart-alert',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  DISTRIBUTE_SOFTWARE: {
    id: 126,
    name: 'Distribute software',
    order: 1,
    parameters: false,
    distributeOwnSoftware: true,
    serverSide: false,
    icon: 'cube-send',
    help: 'share copy pass software',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SOFTWARE_INVENTORY.value],
    onlyForOnPremise: false,
  },
  DISTRIBUTE_FILE: {
    id: 127,
    name: 'Distribute files',
    order: 2,
    parameters: false,
    distributeOwnSoftware: true,
    serverSide: false,
    icon: 'file-send-outline',
    help: 'share copy pass file',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.SOFTWARE.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SOFTWARE_INVENTORY.value],
    onlyForOnPremise: false,
  },
  ENABLE_CONTROL_PANEL: {
    id: 128,
    name: 'Enable control panel',
    order: 21,
    parameters: false,
    serverSide: false,
    icon: 'archive-lock-open-outline',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_CONTROL_PANEL: {
    id: 129,
    name: 'Disable control panel',
    order: 22,
    parameters: false,
    serverSide: false,
    icon: 'archive-lock-outline',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  FORCE_REPORT_AGENT_ONLINE: {
    id: 130,
    name: 'Force report agent online',
    order: 22,
    parameters: false,
    serverSide: false,
    icon: 'archive-lock-outline',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    onlyForOnPremise: false,
  },
  RESUME_LOCAL_ACCOUNT_MANAGEMENT: {
    id: 131,
    name: 'Confirm local account password',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_CMD: {
    id: 132,
    name: 'Enable CMD',
    order: 23,
    parameters: false,
    serverSide: false,
    icon: 'console',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_CMD: {
    id: 133,
    name: 'Disable CMD',
    order: 24,
    parameters: false,
    serverSide: false,
    icon: 'console',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_POWERSHELL: {
    id: 134,
    name: 'Enable PowerShell',
    order: 25,
    parameters: false,
    serverSide: false,
    icon: 'archive-lock-open-outline',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_POWERSHELL: {
    id: 135,
    name: 'Disable PowerShell',
    order: 26,
    parameters: false,
    serverSide: false,
    icon: 'console-network-outline',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  REFRESH_BOOT_ORDER: {
    id: 136,
    name: 'Refresh boot order',
    order: 13,
    parameters: false,
    serverSide: false,
    icon: 'flag-checkered',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    requiredCapability: [planCapabilities.BOOT_ORDER.value],
    onlyForOnPremise: false,
  },
  UPDATE_DEVICE_PASSWORDS_FROM_BACKUP: {
    id: 137,
    name: 'Update device passwords from backup',
    order: 26,
    parameters: false,
    serverSide: false,
    icon: '',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
    ],
    onlyForOnPremise: false,
  },
  DISCLAIMER_ON_SIGN_IN: {
    id: 138,
    name: 'Disclaimer on sign in',
    order: 0,
    parameters: false,
    serverSide: false,
    icon: '',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  TURN_OFF_DISCLAIMER_ON_SIGN_IN: {
    id: 139,
    name: 'Turn off disclaimer on sign in',
    order: 27,
    parameters: false,
    serverSide: false,
    icon: 'message-bulleted-off',
    help: 'login message',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_INSTALL_MSI: {
    id: 140,
    name: 'Do not allow to install MSI packages',
    order: 28,
    parameters: false,
    serverSide: false,
    icon: 'package-variant-closed-remove',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_INSTALL_MSI: {
    id: 141,
    name: 'Allow to install MSI packages',
    order: 29,
    parameters: false,
    serverSide: false,
    icon: 'package-variant-closed-plus',
    help: '',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  NOT_SAVE_PASSWORD_IN_LAN_MANAGER: {
    id: 142,
    name: 'Do not save password in LAN Manager',
    order: 30,
    parameters: false,
    serverSide: false,
    icon: 'lan',
    help: 'password',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  SAVE_PASSWORD_IN_LAN_MANAGER: {
    id: 143,
    name: 'Save password in LAN Manager',
    order: 31,
    parameters: false,
    serverSide: false,
    icon: 'folder-key-network',
    help: 'password',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  LOCK_DEVICE_AFTER_X_TIME_OF_INACTIVITY: {
    id: 144,
    name: 'Lock device after X time of inactivity',
    order: 32,
    parameters: [{
      label: 'Time in seconds',
      name: 'time',
      type: 'text',
      horizontal: true,
    }],
    serverSide: false,
    icon: 'lock',
    help: '',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_LOCK_DEVICE_AFTER_X_TIME_OF_INACTIVITY: {
    id: 145,
    name: 'Disable auto-lock device after inactivity',
    order: 33,
    parameters: [paramsToRestartRequired],
    warningMessage: 'The device should be restarted after running this action.',
    restartRequired: true,
    serverSide: false,
    icon: 'lock-open',
    help: 'idle time',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_LOCAL_ADMIN_USER: {
    id: 146,
    name: 'Disable local administrator user',
    order: 32,
    parameters: false,
    serverSide: false,
    icon: 'account-lock',
    help: 'block administrator account',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_LOCAL_ADMIN_USER: {
    id: 147,
    name: 'Enable local administrator user',
    order: 33,
    parameters: false,
    serverSide: false,
    icon: 'account-lock-open',
    help: 'block administrator account',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  BLOCK_UNSUCCESSFUL_LOGIN: {
    id: 148,
    name: 'Block unsuccessful attempts to login',
    order: 35,
    parameters: false,
    serverSide: false,
    icon: 'lock',
    help: 'login',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UNBLOCK_UNSUCCESSFUL_LOGIN: {
    id: 149,
    name: 'Unblock unsuccessful attempts to login',
    order: 36,
    parameters: false,
    serverSide: false,
    icon: 'lock-open',
    help: 'login',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.OS_MANAGEMENT.value,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CONNECT_FILE_TRANSFER: {
    id: 150,
    name: 'Connect file transfer',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    hiddenActionResult: true,
    executeWithoutDelay: true,
    requiredCapability: [planCapabilities.DEVICES_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  INITIALIZE_TPM: {
    id: 151,
    name: 'Initialize TPM',
    order: 2,
    parameters: false,
    serverSide: false,
    icon: 'shield-key',
    help: 'tpm',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  INSTALL_DELL_COMMAND_MONITOR: {
    id: 152,
    name: 'Install Dell Command | Monitor',
    order: 5,
    parameters: [
      {
        type: 'select',
        name: 'version',
        isAdvance: true,
        checkbox: {
          checkboxText: 'Download an specific version',
          default: false,
        },
        placeholder: 'Select specific version to download',
        options: [
          {
            id: dellCommandMonitor.BIOS_WITHOUT_WMI_ACPI.value.id,
            name: `Version ${dellCommandMonitor.BIOS_WITHOUT_WMI_ACPI.value.version}`,
            text: `Download version ${dellCommandMonitor.BIOS_WITHOUT_WMI_ACPI.value.version}`,
          },
          {
            id: dellCommandMonitor.BIOS_WITH_WMI_ACPI_W7_OR_W8.value.id,
            name: `Version ${dellCommandMonitor.BIOS_WITH_WMI_ACPI_W7_OR_W8.value.version}`,
            text: `Download version ${dellCommandMonitor.BIOS_WITH_WMI_ACPI_W7_OR_W8.value.version}`,
          },
          {
            id: dellCommandMonitor.BIOS_WITH_WMI_ACPI_W10_OR_W11.value.id,
            name: `Version ${dellCommandMonitor.BIOS_WITH_WMI_ACPI_W10_OR_W11.value.version}`,
            text: `Download the latest version available on Boardgent
              (${dellCommandMonitor.BIOS_WITH_WMI_ACPI_W10_OR_W11.value.version})`,
          },
        ],
      },
    ],
    serverSide: false,
    icon: 'application-cog-outline',
    help: 'configured enabled',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.LOCAL_PASSWORD.value,
    requiredCapability: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    onlyForOnPremise: false,
  },
  ENABLE_FILEVAULT: {
    id: 153,
    name: 'Enable FileVault',
    icon: 'lock',
    order: 9,
    help: 'Encrypt keys',
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  DISABLE_FILEVAULT: {
    id: 154,
    name: 'Disable FileVault',
    icon: 'lock-open',
    order: 10,
    help: 'Encrypt keys',
    parameters: [
      {
        label: 'Account name',
        name: 'user',
        type: 'text',
        horizontal: true,
        validate: 'required',
        optional: true,
      },
      {
        label: 'Account password',
        name: 'password',
        type: 'password',
        horizontal: true,
        validate: 'required',
        optional: true,
      },
    ],
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_FILEVAULT_STATUS: {
    id: 155,
    name: 'Update FileVault status',
    icon: 'list-status',
    order: 11,
    parameters: false,
    serverSide: false,
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  CHECK_IF_FILEVAULT_KEY_CHANGED: {
    id: 156,
    name: 'Check if FileVault key changed',
    parameters: false,
    serverSide: false,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_FILEVAULT_KEY: {
    id: 157,
    name: 'Get FileVault key',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  GET_FILEVAULT_KEY_HISTORY: {
    id: 158,
    name: 'Get FileVault keys history',
    order: 0,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    category: actionCategories.ENCRYPTION.value,
    requiredCapability: [planCapabilities.DISK_ENCRYPTION.value],
    onlyForOnPremise: false,
  },
  POWER_ON: {
    id: 1000,
    name: 'Power on',
    order: 5,
    parameters: false,
    serverSide: true,
    icon: 'power-on',
    help: 'power on hardware',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  POWER_OFF: {
    id: 1001,
    name: 'Power off',
    order: 6,
    parameters: false,
    serverSide: true,
    icon: 'power-plug-off',
    help: 'power off shutdown hardware',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.DISCONNECT.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  HARDWARE_RESTART: {
    id: 1002,
    name: 'Hardware restart',
    order: 10,
    parameters: false,
    serverSide: true,
    icon: 'power-plug',
    help: 'power restart reset hardware',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.RESTART.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_AMT_CIM_CLASS: {
    id: 1003,
    name: 'Get AMT CIM class',
    order: 9,
    parameters: [{
      label: 'Class name',
      name: 'className',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: true,
    icon: 'text-box-search',
    help: 'CIM',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENUM_AMT_CIM_CLASS: {
    id: 1004,
    name: 'Enum AMT CIM class',
    order: 10,
    parameters: [{
      label: 'Class name',
      name: 'className',
      type: 'text',
      horizontal: true,
      validate: 'required',
    }],
    serverSide: true,
    icon: 'text-box-search',
    help: 'CIM',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_KVM_REDIRECTION_MPS: {
    id: 1005,
    name: 'Get KVM redirection',
    order: 19,
    parameters: false,
    serverSide: true,
    icon: 'cast',
    help: 'kvm vnc remote',
    isVisibleAction: false,
    isVisibleForFilter: false,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  ENABLE_KVM_REDIRECTION_MPS: {
    id: 1006,
    name: 'Enable KVM redirection',
    order: 5,
    parameters: false,
    serverSide: true,
    icon: 'cast-connected',
    help: 'kvm vnc remote',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  DISABLE_KVM_REDIRECTION_MPS: {
    id: 1007,
    name: 'Disable KVM redirection',
    order: 6,
    parameters: false,
    serverSide: true,
    icon: 'cast-off',
    help: 'kvm vnc remote',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    isSupportExecution: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.AMT.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_INVENTORY_BOARD: {
    id: 1008,
    name: 'Update inventory from board',
    order: 5,
    parameters: false,
    serverSide: true,
    icon: 'desktop-mac-dashboard',
    help: 'hardware inventory',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.INVENTORY.value,
    isvPro: true,
    requiredCapability: [
      planCapabilities.AMT_MANAGEMENT.value,
    ],
    onlyForOnPremise: false,
  },
  GRACEFUL_POWER_OFF: {
    id: 1009,
    name: 'AMT OS shutdown',
    order: 7,
    parameters: false,
    serverSide: true,
    icon: 'power-off',
    help: 'shutdown power off',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.DISCONNECT.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  UPDATE_AMT_LOGS: {
    id: 1010,
    name: 'Refresh AMT Log',
    order: 3,
    parameters: false,
    serverSide: true,
    icon: 'database-refresh',
    help: 'audit logs',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.LOGS.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  BOOT_TO_BIOS: {
    id: 1011,
    name: 'Boot to BIOS',
    parameters: false,
    serverSide: true,
    icon: 'hammer-wrench',
    help: 'start boot restart BIOS UEFI',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.RESTART.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  BOOT_TO_CD_ROM: {
    id: 1012,
    name: 'Boot to CD-ROM',
    parameters: false,
    serverSide: true,
    icon: 'progress-wrench',
    help: 'start boot restart CD DVD',
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: true,
    kvmBehavior: kvmBehavior.RESTART.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  GET_POWER_STATUS_MPS: {
    id: 1013,
    name: 'Get power status',
    order: 8,
    parameters: false,
    serverSide: true,
    icon: 'power-socket',
    isVisibleAction: true,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
  CONNECT_KVM_REMOTE_DESKTOP: {
    id: 1014,
    name: 'Start KVM connection',
    order: 9,
    parameters: false,
    serverSide: true,
    isVisibleAction: false,
    isVisibleForFilter: true,
    isKvmAction: false,
    kvmBehavior: kvmBehavior.NONE.value,
    category: actionCategories.POWER.value,
    isvPro: true,
    requiredCapability: [planCapabilities.AMT_MANAGEMENT.value],
    onlyForOnPremise: false,
  },
});
