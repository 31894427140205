const Enum = require('enum');

exports.locationDetectionMethod = new Enum({
  UNKNOWN: 0,
  IP_ADDRESS: 1,
  NATIVE: 2,
  WIFI: 3,
  NEARBY_DEVICES: 4,
});

exports.locationPerimeterType = new Enum({
  POLYGON: {
    dbValue: 1,
    text: 'Draw polygon',
    icon: 'shape-polygon-plus',
  },
  MARKER: {
    dbValue: 2,
    text: 'Circular',
    icon: 'shape-circle-plus',
  },
});
