<template>
  <section>
    <b-modal
      v-model="showComponent"
      width="40%"
      style="overflow: none"
      :can-cancel="[false,false,false]"
      scroll="clip"
      class="modal-logout"
    >
      <b-message
        :closable="false"
        has-icon
        title="Automatic logout"
        icon="clock-alert"
        aria-close-label="Close message"
        style="border-radius: 8px 8px 4px 4px;"
        class="message-logout"
      >
        {{ message }}
      </b-message>
    </b-modal>
  </section>
</template>

<script>

export default {
  name: 'LogoutModal',
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style>
  .modal-logout .modal-content .message-header div{
    display: flex;
    align-items: center;
    gap:5px;
  }
  .message-logout .message-body {
    background-color: #fffefe;
    color: #393739;
  }
  .message-logout .message-header {
    background-color: #632d8e;
    color: #fff;
  }
</style>
