const Enum = require('enum');

exports.filters = new Enum({
  LOG_NAME: {
    name: 'Log name',
    command: '-LogName',
  },
  NEWEST: {
    name: 'Newest',
    command: '-Newest',
  },
  ENTRY_TYPE: {
    name: 'Entry type',
    command: '-EntryType',
  },
  SOURCE: {
    name: 'Source',
    command: '-Source',
  },
  INSTANCE_ID: {
    name: 'Instance id',
    command: '-InstanceId',
  },
  USER_NAME: {
    name: 'User name',
    command: '-UserName',
  },
  AFTER: {
    name: 'After',
    command: '-After',
  },
  BEFORE: {
    name: 'Before',
    command: '-Before',
  },
  MESSAGE: {
    name: 'Message',
    command: '-Message',
  },
});
