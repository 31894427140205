<template>
  <div v-if="navbarVisible">
    <b-sidebar
      :open.sync="sidebarOpen"
      fullheight
      :can-cancel="!$screen.tablet"
      :overlay="!$screen.tablet"
      type="is-light"
    >
      <b-menu
        :accordion="false"
        :activable="false"
      >
        <b-menu-list>
          <div id="sidebar-close-container">
            <!-- Due buefy limitations some styles are defined inline and other in the id -->
            <b-menu-item
              v-if="companySelected"
              id="company-switcher"
              style="width:100%"
            >
              <template #label>
                <b-dropdown
                  ref="companySwitcherDropdown"
                  aria-role="list"
                  position="is-bottom-right"
                >
                  <template #trigger>
                    <div
                      id="company-switcher-logo-container"
                    >
                      <CompanyLogo
                        id="company-switcher-company-logo"
                        :company-name="currentCompany.name"
                        :url-logo="currentCompany.urlLogo"
                        :lazy="false"
                      />
                      <strong id="company-switcher-company-name">
                        {{ currentCompany.name }}</strong>
                    </div>
                  </template>
                  <b-dropdown-item
                    aria-role="menuitem"
                    has-link
                  >
                    <router-link
                      :to="logoUrl"
                    >
                      Switch company
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-menu-item>
            <b-menu-item
              v-else
              :to="companySelected ? { name: 'devices'} : logoUrl"
              tag="router-link"
              class="sidebarParentMenuItem"
              style="width:100%"
            >
              <template #label>
                <div id="sidebar-boardgent-logo">
                  <img
                    src="@/assets/img/logoName.svg"
                    alt="Boardgent logo"
                  >
                </div>
              </template>
            </b-menu-item>
            <b-menu-item
              id="close-sidebar"
              @click="sidebarOpen = false"
            >
              <template #label>
                <b-tooltip
                  v-show="sidebarOpen"
                  label="Hide sidebar"
                  type="is-dark"
                  position="is-bottom"
                  append-to-body
                >
                  <b-icon
                    icon="chevron-double-left"
                    size="is-small"
                  />
                </b-tooltip>
              </template>
            </b-menu-item>
          </div>
        </b-menu-list>
        <div
          v-if="companySelected"
          class="sidebar-menu-section"
          style="margin-top:30px"
        >
          <b-menu-list label="Workspace">
            <b-menu-item
              :disabled="!hasSubscription"
              icon="laptop"
              :to="{ name: 'devices'}"
              tag="router-link"
              class="sidebarParentMenuItem"
              :expanded="['devices', 'device',
                          'execute', 'executedevice',
                          'executiondevices', 'executiondevice',
                          'addDevice',
                          'groups', 'group', 'creategroup',
                          'devicesToBeApproved', 'duplicategroup'].includes(currentRouteName)"
            >
              <template #label="props">
                Devices
                <b-icon
                  :style="sidebarParentMenuItemIconStyle(props.expanded)"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-menu-item
                :label="devicesCountFetched > 0 || totalDevices > 0
                  ? 'Managed devices': 'Add devices'"
                :to="{ name: 'devices'}"
                tag="router-link"
                :active="['devices', 'device',
                          'execute', 'executedevice',
                          'executiondevices', 'executiondevice'].includes(currentRouteName)"
              />
              <b-menu-item
                v-if="devicesCountFetched > 0 || totalDevices > 0"
                label="Add devices"
                :to="{ name: 'addDevice'}"
                tag="router-link"
                :active="'addDevice' === currentRouteName"
              />
              <b-menu-item
                label="Device groups"
                :to="{ name: 'groups'}"
                tag="router-link"
                :active="['groups', 'group', 'creategroup',
                          'duplicategroup'].includes(currentRouteName)"
              />
              <b-menu-item
                :to="{ name: 'devicesToBeApproved'}"
                tag="router-link"
                :active="['devicesToBeApproved',].includes(currentRouteName)"
              >
                <template #label>
                  Devices to be approved
                  <status-indicator
                    v-if="totalDevicesToBeApproved > 0 || devicesToBeApprovedFetched > 0"
                    style="background-color: #632d8e;"
                  />
                </template>
              </b-menu-item>
            </b-menu-item>
            <b-menu-item
              v-if="$isBgCloud"
              :disabled="!hasSubscription"
              label="Execution log"
              icon="format-list-bulleted-type"
              :to="{ name: 'executions'}"
              tag="router-link"
              :active="['executions', 'execution'].includes(currentRouteName)"
            />
            <b-menu-item
              v-else
              :disabled="!hasSubscription"
              icon="format-list-bulleted-type"
              :to="{ name: 'executions'}"
              tag="router-link"
              class="sidebarParentMenuItem"
              :expanded="['logs',
                          'execution', 'executions', 'admin-log'].includes(currentRouteName)"
            >
              <template #label="props">
                Logs
                <b-icon
                  :style="sidebarParentMenuItemIconStyle(props.expanded)"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-menu-item
                :disabled="!hasSubscription"
                label="Executions"
                :to="{ name: 'executions'}"
                tag="router-link"
                :active="['executions', 'execution'].includes(currentRouteName)"
              />
              <b-menu-item
                :disabled="!hasSubscription"
                label="Administrator"
                :to="{ name: 'admin-log'}"
                tag="router-link"
                :active="['admin-log'].includes(currentRouteName)"
              />
            </b-menu-item>
            <b-menu-item
              v-if="hasLocationsInventoryCapacity"
              :disabled="!hasSubscription"
              label="Map"
              icon="map-marker-multiple"
              :to="{ name: 'map'}"
              tag="router-link"
              :active="'map' === currentRouteName"
            />
            <b-menu-item
              :disabled="!hasSubscription"
              label="Export data"
              icon="file-export"
              :to="{ name: 'exports'}"
              tag="router-link"
              :active="['exports'].includes(currentRouteName)"
            />
            <b-menu-item
              :disabled="!hasSubscription"
              icon="account-group"
              :to="{ name: 'team'}"
              tag="router-link"
              class="sidebarParentMenuItem"
              :expanded="['team',
                          'roles', 'createRole', 'editRole'].includes(currentRouteName)"
            >
              <template #label="props">
                Team
                <b-icon
                  :style="sidebarParentMenuItemIconStyle(props.expanded)"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-menu-item
                label="Members"
                :to="{ name: 'team'}"
                tag="router-link"
                :active="'team' === currentRouteName"
              />
              <b-menu-item
                label="Roles"
                :to="{ name: 'roles'}"
                tag="router-link"
                :active="['roles', 'createRole', 'editRole'].includes(currentRouteName)"
              />
            </b-menu-item>
            <b-menu-item
              :disabled="!hasSubscription"
              icon="cog"
              :to="{ name: 'settings'}"
              tag="router-link"
              class="sidebarParentMenuItem"
              :expanded="['settings',
                          'billing', 'upgradeplan'].includes(currentRouteName)"
            >
              <template #label="props">
                Settings
                <b-icon
                  :style="sidebarParentMenuItemIconStyle(props.expanded)"
                  :icon="props.expanded ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-menu-item
                label="Company settings"
                :to="{ name: 'settings'}"
                tag="router-link"
                :active="'settings' === currentRouteName"
                :expanded="true"
              />
              <b-menu-item
                v-if="$isBgCloud"
                :to="{ name: 'billing'}"
                tag="router-link"
                :active="['billing', 'upgradeplan'].includes(currentRouteName)"
              >
                <template #label>
                  Billing
                </template>
              </b-menu-item>
            </b-menu-item>
          </b-menu-list>
        </div>
        <div
          v-else
          class="sidebar-menu-section"
          style="margin-top:20px;"
        >
          <b-menu-list label="Companies">
            <b-menu-item
              label="Select company"
              icon="domain"
              :to="{ name: 'companies'}"
              tag="router-link"
              :active="'companies' === currentRouteName"
            />
            <b-menu-item
              v-if="$isBgCloud"
              label="Create new company"
              icon="plus-circle-outline"
              :to="{ name: 'createcompany'}"
              tag="router-link"
              :active="'createcompany' === currentRouteName"
            />
          </b-menu-list>
        </div>
      </b-menu>
    </b-sidebar>
    <b-navbar
      :style="navbarsStyle"
      :shadow="!secondNavbarIsVisible"
      :fixed-top="true"
      :mobile-burger="false"
    >
      <template #brand>
        <b-navbar-item
          v-if="!sidebarOpen"
          @click="sidebarOpen = true"
        >
          <b-icon
            icon="menu"
            custom-size="mdi-24px"
          />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <portal-target name="breadcrumb" />
        </b-navbar-item>
      </template>
      <template #end>
        <portal-target name="navbar-right" />
        <b-navbar-dropdown
          :right="true"
          :arrowless="true"
        >
          <template #label>
            <div class="is-align-items-center is-flex">
              <b-icon
                icon="help-circle"
                size="is-small"
                style="font-size: 24px;"
              />
              <span
                v-if="mainLayoutWidth > 770"
                style="margin-left:8px;"
              >Help</span>
            </div>
          </template>
          <b-navbar-item tag="div">
            <b class="noselect">GET IN TOUCH</b>
          </b-navbar-item>
          <b-navbar-item
            href="https://feedback.boardgent.com/feature-requests"
            target="_blank"
          >
            <span class="navbar-margin-to-icons">Share feedback</span>
            <b-icon
              icon="open-in-new"
              size="is-small"
            />
          </b-navbar-item>
          <hr class="navbar-divider">
          <b-navbar-item tag="div">
            <b class="noselect">OTHER RESOURCES</b>
          </b-navbar-item>
          <b-navbar-item
            href="https://help.boardgent.com"
            target="_blank"
          >
            <span class="navbar-margin-to-icons">Help center</span>
            <b-icon
              icon="open-in-new"
              size="is-small"
            />
          </b-navbar-item>
          <b-navbar-item
            href="https://blog.boardgent.com"
            target="_blank"
          >
            <span class="navbar-margin-to-icons">Boardgent blog</span>
            <b-icon
              icon="open-in-new"
              size="is-small"
            />
          </b-navbar-item>
          <b-navbar-item
            href="https://www.reddit.com/r/Boardgent"
            target="_blank"
          >
            <span class="navbar-margin-to-icons">Community</span>
            <b-icon
              icon="open-in-new"
              size="is-small"
            />
          </b-navbar-item>
          <a
            data-canny-changelog
            class="navbar-item noselect"
          >
            What's new at Boardgent
          </a>
          <div v-if="$isBgCloud">
            <hr class="navbar-divider">
            <b-navbar-item tag="div">
              <b class="noselect">SYSTEM STATUS</b>
            </b-navbar-item>
            <Status />
          </div>
        </b-navbar-dropdown>
        <b-navbar-dropdown
          :right="true"
          :arrowless="true"
        >
          <template #label>
            <span
              :class="{'is-navbar-active': currentRouteName === 'user'}"
            >
              <UserImage
                :email="user.email"
                :size="21"
                :is-in-navbar="true"
                :in-navbar-active="currentRouteName === 'user'"
                :lazy="false"
              />
            </span>
          </template>
          <b-navbar-item
            :to="{ name: 'user'}"
            tag="router-link"
            :active="currentRouteName === 'user'"
          >
            My profile<br>{{ user.email }}
          </b-navbar-item>
          <hr class="navbar-divider">
          <b-navbar-item
            @click="logoutApp()"
          >
            Sign out
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <b-navbar
      v-if="secondNavbarIsVisible"
      id="secondNavbar"
      ref="secondNavbar"
      :style="navbarsStyle"
      :shadow="true"
      :fixed-top="true"
      :mobile-burger="false"
    >
      <template #brand>
        <portal-target name="secondNavbarLeft" />
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <portal-target name="secondNavbarRight" />
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { Wormhole } from 'portal-vue';

import Status from '@/components/layout/Status.vue';
import Subscription from '@/helpers/subscription';

import CompanyLogo from '@/components/cross/CompanyLogo.vue';
import UserImage from '@/components/cross/UserImage.vue';

import UserMixin from '@/mixins/users';
import CompanyMixin from '@/mixins/company';
import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../../cross/index';

const { stripeQuery } = vtulEnums.enum.stripe;
const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { approvalStatus } = vtulEnums.enum.device;

export default {
  name: 'NavBar',
  metaInfo() {
    const self = this;
    return {
      titleTemplate(title) {
        if (!title) return 'Boardgent';
        if (self.currentCompany) return `${title} – ${self.currentCompany.name} – Boardgent`;
        return `${title} – Boardgent`;
      },
    };
  },
  components: {
    CompanyLogo,
    UserImage,
    Status,
  },
  mixins: [UserMixin, CompanyMixin, analyticsMixin],
  data() {
    return {
      sidebarOpen: this.$screen.tablet,
      hasSubscription: true,
      planCapabilities,
      hasUpdatesInventoryCapacity: false,
      hasSoftwareInventoryCapacity: false,
      hasLocationsInventoryCapacity: false,
      mainLayoutWidth: 0,
      devicesCountFetched: 1, // We always assume the company have devices while we make the fetch
      devicesToBeApprovedFetched: 0,
    };
  },
  computed: {
    ...mapGetters('stripe-company-information', { getStoreStripeCustomer: 'get' }),
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('companies', { findCompanyInStore: 'find' }),
    ...mapGetters('devices', { findDevicesInStore: 'find' }),
    ...mapGetters('devices-to-be-approved', { findDevicesToBeApprovedInStore: 'find' }),
    user() {
      return this.$store.getters['auth/user'];
    },
    currentRouteName() {
      return this.$route.name;
    },
    getTrialRemainingDays() {
      const stripeCustomer = this.getStoreStripeCustomer(this.currentCompany.stripeCustomerId);
      const trial = stripeCustomer && stripeCustomer.subscriptions
        ? stripeCustomer.subscriptions.data.find((x) => x.status === 'trialing')
        : null;
      if (trial) {
        const stripeEndDate = moment(trial.current_period_end * 1000);
        return stripeEndDate.diff(moment(new Date()).add(2, 'minutes'), 'days') + 1;
      }
      return null;
    },
    logoUrl() {
      const companiesAmount = this.findCompanyInStore().total;
      if (companiesAmount === 0) return '/createcompany';
      return '/companies';
    },
    navbarVisible() {
      return !!this.user;
    },
    companySelected() {
      if (this.currentCompany) {
        this.fetchTotalDevices();
        this.fectchTotalDevicesToBeApproved();
      }
      return this.user && this.$route.params.companyId && this.currentCompany;
    },
    navbarsStyle() {
      return {
        padding: '0 2%',
        marginLeft: this.sidebarOpen && this.$screen.tablet ? '260px' : '0',
      };
    },
    secondNavbarIsVisible() {
      return Wormhole.hasContentFor('secondNavbarLeft') || Wormhole.hasContentFor('secondNavbarRight');
    },
    totalDevices() {
      const { total } = this.findDevicesInStore({
        query: {
          $limit: 0,
          companyId: this.currentCompany.id,
        },
      });
      return total;
    },
    totalDevicesToBeApproved() {
      if (!this.currentCompany) {
        return 0;
      }
      const { total } = this.findDevicesToBeApprovedInStore({
        query: {
          status: approvalStatus.WAITING.value.dbValue,
          companyId: this.currentCompany.id,
        },
      });
      return total;
    },
  },
  watch: {
    userHasCompanyAccess() {
      if (this.userIsInACompany) {
        if (!this.userHasCompanyAccess) this.$router.go();
      }
    },
    user(user) {
      if (user && this.$isBgCloud) {
        this.trackEvent(true, 'identify', user.id, {
          id: user.id,
          avatar: `${this.$enrichWorker}?email=${user.email}&identicon=true`,
          email: user.email,
          name: `${user.name} ${user.lastName}`,
          phone: user.phoneNumber,
          emailVerified: user.isEmailVerified,
          createdAt: new Date(user.createdAt).toISOString(),
          twoFactorEnabled: user.twoFactorEnabled,
          isTestData: user.isTestData,
          origin: user.origin,
        });

        if (user.isTestData) this.startOrStopHighlight(false);
        else this.startOrStopHighlight(true);

        this.fetchCompaniesFromApi();
      }
    },
    async currentCompany(currentCompany) {
      if (!currentCompany) {
        return;
      }
      this.validateSubscriptionStatus();
      if (this.$isBgCloud) {
        this.updateCompanySubscription(currentCompany);
        this.getCompanySubscription();
      }
      this.fetchMembershipsFromApi();
      this.hasUpdatesInventoryCapacity = this.validateIfHasCapability([
        planCapabilities.UPDATES_INVENTORY.value,
        planCapabilities.DEVICES_MANAGEMENT.value,
      ]);
      this.hasSoftwareInventoryCapacity = this.validateIfHasCapability([
        planCapabilities.SOFTWARE_INVENTORY.value,
        planCapabilities.DEVICES_MANAGEMENT.value,
      ]);
      this.hasLocationsInventoryCapacity = this.validateIfHasCapability([
        planCapabilities.LOCATIONS.value,
      ]);
      this.trackEvent(true, 'group', currentCompany.id, {
        name: currentCompany.name,
        address: currentCompany.address,
        city: currentCompany.city,
        country: currentCompany.country,
        stripeCustomerId: currentCompany.stripeCustomerId,
        url: currentCompany.url,
        totalDevices: await this.fetchTotalDevices(),
      });
    },
    navbarVisible(visible) {
      if (visible) this.initCanny();
    },
    sidebarOpen() {
      this.emitStyleForMainLayout();
    },
    secondNavbarIsVisible() {
      this.emitStyleForMainLayout();
    },
    $route(to, from) {
      if (from.path === '/') return;
      if (!this.$screen.tablet) this.sidebarOpen = false;
    },
    '$screen.width': function screenWithChanged() {
      this.emitStyleForMainLayout();
    },
    '$screen.height': function screenHeightChanged() {
      this.emitStyleForMainLayout();
    },
    '$screen.tablet': function changedBetwenTableAndMobile() {
      if (this.$screen.tablet) this.sidebarOpen = true;
      else this.sidebarOpen = false;
    },
  },
  created() {
    this.validateSubscriptionStatus();
  },
  mounted() {
    this.emitStyleForMainLayout();
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('stripe-company-information', { getStripeCustomer: 'get', findStripeCustomerInfo: 'find' }),
    ...mapActions('companies', ['changeCurrentCompany']),
    ...mapActions('companies', { updateCompany: 'patch', findCompanies: 'find' }),
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('devices-to-be-approved', { findDevicesToBeApproved: 'find' }),
    ...mapMutations(['setMainLayoutStyle', 'clearCurrentSelections']),
    emitStyleForMainLayout() {
      this.$nextTick(() => {
        let left = 0;
        let top = 53;
        if (this.sidebarOpen && this.$screen.tablet) left = 260;
        if (this.secondNavbarIsVisible) top += this.$refs.secondNavbar.$el.clientHeight;
        const mainLayoutWidth = this.$screen.width - left;
        const mainLayoutHeight = this.$screen.height - top;
        this.setMainLayoutStyle({
          position: 'absolute',
          left: `${left}px`,
          top: `${top}px`,
          right: '0',
          padding: '10px 2% 2% 2%',
          // We store the MainLayout Size too, so if need to monitor changes in mainlayout size
          // in any place of app we simple monitor the mainLayoutStyle vuex value
          mainLayoutWidth,
          mainLayoutHeight,
        });
        this.mainLayoutWidth = mainLayoutWidth;
      });
    },
    sidebarParentMenuItemIconStyle(expanded) {
      return {
        float: 'right',
        opacity: expanded ? '0.5' : '',
        cursor: expanded ? 'default' : '',
      };
    },
    initCanny() {
      this.$nextTick(() => {
        const appID = '6168fafa6f656c7babeba517';
        // eslint-disable-next-line no-undef
        Canny('initChangelog', {
          appID,
          position: 'bottom',
          align: 'right',
        });
      });
    },
    async updateCompanySubscription(currentCompany) {
      const endPeriodDB = new Date(currentCompany.stripeExpirationDate);
      const stripeInfo = await this.findStripeCustomerInfo({
        query: {
          stripeQuery: stripeQuery.CURRENTSUBSCRIPTION.value,
          customerId: this.currentCompany.stripeCustomerId,
          companyId: this.currentCompany.id,
        },
      });
      if (stripeInfo[0].customer.subscriptions.data.length > 0) {
        const endPeriodStripe = new Date(
          stripeInfo[0].customer.subscriptions.data[0].current_period_end
          * 1000,
        );
        if (!moment(endPeriodStripe).isSame(endPeriodDB)) {
          const company = await this.updateCompany([this.currentCompany.id, {
            stripeExpirationDate: endPeriodStripe,
          }]);
          this.changeCurrentCompany(company);
          if (moment(endPeriodStripe) < moment(new Date())) {
            this.$router.push(`/${this.currentCompany.id}/upgradeplan`);
          }
        }
      } else if (stripeInfo[0].customer.subscriptions.data.length <= 0
        && moment(this.currentCompany.stripeExpirationDate).isAfter(moment())) {
        const company = await this.updateCompany([this.currentCompany.id, {
          stripeExpirationDate: new Date(),
        }]);
        this.changeCurrentCompany(company);
        this.$router.push(`/${this.currentCompany.id}/upgradeplan`);
      }
    },
    async getCompanySubscription() {
      await this.getStripeCustomer([
        this.currentCompany.stripeCustomerId,
        { query: { companyId: this.currentCompany.id } },
      ]);
    },
    logoutApp() {
      this.trackEvent(!this.user.isTestData, 'track', 'logout', {
        email: this.user.email,
        name: `${this.user.name} ${this.user.lastName}`,
        phone: this.user.phoneNumber,
        emailVerified: this.user.isEmailVerified,
        createdAt: new Date(this.user.createdAt).toISOString(),
        origin: this.user.origin,
      });
      this.trackEvent(true, 'reset');
      this.clearCurrentSelections();
      this.logout().then(() => this.$router.go('/login')).catch((error) => { throw new Error(error); });
    },
    validateSubscriptionStatus() {
      this.hasSubscription = Subscription.isActive(this.currentCompany);
    },
    async fetchCompaniesFromApi(skip) {
      let skipCompanies = skip;
      if (!skipCompanies) skipCompanies = 0;
      const { total, data } = await this.findCompanies({
        query: {
          $sort: { createdAt: -1 },
          $skip: skipCompanies,
        },
      });
      skipCompanies += data.length;
      if (skipCompanies < total) {
        await this.fetchCompaniesFromApi(skipCompanies);
      }
    },
    async fetchTotalDevices() {
      const { total } = await this.findDevices({
        query: {
          $limit: 0,
          companyId: this.currentCompany.id,
        },
      });
      this.devicesCountFetched = total;
      return total;
    },
    async fectchTotalDevicesToBeApproved() {
      const { total } = await this.findDevicesToBeApproved({
        query: {
          $limit: 0,
          status: approvalStatus.WAITING.value.dbValue,
          companyId: this.currentCompany.id,
        },
      });
      this.devicesToBeApprovedFetched = total;
    },
  },
};
</script>

<style>
.dropdown-item-router {
  padding: 0px !important;
  height: 50px;
}
.dropdownAction {
  color: black;
  cursor: pointer;
  padding: 15px 10px;
}
.trialButton {
  color: black;
  cursor: pointer;
  margin: 13px 10px;
  padding: 2px 4px;
  background-color: #632d8e;
  border-radius: 23px;
}
.trialButton.is-active {
  color: black;
}
.trialButton.is-active:hover {
  color: black;
}

.navbar-margin-to-icons{
  position:relative; margin-right:2px;
}

.navbar-item.is-active {
    color: #632d8e !important;
}

.is-navbar-active {
    color: #632d8e !important;
}

.dropdown-scrollbar .dropdown-content::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  background-color: #F5F5F5;
}

.dropdown-scrollbar .dropdown-content::-webkit-scrollbar
{
  width: 12px;
}

.dropdown-scrollbar .dropdown-content::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #c1c1c1;
}

#secondNavbar{
  top:52px;
  z-index:29
}

#secondNavbar div {
  flex-shrink: 1;
}

#sidebar-close-container{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

#close-sidebar{
  padding: 0;
}

#sidebar-boardgent-logo{
  padding: 11px;
}

#company-switcher{
  padding: 0;
}

#company-switcher .dropdown{
  width:100%;
}

#company-switcher .dropdown-trigger{
  padding: 11px;
  width:100%;
}

#company-switcher .dropdown-menu{
  margin-left:10px;
}

#company-switcher-company-name:after {
  font: normal normal normal 16px/1 "Material Design Icons";
  content: "\F0A64";
  display: inline;
}

#company-switcher-logo-container{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

#company-switcher-company-logo{
  width:100%;
  max-width:35px;
  margin-right:10px;
}

.sidebar-menu-section{
  padding-left:10px
}

.sidebarParentMenuItem >  a {
  color:#4a4a4a!important;
  background:none!important;
}

.Canny_BadgeContainer .Canny_Badge {
  position: absolute;
  top: 11px;
  right: 35px;
  border-radius: 10px;
  background-color:#632d8e;;
  padding: 5px;
  border: 1px solid white;

}

#canny-changelog-iframe{
  position: fixed!important;
}

.status-indicator{
  background-color: #632d8e;
}

</style>
