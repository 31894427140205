const Enum = require('enum');

exports.httpVerbs = new Enum({
  CREATE: 0,
  UPDATE: 1,
  PATCH: 2,
  REMOVE: 3,
});

exports.types = new Enum({
  SYSTEM_INFORMATION_GATEWAY_ERROR: 0,
  SAVE_AMT_INFO_EMPTY_CREDENTIALS: 1,
  COORDINATES_FROM_WIFI_ERROR: 2,
  WORKER_RESPONSE_ERROR: 3,
  CHANGE_WIFI_STATUS_ERROR: 4,
  AUTHENTICATION_ERROR: 5,
  SEND_EMAIL_ERROR: 6,
  REMOTE_DESKTOP_ERROR: 7,
});
