import { mapGetters, mapActions } from 'vuex';
import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'RolesMixin',
  computed: {
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    ...mapGetters('roles', { findRolesInStore: 'find' }),
    currentMembership() {
      const { data } = this.findMembershipsInStore({
        query: {
          $limit: 1,
          companyId: this.currentCompany.id,
          userId: this.$store.getters['auth/user'].id,
          $select: ['isOwner', 'companyId', 'userId', 'id', 'roleId'],
        },
      });
      if (data[0]) {
        return data[0];
      }
      return '';
    },
    currentUserIsOwner() {
      const membership = this.currentMembership;
      if (membership && membership.isOwner) {
        return true;
      }
      return false;
    },
    currentRole() {
      const query = {
        $limit: 1,
        companyId: this.currentCompany.id,
        $select: ['permissions', 'companyId', 'id', 'maxIdleTime'],
      };

      if (this.currentMembership.roleId) {
        query.id = this.currentMembership.roleId;
      } else {
        query.isDefaultRole = true;
      }

      const { data } = this.findRolesInStore({
        query,
      });
      if (data[0]) {
        return data[0];
      }
      return '';
    },
  },
  async created() {
    await this.fetchCurrentUserMembershipFromApi();
  },
  methods: {
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapActions('roles', { findRoles: 'find' }),
    async fetchCurrentUserMembershipFromApi() {
      try {
        if (!this.currentCompany) {
          return null;
        }
        const permissions = await this.findMemberships({
          query: {
            $limit: 1,
            companyId: this.currentCompany.id,
            userId: this.$store.getters['auth/user'].id,
          },
        });
        if (permissions && permissions.data && permissions.data.length > 0) {
          await this.fetchCurrentRoleFromApi();
          return permissions.data[0];
        }
      } catch (err) {
        this.throwVtulError(operationalUiErrors.GET_USER_INFO_ERROR, alertBox.SNACKBAR.value, err);
      }
      return undefined;
    },
    async fetchCurrentRoleFromApi() {
      try {
        if (this.currentCompany) {
          const query = {
            $limit: 1,
            companyId: this.currentCompany.id,
          };

          if (this.currentMembership.roleId) {
            query.id = this.currentMembership.roleId;
          } else {
            query.isDefaultRole = true;
          }
          const permissions = await this.findRoles({ query });
          if (permissions && permissions.data && permissions.data.length > 0) {
            return permissions.data[0];
          }
        }
      } catch (err) {
        this.throwVtulError(operationalUiErrors.GET_USER_INFO_ERROR, alertBox.SNACKBAR.value, err);
      }
      return undefined;
    },
    validateIfHasPermission(values) {
      if (!this.currentMembership) {
        return false;
      }
      if (this.currentMembership.isOwner) {
        return true;
      }
      if (!this.currentRole) {
        return false;
      }
      return values.some((permission) => this.currentRole.permissions.includes(permission));
    },
  },
};
