<template>
  <b-tooltip
    :active="helpText !== ''"
    :label="helpText"
    :multilined="isMultiline"
    class="force-right"
    :position="(position !== '') ? position : 'is-left'"
    animated
    size="is-small"
    type="is-dark"
  >
    <div
      v-if="url"
    >
      <button
        class="button is-white options-button"
        @click="openHelp"
      >
        <b-icon
          icon="help-circle"
          :size="iconSize"
        />
      </button>
    </div>
  </b-tooltip>
</template>

<script>
import crossHelper from '@/helpers/cross';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'is-small',
    },
    position: {
      type: String,
      default: '',
    },
    isMultiline: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openHelp() {
      crossHelper.openExternalLink(this.url, true);
    },
  },
};
</script>
