const Enum = require('enum');

exports.emitToAgent = new Enum({
  EMIT_EXECUTION: {
    id: 1,
    name: 'Emit execution',
  },
  EMIT_AUTHENTICATION_EVENT: {
    id: 2,
    name: 'Emit authentication event',
  },
});
