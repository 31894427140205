import feathersClient, { makeServicePlugin, BaseModel } from '@/store/feathersClient';

class Companies extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Companies'
}

const servicePath = 'companies';

const state = {
  currentCompany: null,
};
const getters = {
  getCurrentCompany: (currentState) => currentState.currentCompany,
};
const actions = {
  changeCurrentCompany(context, company) {
    context.commit('changeCurrentCompany', company);
  },
  deleteStoredCurrentCompany(context, company) {
    context.commit('deleteStoredCurrentCompany', company);
  },
};
const mutations = {
  changeCurrentCompany(currentStateParam, company) {
    if (company !== Object(company)) return;
    const currentState = currentStateParam;
    currentState.currentCompany = company;
  },
  deleteStoredCurrentCompany(currentStateParam) {
    const currentState = currentStateParam;
    currentState.currentCompany = null;
  },
};

const servicePlugin = makeServicePlugin({
  Model: Companies,
  service: feathersClient.service(servicePath),
  servicePath,
  state,
  getters,
  actions,
  mutations,
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
