const Enum = require('enum');

exports.reportsNames = new Enum({
  BASIC: 'devices',
  HARDWARE: 'hardware',
  SOFTWARE: 'software',
  UPDATES: 'updates',
  NETWORK_INTERFACES: 'network interfaces',
  LOCATIONS: 'locations',
  INTEL_AMT_LOGS: 'Intel AMT logs',
  DISKS: 'disks',
  BITLOCKER_REQUIREMENTS: 'BitLocker requirements',
  BITLOCKER_PASSWORDS: 'BitLocker passwords',
  GEOFENCING: 'geofencing',
  EXECUTIONS_LOGS: 'Executions logs',
});

exports.devicesToReport = new Enum({
  ENABLED_ONLY: {
    name: 'Enabled devices only',
    value: 0,
    queryValue: true,
  },
  ENABLED_AND_DISABLED: {
    name: 'Enabled and disabled devices',
    value: 1,
    queryValue: { $or: [true, false] },

  },
  DISABLED_ONLY: {
    name: 'Disabled devices only',
    value: 2,
    queryValue: false,
  },
});
