const amt = require('./src/amt.enum');
const device = require('./src/device.enum');
const location = require('./src/location.enum');
const optIn = require('./src/optIn.enum');
const execution = require('./src/execution.enum');
const action = require('./src/action.enum');
const kvm = require('./src/kvm.enum');
const log = require('./src/log.enum');
const remoteDesktop = require('./src/remoteDesktop.enum');
const user = require('./src/user.enum');
const report = require('./src/report.enum');
const webrtc = require('./src/configurations/webrtc');
const agent = require('./src/errors/agent.enum');
const ui = require('./src/errors/ui.enum');
const api = require('./src/errors/api.enum');
const alert = require('./src/alert.enum');
const company = require('./src/company.enum');
const stripe = require('./src/stripe.enum');
const hardware = require('./src/hardware.enum');
const windowsEventLogs = require('./src/windowsEventLogs.enum');
const userType = require('./src/userType.enum');
const planCapabilities = require('./src/planCapability.enum');
const authentication = require('./src/authentication.enum');
const settings = require('./src/settings.enum');
const passwordManager = require('./src/passwordManager.enum');
const roles = require('./src/roles.enum');
const emitEvent = require('./src/emitEvent.enum');
const bitlocker = require('./src/bitlocker.enum');
const agentDependencies = require('./src/agentDependencies.enum');
const software = require('./src/software.enum');
const httpErrorCodes = require('./src/errors/httpErrorCodes');
const shellAction = require('./src/shellCommunication.enum');
const fileVault = require('./src/filevault.enum');

module.exports.enum = {
  amt,
  device,
  location,
  optIn,
  execution,
  action,
  kvm,
  remoteDesktop,
  log,
  report,
  windowsEventLogs,
  user,
  agent,
  ui,
  alert,
  api,
  company,
  hardware,
  stripe,
  userType,
  planCapabilities,
  authentication,
  settings,
  passwordManager,
  roles,
  emitEvent,
  bitlocker,
  agentDependencies,
  software,
  httpErrorCodes,
  shellAction,
  fileVault,
};

module.exports.configurations = {
  webrtc,
};
