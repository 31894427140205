import { mapGetters } from 'vuex';

export default {
  name: 'CompanyMixin',
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
  },
  methods: {
    validateIfHasCapability(values) {
      if (!this.currentCompany) {
        return false;
      }
      return values.some((capacity) => this.currentCompany.planCapabilities.includes(capacity));
    },
  },
};
