const Enum = require('enum');

const { planCapabilities } = require('./planCapability.enum');
// The permission 47 and 48 are in branch 109.
// The last permission is 50
const sections = {
  SESSION_SETTINGS: {
    id: 'sessionSettingsSection',
    name: 'Session settings',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 1,
  },
  DEVICE_SECTION: {
    id: 'deviceSection',
    name: 'Device',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 2,
  },
  LOCATIONS_SECTION: {
    id: 'locationsSection',
    name: 'Locations',
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
    position: 3,
  },
  EXECUTIONS_SECTION: {
    id: 'executionsSection',
    name: 'Execution log',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 4,
  },
  DEVICE_GROUPS_SECTION: {
    id: 'deviceGroupsSection',
    name: 'Device groups',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 5,
  },
  COMPANY_SETTINGS_SECTION: {
    id: 'companySettingsSection',
    name: 'Company settings',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 6,
  },
  BOARDGENT_USERS_SECTION: {
    id: 'boardgentUsersSection',
    name: 'Boardgent users',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 7,
  },
  BILLING_SECTION: {
    id: 'billingSection',
    name: 'Billing',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    position: 8,
    hideOnPremise: true,
  },
};

const parentPermissions = {
  DEVICE_LIST: {
    id: 'deviceList',
    name: 'See device list',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 1,
    section: sections.DEVICE_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  DEVICE_DETAILS: {
    id: 'deviceDetails',
    name: 'See device details',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 2,
    section: sections.DEVICE_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
      planCapabilities.HARDWARE_EMAIL_REPORT.value,
    ],
  },
  LOCATIONS: {
    id: 'locations',
    name: 'See locations',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 10,
    section: sections.LOCATIONS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
    ],
  },
  GEOFENCING: {
    id: 'geofencing',
    name: 'Manage geofences',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 50,
    section: sections.LOCATIONS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.GEOFENCING.value,
    ],
  },
  SEE_EXECUTIONS: {
    id: 'seeExecutions',
    name: 'See execution log',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 11,
    section: sections.EXECUTIONS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  RUN_EXECUTIONS: {
    id: 'executeActions',
    name: 'Execute actions',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 12,
    section: sections.EXECUTIONS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  // COMPANY_USERS: {
  //   id: 'companyUsers',
  //   name: 'Company users',
  //   enabled: true,
  //   setupTime: true,
  //   canSwitch: true,
  //   available: false,
  //   dbValue: 20,
  //   requestedCapabilities: [
  //     planCapabilities.DEVICES_MANAGEMENT.value,
  //     planCapabilities.LOCATIONS.value,
  //     planCapabilities.SOFTWARE_INVENTORY.value,
  //     planCapabilities.HARDWARE_INVENTORY.value,
  //     planCapabilities.UPDATES_INVENTORY.value,
  //     planCapabilities.SYSTEM_STATUS.value,
  //     planCapabilities.BIOS_PASSWORD_MANAGER.value,
  //     planCapabilities.AMT_MANAGEMENT.value,
  //     planCapabilities.LOCAL_PASSWORD_MANAGER.value,
  //     planCapabilities.REMOTE_DESKTOP.value,
  //     planCapabilities.DISK_ENCRYPTION.value,
  //   ],
  // },
  COMPANY_SETTINGS: {
    id: 'companySettings',
    name: 'See company settings',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 26,
    section: sections.COMPANY_SETTINGS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  USER_IDLE_TIME: {
    id: 'userIdleTime',
    name: 'Automatic logout',
    enabled: false,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 44,
    section: sections.SESSION_SETTINGS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  BOARDGENT_USERS: {
    id: 'boardgentUsers',
    name: 'See Boardgent users',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 28,
    section: sections.BOARDGENT_USERS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  BILLING: {
    id: 'billing',
    name: 'See billing',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 31,
    section: sections.BILLING_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  DEVICE_GROUPS: {
    id: 'deviceGroups',
    name: 'See device Groups',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 23,
    section: sections.DEVICE_GROUPS_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  DEVICES_TO_BE_APPROVED: {
    id: 'devicesToBeApproved',
    name: 'See devices to be approved',
    enabled: true,
    canSwitch: true,
    available: true,
    dbValue: 47,
    section: sections.DEVICE_SECTION.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
};

exports.permissions = new Enum({
  ...sections,
  ...parentPermissions,
  MODIFY_DEVICE_GROUPS: {
    id: 'modifyDeviceGroups',
    name: 'Manage device groups',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 25,
    section: sections.DEVICE_GROUPS_SECTION.id,
    parent: parentPermissions.DEVICE_GROUPS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  DEVICE_INVENTORY: {
    id: 'deviceInventory',
    name: 'See device inventory',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 3,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  DEVICE_LOCATION: {
    id: 'deviceLocation',
    name: 'See device location',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 4,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.LOCATIONS.value,
    ],
  },
  DEVICE_REMOTE_DESKTOP: {
    id: 'deviceRD',
    name: 'Remote desktop',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 6,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.REMOTE_DESKTOP.value,
    ],
  },
  SEE_LOCAL_PASSWORDS: {
    id: 'seeLocalPasswords',
    name: 'See local passwords',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 39,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
  },
  SEE_BIOS_PASSWORDS: {
    id: 'seeBiosPasswords',
    name: 'See BIOS passwords',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 40,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
  },
  SEE_BITLOCKER_PASSWORDS: {
    id: 'seeBitlockerPasswords',
    name: 'See BitLocker passwords',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 41,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [planCapabilities.DISK_ENCRYPTION.value],
  },
  SEE_AMT_PASSWORDS: {
    id: 'seeAmtPasswords',
    name: 'See AMT passwords',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 42,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [planCapabilities.AMT_MANAGEMENT.value],
  },
  DEVICE_AMT_LOGS: {
    id: 'deviceAMTLogs',
    name: 'See device AMT logs',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 9,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.AMT_MANAGEMENT.value,
    ],
  },
  EXPORT_REPORTS: {
    id: 'exportReports',
    name: 'Export reports',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 43,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  POWER_EXECUTIONS: {
    id: 'powerExecutions',
    name: 'Power executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 13,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 4,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
  },
  INVENTORY_EXECUTIONS: {
    id: 'inventoryExecutions',
    name: 'Inventory executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 14,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 10,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  UPDATE_EXECUTIONS: {
    id: 'updateExecutions',
    name: 'Update executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 15,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 6,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  OS_MANAGEMENT_EXECUTIONS: {
    id: 'osManagementExecutions',
    name: 'OS management executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 16,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 7,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  COMMANDS_AND_SCRIPTS: {
    id: 'executeCommandsAndScripts',
    name: 'Execute commands and scripts',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 17,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 3,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
  },
  AMT_MANAGEMENT_EXECUTIONS: {
    id: 'amtManagementExecutions',
    name: 'AMT management executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 18,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 9,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.AMT_MANAGEMENT.value,
    ],
  },
  LOCAL_PASSWORD_EXECUTIONS: {
    id: 'localPasswordExecutions',
    name: 'Local password executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 33,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 1,
    requestedCapabilities: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
    ],
  },
  BIOS_MANAGEMENT_EXECUTIONS: {
    id: 'biosManagementExecutions',
    name: 'BIOS management executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 34,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 8,
    requestedCapabilities: [
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
  },
  GEOLOCATION_AND_LOCK_EXECUTIONS: {
    id: 'geolocationAndLockExecutions',
    name: 'Geolocation and lock executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 35,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 2,
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
  },
  NOTIFY_EXECUTIONS: {
    id: 'notifyExecutions',
    name: 'Notify executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 36,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 5,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
  },
  LOGS_EXECUTIONS: {
    id: 'logsExecutions',
    name: 'Logs executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 37,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 11,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  ENCRYPTION_EXECUTIONS: {
    id: 'encryptionExecutions',
    name: 'Encryption executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 45,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 1,
    requestedCapabilities: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
    ],
  },
  SOFTWARE_EXECUTIONS: {
    id: 'softwareExecutions',
    name: 'Software executions',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 46,
    section: sections.EXECUTIONS_SECTION.id,
    parent: parentPermissions.RUN_EXECUTIONS.id,
    order: 1,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  // MODIFY_COMPANY_USERS: {
  //   id: 'modifyCompanyUsers',
  //   name: 'Manage company users',
  //   enabled: true,
  //   canSwitch: true,
  //   isAdvancedPlan: false,
  //   available: false,
  //   dbValue: 22,
  //   parent: parentPermissions.COMPANY_USERS.id,
  //   requestedCapabilities: [
  //     planCapabilities.DEVICES_MANAGEMENT.value,
  //     planCapabilities.LOCATIONS.value,
  //     planCapabilities.SOFTWARE_INVENTORY.value,
  //     planCapabilities.HARDWARE_INVENTORY.value,
  //     planCapabilities.UPDATES_INVENTORY.value,
  //     planCapabilities.SYSTEM_STATUS.value,
  //     planCapabilities.BIOS_PASSWORD_MANAGER.value,
  //     planCapabilities.AMT_MANAGEMENT.value,
  //     planCapabilities.LOCAL_PASSWORD_MANAGER.value,
  //     planCapabilities.REMOTE_DESKTOP.value,
  //     planCapabilities.DISK_ENCRYPTION.value,
  //   ],
  // },
  MODIFY_COMPANY_INFORMATION: {
    id: 'modifyCompanyInformation',
    name: 'Modify company information',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 27,
    section: sections.COMPANY_SETTINGS_SECTION.id,
    parent: parentPermissions.COMPANY_SETTINGS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  INVITE_USERS: {
    id: 'inviteUsers',
    name: 'Invite users',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 29,
    section: sections.BOARDGENT_USERS_SECTION.id,
    parent: parentPermissions.BOARDGENT_USERS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  MANAGE_USERS: {
    id: 'manageUsers',
    name: 'Remove users',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 30,
    section: sections.BOARDGENT_USERS_SECTION.id,
    parent: parentPermissions.BOARDGENT_USERS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  EMAIL_HARDWARE_CHANGE: {
    id: 'hardwareChange',
    name: 'Receive an email when hardware changes',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 49,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICE_DETAILS.id,
    requestedCapabilities: [
      planCapabilities.HARDWARE_EMAIL_REPORT.value,
    ],
  },
  MANAGE_COMPANY_PLAN: {
    id: 'manageCompanyPlan',
    name: 'Manage company plan',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 32,
    section: sections.BILLING_SECTION.id,
    parent: parentPermissions.BILLING.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  MODIFY_ROLES: {
    id: 'modifyRoles',
    name: 'Manage user roles',
    enabled: false,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 38,
    section: sections.BOARDGENT_USERS_SECTION.id,
    parent: parentPermissions.BOARDGENT_USERS.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  MODIFY_DEVICES_TO_BE_APPROVED: {
    id: 'modifyDevicesToBeApproved',
    name: 'Manage devices to be approved',
    enabled: true,
    canSwitch: true,
    isAdvancedPlan: false,
    available: true,
    dbValue: 48,
    section: sections.DEVICE_SECTION.id,
    parent: parentPermissions.DEVICES_TO_BE_APPROVED.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
});
