<template>
  <div />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import UserMixin from '@/mixins/users';
import confirmation from '@/components/cross/ModalConfirmation.vue';

export default {
  name: 'AlertNotification',
  mixins: [UserMixin],
  data() {
    return {
      localStorageNotifications: JSON.parse(localStorage.getItem('alertNotifications')) || [],
      activeNotificationIds: [],
    };
  },
  computed: {
    ...mapGetters('notifications', { findNotificationsInStore: 'find' }),
    notifications() {
      return this.findNotificationsInStore();
    },
    notificationsToShow() {
      if (this.notifications.total === 0 || !this.currentCompany || !this.user) {
        return [];
      }
      const notificationsToShow = this.notifications.data.filter((notification) => {
        const dateIsBetweenNow = moment().isBetween(notification.startDate, notification.endDate);
        if (!dateIsBetweenNow) {
          return false;
        }
        if (notification.companies && notification.companies.length > 0
          && !notification.companies.includes(this.currentCompany.id)) {
          return false;
        }
        if (notification.users && notification.users.length > 0
          && !notification.users.includes(this.user.id)) {
          return false;
        }
        return true;
      });

      return notificationsToShow || [];
    },
  },
  watch: {
    notificationsToShow: {
      handler(notificationsDB) {
        const filteredNotifications = [];
        notificationsDB.forEach((notification) => {
          const notificationShown = this.localStorageNotifications.some(
            (localStorageNotification) => localStorageNotification.id === notification.id,
          );
          const notificationActive = this.activeNotificationIds.some(
            (notificationId) => notificationId === notification.id,
          );
          if (!notificationShown && !notificationActive) {
            filteredNotifications.push(notification);
          }
        });

        filteredNotifications.forEach((notification) => {
          this.showNotification(notification);
          this.activeNotificationIds.push(notification.id);
        });
      },
    },
  },
  created() {
    this.fetchNotifications();
    this.deleteExpiredNotificationsFromLocalStorage();
  },
  methods: {
    ...mapActions('notifications', { findNotifications: 'find' }),

    fetchNotifications() {
      this.findNotifications({
        query: {
          $limit: 50,
          $sort: { createdAt: -1 },
        },
      });
    },

    deleteExpiredNotificationsFromLocalStorage() {
      if (this.localStorageNotifications.length === 0) return;

      this.localStorageNotifications = this.localStorageNotifications.filter(
        (notification) => new Date() < new Date(notification.endDate),
      );

      localStorage.alertNotifications = JSON.stringify([
        ...this.localStorageNotifications,
      ]);
    },

    showNotification(notification) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        canCancel: ['escape'],
        props: {
          onConfirm: () => this.notShowNotification(notification),
          title: notification.title,
          confirmText: 'Do not show again',
          cancelText: 'Ok',
          message: notification.content,
        },
      });
    },

    notShowNotification(notification) {
      this.localStorageNotifications.push({
        id: notification.id,
        endDate: notification.endDate,
      });
      localStorage.alertNotifications = JSON.stringify([
        ...this.localStorageNotifications,
      ]);
    },
  },
};
</script>
