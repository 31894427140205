const Enum = require('enum');

exports.deviceStatusColor = new Enum({
  BLUE: 0,
  GREEN: 1,
  RED: 2,
  GRAY: 3,
  YELLOW: 4,
});

exports.deviceStatus = new Enum({
  UNKNOWN: {
    dbValue: -1,
    name: 'Unknown',
    color: this.deviceStatusColor.GRAY,
    hidden: true,
    displayOrder: 4,
  },
  OFFLINE: {
    dbValue: 0,
    name: 'Offline',
    color: this.deviceStatusColor.RED,
    hidden: false,
    displayOrder: 2,
  },
  CONNECTING: {
    dbValue: 1,
    name: 'Connecting',
    color: this.deviceStatusColor.YELLOW,
    hidden: true,
    displayOrder: 3,
  },
  ONLINE: {
    dbValue: 2,
    name: 'Online',
    color: this.deviceStatusColor.GREEN,
    hidden: false,
    displayOrder: 1,
  },
});

exports.deviceTypes = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
    icon: 'laptop-off',
    hidden: true,
    displayOrder: 3,
  },
  DESKTOP: {
    dbValue: 1,
    name: 'Desktop',
    icon: 'desktop-tower',
    hidden: false,
  },
  LAPTOP: {
    dbValue: 2,
    name: 'Laptop',
    icon: 'laptop',
    hidden: false,
  },
  VIRTUALMACHINE: {
    dbValue: 3,
    name: 'Virtual machine',
    icon: 'checkbox-intermediate',
    hidden: false,
  },
});

exports.deviceLockStatus = new Enum({
  UNLOCKED: {
    dbValue: 0,
    name: 'Unlocked',
  },
  DISPLAY_MESSAGES_FREQUENTLY: {
    dbValue: 1,
    name: 'Display messages frequently',
  },
  LOCKED: {
    dbValue: 2,
    name: 'Locked',
  },
});

exports.antivirusStatus = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  DISABLED: {
    dbValue: -1,
    name: 'Disabled',
  },
  ENABLED: {
    dbValue: 2,
    name: 'Enabled',
  },
});

exports.firewallStatus = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  DISABLED: {
    dbValue: -1,
    name: 'Disabled',
  },
  ENABLED: {
    dbValue: 2,
    name: 'Enabled',
  },
});

exports.diskHealthStatus = new Enum({
  UNKNOWN: {
    dbValue: -1,
    name: 'Unknown',
  },
  OK: {
    dbValue: 1,
    name: 'Ok',
  },
  BAD: {
    dbValue: 2,
    name: 'Bad',
  },
  CAUTION: {
    dbValue: 3,
    name: 'Caution',
  },
});

exports.encryptionStatus = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  ON: {
    dbValue: 1,
    name: 'On',
  },
  OFF: {
    dbValue: 2,
    name: 'Off',
  },
  CANNOT_BE_ENCRYPTED: {
    dbValue: 3,
    name: 'Cannot be encrypted',
  },
});

exports.bootType = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  LEGACY: {
    dbValue: 1,
    name: 'BIOS (Legacy)',
  },
  UEFI: {
    dbValue: 2,
    name: 'EFI/UEFI',
  },
});

exports.bootOrderVendors = new Enum({
  HEWLETT_PACKARD: {
    dbValue: 0,
    name: 'Hewlett Packard',
  },
  DELL: {
    dbValue: 1,
    name: 'Dell',
  },
  LENOVO: {
    dbValue: 2,
    name: 'Lenovo',
  },
});

exports.secureBoot = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  ENABLED: {
    dbValue: 1,
    name: 'Enabled',
  },
  DISABLED: {
    dbValue: 2,
    name: 'Disabled',
  },
});

exports.tpmStatus = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  ENABLED: {
    dbValue: 1,
    name: 'Enabled',
  },
  DISABLED: {
    dbValue: 2,
    name: 'Disabled',
  },
});

exports.biosManagementStatus = new Enum({
  MANAGED: {
    dbValue: 0,
    name: 'Managed by Boardgent',
    column: 'managed',
  },
  READY_TO_BE_MANAGED: {
    dbValue: 1,
    name: 'Ready to be managed',
    column: 'readyToBeManaged',
  },
  NO_PASSWORD_IN_BIOS: {
    dbValue: 2,
    name: 'There is no password in BIOS',
    column: 'notPasswordInBios',
  },
  NO_CLASSES_IN_WMI: {
    dbValue: 3,
    name: 'WMI classes not installed',
    column: 'noClassesInWmi',
  },
  NOT_COMPATIBLE: {
    dbValue: 4,
    name: 'Not compatible',
    column: 'notCompatible',
  },
  OTHERS: {
    dbValue: 5,
    name: 'Not managed',
    column: 'others',
  },
  UNKNOWN: {
    dbValue: 6,
    name: 'Unknown',
    column: 'unknown',
  },
});

exports.freezeStatus = new Enum({
  NOT_COMPATIBLE: {
    dbValue: -1,
    name: 'Not supported',
  },
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  ENABLED: {
    dbValue: 1,
    name: 'Enabled',
  },
  DISABLED: {
    dbValue: 2,
    name: 'Disabled',
  },
});

exports.approvalStatus = new Enum({
  WAITING: {
    dbValue: 0,
    name: 'Waiting for approve',
  },
  DOUBLE_BOOT: {
    dbValue: 1,
    name: 'Approved with double boot',
    modalTitle: 'Approve devices with double boot',
  },
  APPROVED: {
    dbValue: 2,
    name: 'Approved',
    modalTitle: 'Approve devices',
  },
  NOT_APPROVED: {
    dbValue: 3,
    name: 'Rejected',
    modalTitle: 'Reject devices',
  },
});

exports.controlPanel = new Enum({
  ENABLED: {
    value: 1,
    name: 'Enabled',
  },
  DISABLED: {
    value: 2,
    name: 'Disabled',
  },
});

exports.dellCommandMonitor = new Enum({
  BIOS_WITHOUT_WMI_ACPI: {
    id: 1,
    version: '9.3.0.22',
  },
  BIOS_WITH_WMI_ACPI_W7_OR_W8: {
    id: 2,
    version: '10.5.1.114',
  },
  BIOS_WITH_WMI_ACPI_W10_OR_W11: {
    id: 3,
    version: '10.9.0.307',
  },
});
