const Enum = require('enum');

exports.sizeTypes = new Enum({
  values: [
    { name: '', value: 0 },
    { name: '1 to 5 employees', value: 1 },
    { name: '5 to 25 employees', value: 2 },
    { name: '25 to 50 employees', value: 3 },
    { name: '50 to 100 employees', value: 4 },
    { name: '100 to 500 employees', value: 5 },
    { name: '500 to 1000 employees', value: 6 },
    { name: '1000 or more employees', value: 7 },
  ],
});

exports.reasonOfUse = new Enum({
  UNKNOWN: {
    value: 0,
    name: 'Unknown',
  },
  MY_COMPANY: {
    value: 1,
    name: 'My company',
  },
  PERSONAL_USE: {
    value: 2,
    name: 'Personal use',
  },
  NOT_SURE: {
    value: 3,
    name: 'I am not sure',
  },
});
