<template>
  <b-image
    :src="imageUrl"
    :alt="urlLogo ? `${companyName} logo` : 'Boardgent logo'"
    :src-fallback="require('@/assets/img/logo.svg')"
    :lazy="lazy"
  />
</template>

<script>
export default {
  props: {
    companyName: {
      type: String,
      required: true,
    },
    urlLogo: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    imageUrl() {
      if (this.urlLogo) return this.urlLogo;
      return '';
    },
  },
};
</script>
<style scoped>
