import moment from 'moment';

export default class Subscription {
  static isActive(company = null) {
    if (company && company.stripeExpirationDate) {
      return moment(company.stripeExpirationDate) > moment(new Date());
    }
    return false;
  }

  static subscriptionExpired(company = null) {
    return company && company.stripeExpirationDate
      ? (!company.stripeExpirationDate
      || moment(company.stripeExpirationDate) < moment(new Date())) : null;
  }

  static validateActiveSubscription(company) {
    return this.isActive(company);
  }
}
