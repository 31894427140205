// Default values of the preferences
const state = {
  deviceListColumns: [
    { id: 'name', visible: true, position: 1 },
    { id: 'status', visible: true, position: 2 },
    { id: 'amtStatus', visible: true, position: 3 },
    { id: 'lastSeen', visible: true, position: 5 },
    { id: 'lastUser', visible: true, position: 7 },
    { id: 'description', visible: true, position: 8 },
    { id: 'address', visible: true, position: 9 },
  ],
  deviceListOrder: {
    field: 'lastSeen', direction: 'desc',
  },
  deviceListPageSize: 40,
};

const getters = {
  getDeviceListColumns: (currentState) => currentState.deviceListColumns,
  getDeviceListOrder: (currentState) => currentState.deviceListOrder,
  getDeviceListPageSize: (currentState) => currentState.deviceListPageSize,
};
const actions = {
  updateDeviceListColumns(context, columns) {
    context.commit('updateDeviceListColumns', columns);
  },
  updateDeviceListOrder(context, newOrder) {
    context.commit('updateDeviceListOrder', newOrder);
  },
  updateDeviceListPageSize(context, newPageSize) {
    context.commit('updateDeviceListPageSize', newPageSize);
  },
};
const mutations = {
  updateDeviceListColumns(currentStateParam, columns) {
    const currentState = currentStateParam;
    currentState.deviceListColumns = columns;
    return currentState;
  },
  updateDeviceListOrder(currentStateParam, newOrder) {
    const currentState = currentStateParam;
    currentState.deviceListOrder = newOrder;
    return currentState;
  },
  updateDeviceListPageSize(currentStateParam, newPageSize) {
    const currentState = currentStateParam;
    currentState.deviceListPageSize = newPageSize;
    return currentState;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
