const Enum = require('enum');

exports.userType = new Enum({
  Others: {
    name: 'Others',
    value: 0,
  },
  VIP: {
    name: 'VIP',
    value: 1,
  },
});
