const Enum = require('enum');

exports.optInState = new Enum({
  UNKNOWN: {
    dbValue: 1,
    amtValue: '-',
  },
  UNNECESSARY: {
    dbValue: 2,
    amtValue: 0,
  },
  ONLY_KVM: {
    dbValue: 3,
    amtValue: 1,
  },
  NECESSARY: {
    dbValue: 4,
    amtValue: 0xFFFFFFFF,
  },
});
