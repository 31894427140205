import feathersClient, { makeServicePlugin, BaseModel } from '@/store/feathersClient';

class Memberships extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Memberships'
}

const servicePath = 'memberships';

const state = {
  currentMembership: null,
};
const getters = {
  getCurrentMembership: (currentState) => currentState.currentMembership,
};
const actions = {
  changeCurrentMembership(context, membership) {
    context.commit('changeCurrentMembership', membership);
  },
  deleteStoredCurrentRole(context, membership) {
    context.commit('deleteStoredCurrentRole', membership);
  },
};
const mutations = {
  changeCurrentMembership(currentStateParam, membership) {
    if (membership !== Object(membership)) return;
    const currentState = currentStateParam;
    currentState.currentMembership = membership;
  },
  deleteStoredCurrentRole(currentStateParam) {
    const currentState = currentStateParam;
    currentState.currentMembership = null;
  },
};

const servicePlugin = makeServicePlugin({
  Model: Memberships,
  service: feathersClient.service(servicePath),
  servicePath,
  state,
  getters,
  actions,
  mutations,
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
