const Enum = require('enum');

exports.operationalAgentErrors = new Enum({
  NOT_SUPPORT_AMT: {
    name: 'NOT_SUPPORT_AMT',
    code: 'OA00003',
    message: 'This device doesn\'t support Intel® AMT.',
  },
  AMT_INFO_TIMEOUT: {
    name: 'AMT_INFO_TIMEOUT',
    code: 'OA00004',
    message: 'Timeout getting Intel® AMT information. The device probably supports AMT, but the service is not working. Restart the device and try again.',
  },
  ERROR_GET_AMT_FROM_API: {
    name: 'ERROR_GET_AMT_FROM_API',
    code: 'OA00005',
    message: 'Error getting Intel® AMT data from the API.',
  },
  AMT_NOT_CONNECTED_TO_VTUL: {
    name: 'AMT_NOT_CONNECTED_TO_VTUL',
    code: 'OA00006',
    message: 'Intel® AMT is provisioned but not connected to Boardgent. Execute "Add Boardgent MPS Server."',
  },
  NOT_PROVISIONED: {
    name: 'NOT_PROVISIONED',
    code: 'OA00007',
    message: 'Intel® AMT is not provisioned.',
  },
  AMT_ALREADY_PROVISIONED: {
    name: 'AMT_ALREADY_PROVISIONED',
    code: 'OA00008',
    message: 'Intel® AMT is already provisioned.',
  },
  WAIT_TIMEOUT: {
    name: 'WAIT_TIMEOUT',
    code: 'OA00009',
    message: 'Stopped because it runs for more than',
  },
  UNPROVISION_DEVICE_ERROR: {
    name: 'UNPROVISION_DEVICE_ERROR',
    code: 'OA00010',
    message: 'We cannot unprovision a device configured in Admin Control Mode without knowing the admin password. Execute “Add Boardgent MPS Server”.',
  },
  CIRA_NOT_SUPPORTED: {
    name: 'CIRA_NOT_SUPPORTED',
    code: 'OA00011',
    message: 'This device does not support Intel® AMT CIRA.',
  },
  MAX_BUFFER_EXCEEDED: {
    name: 'MAX_BUFFER_EXCEEDED',
    code: 'OA00012',
    message: 'Maximum buffer exceeded.',
  },
  EXECUTION_ERROR: {
    name: 'EXECUTION_ERROR',
    code: 'OA00013',
    message: 'Error during the execution.',
  },
  AGENT_SERVICE_ERROR_UPDATE: {
    name: 'AGENT_SERVICE_ERROR_UPDATE',
    code: 'OA00014',
    message: 'Error updating the agent.',
  },
  UPDATE_DOWNLOAD_ERROR: {
    name: 'UPDATE_DOWNLOAD_ERROR',
    code: 'OA00015',
    message: 'Error downloading the update.',
  },
  MODIFY_AGENT_ERROR: {
    name: 'ERROR_MODIFY_AGENT',
    code: 'OA00016',
    message: 'Error modifying running agent.',
  },
  SHOW_NOTIFICATION_ERROR: {
    name: 'SHOW_NOTIFICATION_ERROR',
    code: 'OA00017',
    message: 'Error showing the notification.',
  },
  SOFTWARE_LIST_ERROR: {
    name: 'SOFTWARE_LIST_ERROR',
    code: 'OA00018',
    message: 'Error getting installed software list.',
  },
  ERROR_LISTING_UPDATES: {
    name: 'ERROR_LISTING_UPDATES',
    code: 'OA00019',
    message: 'Error listing the updates in System.',
  },
  SAVE_AMT_VERSION_ERROR: {
    name: 'SAVE_AMT_VERSION_ERROR',
    code: 'OA00020',
    message: 'Error saving Intel® AMT version.\nIntel® AMT version is:',
  },
  ERROR_GET_KVM_SETTINGS: {
    name: 'ERROR_GET_KVM_SETTINGS',
    code: 'OA00021',
    message: 'Error getting KVM settings.',
  },
  ERROR_CHANGE_KVM_SETTINGS: {
    name: 'ERROR_CHANGE_KVM_SETTINGS',
    code: 'OA00022',
    message: 'Error changing KVM settings.',
  },
  ERROR_REMOVE_ENV_DETECTION: {
    name: 'ERROR_REMOVE_ENV_DETECTION',
    code: 'OA00023',
    message: 'Error removing environment detection.',
  },
  ERROR_SAVE_KVM_REDIRECTION: {
    name: 'ERROR_SAVE_KVM_REDIRECTION',
    code: 'OA00024',
    message: 'Error Saving KVM redirection status.',
  },
  ERROR_SAVE_EHBC_STATUS: {
    name: 'ERROR_SAVE_KVM_REDIRECTION',
    code: 'OA00025',
    message: 'Error Saving EHBC status.\nEHBC is',
  },
  ERROR_SAVE_AMT_MODE: {
    name: 'ERROR_SAVE_AMT_MODE',
    code: 'OA00026',
    message: 'Error saving Intel® AMT Mode.',
  },
  ERROR_GET_USER_CONSET: {
    name: 'ERROR_GET_USER_CONSET',
    code: 'OA00027',
    message: 'Error getting user consent information.',
  },
  ERROR_SAVE_USER_CONSET: {
    name: 'ERROR_SAVE_USER_CONSET',
    code: 'OA00028',
    message: 'Error saving User Consent.',
  },
  ERROR_UPDATE_USER_CONSET: {
    name: 'ERROR_UPDATE_USER_CONSET',
    code: 'OA00029',
    message: 'Error updating the User Consent information.',
  },
  REQUIRED_AMT_PASSWORD: {
    name: 'REQUIRED_AMT_PASSWORD',
    code: 'OA00030',
    message: 'Error Intel® AMT password is required to add Boardgent MPS Server.',
  },
  ERROR_REMOVE_MPS_CONFIGURATION: {
    name: 'ERROR_REMOVE_MPS_CONFIGURATION',
    code: 'OA00031',
    message: 'Error removing current MPS configuration.',
  },
  ERROR_ADD_MPS_SERVER: {
    name: 'ERROR_ADD_MPS_SERVER',
    code: 'OA00032',
    message: 'Error adding Boardgent MPS Server',
  },
  ERROR_CHANGE_CIRA: {
    name: 'ERROR_CHANGE_CIRA',
    code: 'OA00033',
    message: 'Error changing CIRA initiation.',
  },
  ERROR_GET_MPS_LIST: {
    name: 'ERROR_GET_MPS_LIST',
    code: 'OA00034',
    message: 'Error getting MPS Server List.',
  },
  ERROR_GET_MPS_INFORMATION: {
    name: 'ERROR_GET_MPS_INFORMATION',
    code: 'OA00035',
    message: 'Error getting MPS information.',
  },
  ERROR_UPDATE_POLICIES: {
    name: 'ERROR_UPDATE_POLICIES',
    code: 'OA00036',
    message: 'Error updating policies.',
  },
  ERROR_GET_ENV_DETECTION: {
    name: 'ERROR_GET_ENV_DETECTION',
    code: 'OA00037',
    message: 'Error getting environment detection.',
  },
  ERROR_ADD_ENV_DETECTION: {
    name: 'ERROR_ADD_ENV_DETECTION',
    code: 'OA00038',
    message: 'Error adding environment detection.',
  },
  ERROR_SAVE_CREDENTIALS: {
    name: 'ERROR_SAVE_CREDENTIALS',
    code: 'OA00039',
    message: 'Error saving credentials.',
  },
  ERROR_UPDATE_KVM_SETTINGS: {
    name: 'ERROR_UPDATE_KVM_SETTINGS',
    code: 'OA00040',
    message: ' Error updating KVM settings.',
  },
  ERROR_SAVE_TLS_SETTINGS: {
    name: 'ERROR_SAVE_TLS_SETTINGS',
    code: 'OA00041',
    message: 'Error saving TLS settings.',
  },
  ERROR_CHANGE_TLS_SETTINGS: {
    name: 'ERROR_CHANGE_TLS_SETTINGS',
    code: 'OA00042',
    message: 'Error changing TLS settings.',
  },
  ERROR_GET_TLS_SETTINGS: {
    name: 'ERROR_GET_TLS_SETTINGS',
    code: 'OA00043',
    message: 'Error getting TLS settings.',
  },
  ERROR_GET_POLICIES: {
    name: 'ERROR_GET_POLICIES',
    code: 'OA00044',
    message: 'Error getting policies.',
  },
  ERROR_REMOVE_POLICIES: {
    name: 'ERROR_REMOVE_POLICIES',
    code: 'OA00045',
    message: 'Error removing policies.',
  },
  ERROR_REMOVE_MPS: {
    name: 'ERROR_REMOVE_MPS',
    code: 'OA00046',
    message: 'Error removing Boardgent MPS.',
  },
  ERROR_REMOVE_MPS_CERTIFICATES: {
    name: 'ERROR_REMOVE_MPS_CERTIFICATES',
    code: 'OA00047',
    message: 'Error removing Boardgent MPS Certificates.',
  },
  ERROR_GET_CERTIFICATES: {
    name: 'ERROR_GET_CERTIFICATES',
    code: 'OA00048',
    message: 'Error getting Certificates List.',
  },
  ERROR_TLS_SECURITY_MUTUAL: {
    name: 'ERROR_TLS_SECURITY_MUTUAL',
    code: 'OA00049',
    message: 'Error TLS Security Mutual-auth requires at least one Common Name.',
  },
  ERROR_GET_NETWORK_INFO: {
    name: 'ERROR_TLS_SECURITY_MUTUAL',
    code: 'OA00050',
    message: 'Error getting current network information.',
  },
  ERROR_GET_NETWORK_PROFILES: {
    name: 'ERROR_GET_NETWORK_PROFILES',
    code: 'OA00051',
    message: 'Error getting current network profiles.',
  },
  ERROR_ADD_WIFI_PROFILES: {
    name: 'ERROR_GET_NETWORK_PROFILES',
    code: 'OA00052',
    message: 'Error adding the Wi-Fi profiles.',
  },
  ERROR_GET_WIRELESS_CARD_INFO: {
    name: 'ERROR_GET_WIRELESS_CARD_INFO',
    code: 'OA00053',
    message: 'Error getting wireless card information.',
  },
  ERROR_GET_WIRELESS_STATE: {
    name: 'ERROR_GET_WIRELESS_CARD_INFO',
    code: 'OA00054',
    message: 'Error getting wireless card state.',
  },
  ERROR_SAVE_AMT_WIRELESS_STATE: {
    name: 'ERROR_SAVE_AMT_WIRELESS_STATE',
    code: 'OA00055',
    message: 'Error saving Intel® AMT wireless state.',
  },
  ERROR_GET_AMT_WIRELESS_INFO: {
    name: 'ERROR_GET_AMT_WIRELESS_INFO',
    code: 'OA00056',
    message: 'Error getting Intel® AMT wireless information.',
  },
  ERROR_UPDATE_AMT_WIRELESS_INFO: {
    name: 'ERROR_UPDATE_AMT_WIRELESS_INFO',
    code: 'OA00057',
    message: 'Error updating the Intel® AMT wireless status.',
  },
  ERROR_SAVE_AMT_WIRELESS_INFO: {
    name: 'ERROR_GET_AMT_WIRELESS_INFO',
    code: 'OA00058',
    message: 'Error saving the new Intel® AMT wireles status.',
  },
  DECRYPT_AMT_PROFILE: {
    name: 'DECRYPT_AMT_PROFILE',
    code: 'OA00059',
    message: 'Error decrypting Intel® AMT Profile, please confirm the password.',
  },
  PARSE_AMT_PROFILE: {
    name: 'PARSE_AMT_PROFILE',
    code: 'OA00060',
    message: 'Error parsing Intel® AMT Profile.',
  },
  CONFIGURE_CIRA_CONNECTION: {
    name: 'CONFIGURE_CIRA_CONNECTION',
    code: 'OA00061',
    message: 'Error configuring CIRA connection.',
  },
  ERROR_GET_PROVISIONED_MODE: {
    name: 'ERROR_GET_PROVISIONED_MODE',
    code: 'OA00062',
    message: 'Error getting the current provisioned mode.',
  },
  ERROR_CONTACT_GEOLOCATION_API: {
    name: 'ERROR_CONTACT_GEOLOCATION_API',
    code: 'OA00063',
    message: 'Error contacting to Geolocation API.',
  },
  ERROR_IMPORT_REQUIRED_FILES: {
    name: 'ERROR_IMPORT_REQUIRED_FILES',
    code: 'OA00065',
    message: 'Error importing required files in the System.',
  },
  REQUIRED_NET_FRAMEWORK: {
    name: 'REQUIRED_NET_FRAMEWORK',
    code: 'OA00066',
    message: 'Error: The Agent requires .NET Framework 4 or later.',
  },
  ERROR_AGENT_START: {
    name: 'ERROR_AGENT_START',
    code: 'OA00067',
    message: 'Error: The Agent is installed but can not start.',
  },
  UNINSTALL_VTUL_AGENT: {
    name: 'UNINSTALL_VTUL_AGENT',
    code: 'OA00068',
    message: 'Error uninstalling the Agent.',
  },
  ERROR_INSTALL_VTUL_AGENT_SERVICE: {
    name: 'ERROR_INSTALL_VTUL_AGENT_SERVICE',
    code: 'OA00069',
    message: 'Error: To install the Agent as service use a compiled version.',
  },
  ERROR_RESTART_RUN_SERVICE: {
    name: 'ERROR_RESTART_RUN_SERVICE',
    code: 'OA00070',
    message: 'Error restaring the running service.',
  },
  ERROR_IPC: {
    name: 'ERROR_IPC',
    code: 'OA00071',
    message: 'Error creating IPC.',
  },
  ERROR_GET_CURRENT_USER: {
    name: 'ERRERROR_GET_CURRENT_USEROR_IPC',
    code: 'OA00072',
    message: 'Error getting the current user.',
  },
  ERROR_CONNECT_CHILD_PROCESS: {
    name: 'ERROR_CONNECT_CHILD_PROCESS',
    code: 'OA00073',
    message: 'Error connecting to child process.',
  },
  ERROR_VERIFY_AMT: {
    name: 'ERROR_VERIFY_AMT',
    code: 'OA00074',
    message: 'Error verifying AMT.',
  },
  INTEL_AMT_CONNECTION_ERROR: {
    name: 'INTEL_AMT_CONNECTION_ERROR',
    code: 'OA00075',
    message: 'Error connecting to Intel® AMT.',
  },
  INTEL_AMT_AUTHENTICATION_ERROR: {
    name: 'INTEL_AMT_AUTHENTICATION_ERROR',
    code: 'OA00076',
    message: 'Intel® AMT authentication error.',
  },
  TLS_CERTIFICATE_ERROR: {
    name: 'TLS_CERTIFICATE_ERROR',
    code: 'OA00077',
    message: 'TLS Certificate check error.',
  },
  ERROR_GET_USER_CONSENT_INFO: {
    name: 'ERROR_GET_USER_CONSENT_INFO',
    code: 'OA00078',
    message: 'Error getting current user consent information.',
  },
  ERROR_GET_AMT_INFO: {
    name: 'ERROR_GET_AMT_INFO',
    code: 'OA00079',
    message: 'Error getting Intel® AMT information.',
  },
  ONLY_WINDOWS_SUPPORTED: {
    name: 'ONLY_WINDOWS_SUPPORTED',
    code: 'OA00080',
    message: 'Only supported when running Windows.',
  },
  ONLY_LINUX_SUPPORTED: {
    name: 'ONLY_LINUX_SUPPORTED',
    code: 'OA00081',
    message: 'Only supported when running Linux.',
  },
  LINUX_NOT_SUPPORTED: {
    name: 'LINUX_NOT_SUPPORTED',
    code: 'OA00082',
    message: 'This action is not supported on Linux.',
  },
  MUST_RUN_AS_ADMINISTRATOR: {
    name: 'MUST_RUN_AS_ADMINISTRATOR',
    code: 'OA00083',
    message: 'Agent is not running with administrator permisssions.',
  },
  MUST_RUN_AS_SERVICE: {
    name: 'MUST_RUN_AS_SERVICE',
    code: 'OA00084',
    message: 'The Agent is not running as service.',
  },
  GENERAL_ERROR: {
    name: 'GENERAL_ERROR',
    code: 'OA00085',
    message: 'General error.',
  },
  ERROR_SAVE_NEW_USER_CONSET: {
    name: 'ERROR_SAVE_NEW_USER_CONSET',
    code: 'OA00086',
    message: 'Error saving the new User Consent state.',
  },
  ERROR_ADD_MPS_CERT: {
    name: 'ERROR_ADD_MPS_CERT',
    code: 'OA00087',
    message: 'Error adding Boardgent MPS Certificate.',
  },
  ERROR_ADD_SOME_WIFI_PROFILES: {
    name: 'ERROR_ADD_SOME_WIFI_PROFILES',
    code: 'OA00088',
    message: 'Error adding some Wi-Fi profiles.',
  },
  ERROR_SAVE_TLS_STATUS: {
    name: 'ERROR_SAVE_TLS_STATUS',
    code: 'OA00089',
    message: 'Error adding some Wi-Fi profiles.',
  },
  ERROR_UPDATE_ENV_DETECTION: {
    name: 'ERROR_UPDATE_ENV_DETECTION',
    code: 'OA00090',
    message: 'Error updating environment detection domains.',
  },
  ERROR_REMOVE_ENV_DETECTION_DOMAINS: {
    name: 'ERROR_REMOVE_ENV_DETECTION_DOMAINS',
    code: 'OA00091',
    message: 'Error updating environment detection domains.',
  },
  AMT_ESTABLISH_CONNECTION_ERROR: {
    name: 'AMT_ESTABLISH_CONNECTION_ERROR',
    code: 'OA00092',
    message: 'Error establishing connection with Intel® AMT.',
  },
  AMT_READ_INSTALL_SOFTWARE: {
    name: 'AMT_READ_INSTALL_SOFTWARE',
    code: 'OA00093',
    message: 'Error reading installed software list.',
  },
  INVALID_HTTP_OR_HTTPS: {
    name: 'INVALID_HTTP_OR_HTTPS',
    code: 'OA00094',
    message: 'Error: Server Url must be HTTP or HTTPS.',
  },
  ACCESS_DENIED: {
    name: 'ACCESS_DENIED',
    code: 'OA00095',
    message: 'Error: Run this program as administrator or root to manage the service.',
  },
  REQUIRED_COMMAND: {
    name: 'REQUIRED_COMMAND',
    code: 'OA00096',
    message: 'Error: Missing required command.',
  },
  DEVICE_FILES_NOT_AVAILABLE: {
    name: 'DEVICE_FILES_NOT_AVAILABLE',
    code: 'OA00097',
    message: 'Required files are not available in the device.',
  },
  AMT_FILES_NOT_AVAILABLE: {
    name: 'AMT_FILES_NOT_AVAILABLE',
    code: 'OA00098',
    message: 'Intel® AMT Files not available in the System.',
  },
  ERROR_GET_POWER_STATUS: {
    name: 'ERROR_GET_POWER_STATUS',
    code: 'OA00099',
    message: 'Error getting power status information.',
  },
  EXECUTION_REQUIRIE_PARAMS: {
    name: 'EXECUTION_REQUIRIE_PARAMS',
    code: 'OA00100',
    message: 'Required to define the parameters for the use of Execute Command.',
  },
  DEVICE_FILES_UNAVAILABLE: {
    name: 'DEVICE_FILES_UNAVAILABLE',
    code: 'OA00101',
    message: 'Required files not available in the device.',
  },
  RENAME_DEVICE_REQUIRE_PARAMS: {
    name: 'RENAME_DEVICE_REQUIRE_PARAMS',
    code: 'OA00102',
    message: 'Required to define the parameters for the use of Rename Device.',
  },
  RENAME_DEVICE_NOT_SUPPORTED: {
    name: 'RENAME_DEVICE_NOT_SUPPORTED',
    code: 'OA00103',
    message: 'Rename Device is not supported in this OS.',
  },
  OS_NOT_SUPPORTED_ACTION: {
    name: 'OS_NOT_SUPPORTED_ACTION',
    code: 'OA00104',
    message: 'The action is not supported for this OS.',
  },
  GET_WINDOWS_FEATURE_REQUIRE_PARAMS: {
    name: 'GET_WINDOWS_FEATURE_REQUIRE_PARAMS',
    code: 'OA00105',
    message: 'Required to define the parameters for the use of Get Windows Feature.',
  },
  ENABLE_WINDOWS_FEATURE_REQUIRE_PARAMS: {
    name: 'GET_WINDOWS_FEATURE_REQUIRE_PARAMS',
    code: 'OA00106',
    message: 'Required to define the parameters for the use of Enable Windows Feature.',
  },
  DISABLE_WINDOWS_FEATURE_REQUIRE_PARAMS: {
    name: 'DISABLE_WINDOWS_FEATURE_REQUIRE_PARAMS',
    code: 'OA00107',
    message: 'Required to define the parameters for the use of Disable Windows Feature.',
  },
  SDELTE_DOWNLOAD_ERROR: {
    name: 'SDELTE_DOWNLOAD_ERROR',
    code: 'OA00108',
    message: 'It is not possible to download the sdelete file.',
  },
  WMI_REQUIRIE_PARAMS: {
    name: 'WMI_REQUIRIE_PARAMS',
    code: 'OA00109',
    message: 'Required to define the parameters for the use of Execute WMI.',
  },
  ERROR_DOWNLOAD_AMT_PROFILE: {
    name: 'ERROR_DOWNLOAD_AMT_PROFILE',
    code: 'OA00110',
    message: 'The custom Intel® AMT Profile cannot be downloaded.',
  },
  AMT_FILES_UNAVAIABLE: {
    name: 'AMT_FILES_UNAVAIABLE',
    code: 'OA00111',
    message: 'Intel® AMT Files not available in the System.',
  },
  POWERSHELL_REQUIRIE_PARAMS: {
    name: 'POWERSHELL_REQUIRIE_PARAMS',
    code: 'OA00112',
    message: 'Required to define the parameters for the use of Execute Powershell.',
  },
  INSUFFICIENT_DATA_FOR_CONNECTION: {
    name: 'INSUFFICIENT_DATA_FOR_CONNECTION',
    code: 'OA00113',
    message: 'Insufficient data to create the connection.',
  },
  ALREADY_DEVICE_CONNECTION: {
    name: 'ALREADY_DEVICE_CONNECTION',
    code: 'OA00114',
    message: 'is already connected to this device.',
  },
  UNSUPPORTED_OS_FOR_INVENTORY: {
    name: 'UNSUPPORTED_OS_FOR_INVENTORY',
    code: 'OA00115',
    message: 'Sofware inventory is not supported on this OS.',
  },
  W10_OR_HIGHER_REQUIRE: {
    name: 'W10_OR_HIGHER_REQUIRE',
    code: 'OA00116',
    message: 'This feature requires Windows 10 or superior.',
  },
  ERROR_CONTACTING_DOMAIN: {
    name: 'ERROR_CONTACTING_DOMAIN',
    code: 'OA00117',
    message: 'The specified domain can’t be contacted.',
  },
  INVALID_DEVICE_NAME: {
    name: 'INVALID_DEVICE_NAME',
    code: 'OA00118',
    message: 'The device name is invalid, retry again with a new device name.',
  },
  UNSUPPORTED_OS_FOR_UPDATE: {
    name: 'UNSUPPORTED_OS_FOR_UPDATE',
    code: 'OA00119',
    message: ' Update OS is not supported for this OS.',
  },
  ERROR_GETTING_DEVICE_USER: {
    name: 'ERROR_GETTING_DEVICE_USER',
    code: 'OA00120',
    message: ' There was a problem getting device users.',
  },
  ERROR_SAVING_VTUL_INFO: {
    name: 'ERROR_SAVING_VTUL_INFO',
    code: 'OA00121',
    message: 'Error saving Intel® AMT infromation.',
  },
  GET_COMPANY_SETTINGS_ERROR: {
    name: 'GET_COMPANY_SETTINGS_ERROR',
    code: 'OA00122',
    message: 'Cannot get current company settings.',
  },
  UNSUPPORTED_CIRA: {
    name: 'UNSUPPORTED_CIRA',
    code: 'OA00123',
    message: 'This device does not support CIRA.',
  },
  DEVICE_WITHOUT_WIRELESS_CARD: {
    name: 'DEVICE_WITHOUT_WIRELESS_CARD',
    code: 'OA00124',
    message: 'This device does not have a wireless card for Intel® AMT.',
  },
  NEW_AMT_STATE_REQUIRE: {
    name: 'NEW_AMT_STATE_REQUIRE',
    code: 'OA00125',
    message: 'This device does not have a wireless card for Intel® AMT.',
  },
  DEVICE_WITHOUT_ACTIVE_WIRELESS_CARD: {
    name: 'DEVICE_WITHOUT_ACTIVE_WIRELESS_CARD',
    code: 'OA00126',
    message: 'The device does not have an active wireless card.',
  },
  NEW_CONSENT_REQUIRED: {
    name: 'NEW_CONSENT_REQUIRED',
    code: 'OA00127',
    message: 'The new user consent state is required.',
  },
  ADMIN_CONTROL_MODE_REQUIRED: {
    name: 'ADMIN_CONTROL_MODE_REQUIRED',
    code: 'OA00128',
    message: 'The device must be in Admin Control Mode to change the user consent.',
  },
  UNINSTALL_AMT_FILES: {
    name: 'UNINSTALL_AMT_FILES',
    code: 'OA00129',
    message: 'Intel® AMT Files not installed in the System.',
  },
  ERROR_GETTING_WEBRTC_CONFIG: {
    name: 'ERROR_GETTING_WEBRTC_CONFIG',
    code: 'OA00130',
    message: 'Agent could not get the WebRTC signaling information.',
  },
  SPECIFIC_EXECUTION_ERROR: {
    name: 'SPECIFIC_EXECUTION_ERROR',
    code: 'OA00131',
    message: 'Execution error:',
  },
  UPDATE_DOWNLOAD_ERROR_MD5: {
    name: 'UPDATE_DOWNLOAD_ERROR_MD5',
    code: 'OA00132',
    message: 'Downloaded update file does not match the expected hash.',
  },
  ERROR_DOWNLOADING_MPS_ROOT_CERTIFICATE: {
    name: 'ERROR_DOWNLOADING_MPS_ROOT_CERTIFICATE',
    code: 'OA00133',
    message: 'Error downloading MPS root certificate.',
  },
  ERROR_GETTING_MPS_ROOT_CERTIFICATE_INFO: {
    name: 'ERROR_GETTING_MPS_ROOT_CERTIFICATE_INFO',
    code: 'OA00134',
    message: 'Error getting MPS root certificate information.',
  },
  NOTIFICATIONS_NO_COMPATIBLE: {
    name: 'NOTIFICATIONS_NO_COMPATIBLE',
    code: 'OA00135',
    message: 'Notifications are not compatible with this system.',
  },
  NOTIFICATION_FILES_NOT_AVAILABLE: {
    name: 'NOTIFICATION_FILES_NOT_AVAILABLE',
    code: 'OA00136',
    message: 'Notification files are not available in the device.',
  },
  REMOTE_DESKTOP_NO_COMPATIBLE: {
    name: 'REMOTE_DESKTOP_NO_COMPATIBLE',
    code: 'OA00137',
    message: 'Remote desktop is not compatible with this system.',
  },
  REMOTE_DESKTOP_FILES_NOT_AVAILABLE: {
    name: 'REMOTE_DESKTOP_FILES_NOT_AVAILABLE',
    code: 'OA00138',
    message: 'Remote desktop files are not available in the System.',
  },
  INVALID_PARAMETERS: {
    name: 'INVALID_PARAMETERS',
    code: 'OA00139',
    message: 'Invalid or insufficient parameters to execute this action.',
  },
  REMOTE_DESKTOP_WHILE_OFFLINE: {
    name: 'REMOTE_DESKTOP_WHILE_OFFLINE',
    code: 'OA00140',
    message: 'Remote desktop connection requested while the computer was offline.',
  },
  ERROR_UNPROVISIONING_AMT: {
    name: 'ERROR_UNPROVISIONING_AMT',
    code: 'OA00141',
    message: 'Unexpected error unconfiguring Intel® AMT in this system.',
  },
  ONLY_MACOS_SUPPORTED: {
    name: 'ONLY_MACOS_SUPPORTED',
    code: 'OA00142',
    message: 'Only supported when running MacOs.',
  },
  W7_NOT_SUPPORTED: {
    name: 'W7_NOT_SUPPORTED',
    code: 'OA00143',
    message: 'This feature is not supported in Windows 7.',
  },
  GET_AGENT_SETTINGS_ERROR: {
    name: 'GET_AGENT_SETTINGS_ERROR',
    code: 'OA00144',
    message: 'Cannot get current agent settings.',
  },
  GET_DISK_HEALTH_STATUS: {
    name: 'GET_DISK_HEALTH_STATUS',
    code: 'OA00145',
    message: 'Could not get disk status.',
  },
  PASSWORD_MANAGER_FILES_NOT_AVAILABLE: {
    name: 'PASSWORD_MANAGER_FILES_NOT_AVAILABLE',
    code: 'OA00146',
    message: 'Password Manager Files are not available in the System.',
  },
  PASSWORD_MANAGER_INFO_TIMEOUT: {
    name: 'PASSWORD_MANAGER_INFO_TIMEOUT',
    code: 'OA00147',
    message: 'Timeout password manager helper.',
  },
  TIME_BETWEEN_EXECUTIONS: {
    name: 'TIME_BETWEEN_EXECUTIONS',
    code: 'OA00148',
    message: 'This execution is trying to run more often than allowed.',
  },
  COULD_NOT_FIND_PYTHON_ON_WINDOWS: {
    name: 'COULD_NOT_FIND_PYTHON_ON_WINDOWS',
    code: 'OA00149',
    message: 'Could not find python on Windows.',
  },
  AD_INFO_OUTDATE_OR_INVALID: {
    name: 'AD_INFO_OUTDATE_OR_INVALID',
    code: 'OA00150',
    message: 'AD information is outdated or invalid.',
  },
  ERROR_GETTING_AD_INFO: {
    name: 'ERROR_GETTING_AD_INFO',
    code: 'OA00151',
    message: 'Error getting information from AD.',
  },
  ERROR_CHANGING_WINDOWS_USER_PASSWORD: {
    name: 'ERROR_CHANGING_WINDOWS_USER_PASSWORD',
    code: 'OA00152',
    message: 'Could not change the local admin password.',
  },
  ERROR_VALIDATING_BIOS_PASSWORD: {
    name: 'ERROR_VALIDATING_BIOS_PASSWORD',
    code: 'OA00153',
    message: 'Could not validate the BIOS password.',
  },
  ERROR_CHANGING_BIOS_BOOT_ORDER: {
    name: 'ERROR_CHANGING_BIOS_BOOT_ORDER',
    code: 'OA00154',
    message: 'Could not change the BIOS boot order.',
  },
  ERROR_CHANGING_BIOS_PASSWORD: {
    name: 'ERROR_CHANGING_BIOS_PASSWORD',
    code: 'OA00155',
    message: 'Error changing BIOS password',
  },
  ERROR_GETTING_BIOS_PASSWORD_HISTORY: {
    name: 'ERROR_GETTING_BIOS_PASSWORD_HISTORY',
    code: 'OA00156',
    message: 'Could not get the BIOS password history.',
  },
  ENABLE_WINDOWS_UPDATE_ERROR: {
    name: 'ENABLE_WINDOWS_UPDATE_ERROR',
    code: 'OA00157',
    message: 'Could not enable Windows Update successfully.',
  },
  DISABLE_WINDOWS_UPDATE_ERROR: {
    name: 'ENABLE_WINDOWS_UPDATE_ERROR',
    code: 'OA00158',
    message: 'Could not disable Windows Update successfully.',
  },
  WINDOWS_DISTRIBUTION_UNSUPPORTED: {
    name: 'WINDOWS_DISTRIBUTION_UNSUPPORTED',
    code: 'OA00159',
    message: 'Windows distribution not supported.',
  },
  INVALID_BIOS_PASSWORD: {
    name: 'INVALID_BIOS_PASSWORD',
    code: 'OA00160',
    message: 'The current BIOS password is not valid.',
  },
  ERROR_TRANSFERRING_AGENT_LOGS: {
    name: 'ERROR_TRANSFERRING_AGENT_LOGS',
    code: 'OA00161',
    message: 'Error transferring agent log files.',
  },
  UNSUPPORTED_BIOS_PASSWORD_MANAGER: {
    name: 'UNSUPPORTED_BIOS_PASSWORD_MANAGER',
    code: 'OA00162',
    message: 'This action is not supported in this vendor.',
  },
  DRIVE_LIST_FILES_NOT_AVAILABLE: {
    name: 'DRIVE_LIST_FILES_NOT_AVAILABLE',
    code: 'OA00163',
    message: 'Drive List files are not available in the System.',
  },
  GET_PARTITIONS_W7PLUS_HAS_FAILED: {
    name: 'GET_PARTITIONS_W7PLUS_HAS_FAILED:',
    code: 'OA00164',
    message: 'Unable to get the partitions of the system',
  },
  DISK_HEALTH_HAS_FAILED: {
    name: 'DISK_HEALTH_HAS_FAILED:',
    code: 'OA00165',
    message: 'Unable to get the health status of the disks',
  },
  ENCRYPTION_STATUS_HAS_FAILED: {
    name: 'ENCRYPTION_STATUS_HAS_FAILED:',
    code: 'OA00166',
    message: 'Unable to get the encryption status of the partitions',
  },
  GET_PARTITIONS_WITH_DISK_HAS_FAILED: {
    name: 'GET_PARTITIONS_WITH_DISK_HAS_FAILED:',
    code: 'OA00167',
    message: 'Unable to get the partitions of the system',
  },
  NOT_HAVE_MBR2GPT: {
    name: 'NOT_HAVE_MBR2GPT:',
    code: 'OA00168',
    message: 'The device doesn\'t have the required files.',
  },
  VALIDATE_IF_CAN_CONVERT_TO_MBR_FAILED: {
    name: 'VALIDATE_IF_CAN_CONVERT_TO_MBR_FAILED:',
    code: 'OA00169',
    message: 'We cannot validate the selected disk',
  },
  BIOS_MANAGEMENT_DISABLED: {
    name: 'BIOS_MANAGEMENT_DISABLED',
    code: 'OA00170',
    message: 'BIOS management is disabled on this device.',
  },
  DEFAULT_BIOS_PASSWORD_NOT_CONFIGURED: {
    name: 'DEFAULT_BIOS_PASSWORD_NOT_CONFIGURED',
    code: 'OA00171',
    message: 'The default BIOS password is not configured, please check the active device group.',
  },
  BIOS_HAS_NO_PASSWORD: {
    name: 'BIOS_HAS_NO_PASSWORD',
    code: 'OA00172',
    message: 'There is not a password set in BIOS.',
  },
  BIOS_MANAGEMENT_PAUSED: {
    name: 'BIOS_MANAGEMENT_PAUSED',
    code: 'OA00173',
    message: 'BIOS management paused until BIOS password is verified in the Console.',
  },
  UNKNOWN_PASSWORD_TO_MANAGE_BIOS: {
    name: 'UNKNOWN_PASSWORD_TO_MANAGE_BIOS',
    code: 'OA00174',
    message: 'Unknown password to manage BIOS.',
  },
  RESTART_REQUIRED_TO_APPLY_BIOS_PASSWORD: {
    name: 'RESTART_REQUIRED_TO_APPLY_BIOS_PASSWORD',
    code: 'OA00175',
    message: 'The device needs to restart to apply the new BIOS password.',
  },
  RESTART_REQUIRED_TO_VALIDATE_BIOS_PASSWORD: {
    name: 'RESTART_REQUIRED_TO_VALIDATE_BIOS_PASSWORD',
    code: 'OA00176',
    message: 'It is necessary to restart to validate the password again.',
  },
  RESTART_AGAIN_REQUIRED_TO_VALIDATE_BIOS_PASSWORD: {
    name: 'RESTART_AGAIN_REQUIRED_TO_VALIDATE_BIOS_PASSWORD',
    code: 'OA00177',
    message: 'It is necessary to restart this device again to manage the BIOS.',
  },
  RESTART_REQUIRED_TO_APPLY_BOOT_ORDER: {
    name: 'RESTART_REQUIRED_TO_APPLY_BOOT_ORDER',
    code: 'OA00178',
    message: 'The device needs to restart to apply the boot order.',
  },
  BOOT_ORDER_NOT_CONFIGURED: {
    name: 'BOOT_ORDER_NOT_CONFIGURED',
    code: 'OA00179',
    message: 'There is not a boot order defined in the current device group.',
  },
  INVALID_PROFILE_CONFIGURATION: {
    name: 'INVALID_PROFILE_CONFIGURATION',
    code: 'OA00180',
    message: 'Invalid profile configuration, please check the current device group.',
  },
  LOCAL_ACCOUNT_MANAGEMENT_DISABLED: {
    name: 'WINDOWS_ACCOUNT_MANAGEMENT_DISABLED',
    code: 'OA00181',
    message: 'Local password management is not enabled.',
  },
  ERROR_CHANGING_WINDOWS_ACCOUNT_PASSWORD: {
    name: 'ERROR_CHANGING_WINDOWS_ACCOUNT_PASSWORD ',
    code: 'OA00182',
    message: 'Error changing Windows account password.',
  },
  ONLY_LENOVO_SUPPORTED: {
    name: 'ONLY_LENOVO_SUPPORTED ',
    code: 'OA00183',
    message: 'This action is only supported on Lenovo devices.',
  },
  BITLOCKER_MODULES_DISABLE: {
    name: 'BITLOCKER_MODULES_DISABLE',
    code: 'OA00184',
    message: 'BitLocker modules are disabled.',
  },
  ERROR_CREATING_PARTITION: {
    name: 'ERROR_CREATING_PARTITION',
    code: 'OA00185',
    message: 'We couldn\'t create a new partition or locate an existing one.',
  },
  DISK_CONVERSION_DOES_NOT_MEET_REQUIREMENT: {
    name: 'DISK_CONVERSION_DOES_NOT_MEET_REQUIREMENT',
    code: 'OA00186',
    message: 'The disk does not meet the requirements to make the conversion to GPT.',
  },
  INVALID_OR_INSUFFICIENT_PARAMS: {
    name: 'INVALID_OR_INSUFFICIENT_PARAMS',
    code: 'OA00187',
    message: 'Invalid or insufficient parameters to execute this action.',
  },
  ERROR_CHANGING_BOOT_TYPE: {
    name: 'ERROR_CHANGING_BOOT_TYPE',
    code: 'OA00188',
    message: 'We could not apply the new boot type.',
  },
  ERROR_UNINSTALLING_ONEDRIVE: {
    name: 'ERROR_UNINSTALLING_ONEDRIVE',
    code: 'OA00189',
    message: 'We could not uninstall Onedrive on this device.',
  },
  ERROR_STOPPING_ONEDRIVE_SERVICE: {
    name: 'ERROR_STOPPING_ONEDRIVE_SERVICE',
    code: 'OA00190',
    message: 'We could not stop the Onedrive service on this device.',
  },
  ONEDRIVE_IS_NOT_INSTALLED: {
    name: 'ONEDRIVE_IS_NOT_INSTALLED',
    code: 'OA00191',
    message: 'Onedrive is not installed on this device.',
  },
  ERROR_DOWNLOADING_MCAFEE_REMOVER: {
    name: 'ERROR_DOWNLOADING_MCAFEE_REMOVER',
    code: 'OA00192',
    message: 'Error downloading McAfee remover',
  },
  ERROR_EXTRACTING_MCAFEE_REMOVER: {
    name: 'ERROR_EXTRACTING_MCAFEE_REMOVER',
    code: 'OA00193',
    message: 'Error extracting McAfee remover',
  },
  ERROR_GETTING_DISKS: {
    name: 'ERROR_GETTING_DISKS',
    code: 'OA00194',
    message: 'Error getting disks',
  },
  ERROR_GETTING_VOLUMES: {
    name: 'ERROR_GETTING_VOLUMES',
    code: 'OA00195',
    message: 'Error getting volumes',
  },
  WMI_CLASSES_NOT_FOUND: {
    name: 'WMI_CLASSES_NOT_FOUND',
    code: 'OA00196',
    message: 'The device does not have the required WMI classes installed to manage the BIOS.',
  },
  ERROR_GETTING_DEVICE_GROUP: {
    name: 'ERROR_GETTING_DEVICE_GROUP',
    code: 'OA00197',
    message: 'Error getting device group',
  },
  WS_2012_OR_W8_1_OR_HIGHER: {
    name: 'WS_2012_OR_W10_OR_HIGHER',
    code: 'OA00198',
    message: 'This feature requires a superior Windows distribution than: Windows Server 2012 R2 or Windows 8.1.',
  },
  ACTION_GET_DISK_INFORMATION_REQUIRED: {
    name: 'ACTION_GET_DISK_INFORMATION_REQUIRED',
    code: 'OA00199',
    message: 'You need to run the action to get the disk and partitions information first',
  },
  BIOS_SETTING_NOT_FOUND: {
    name: 'BIOS_SETTING_NOT_FOUND',
    code: 'OA00200',
    message: 'The required BIOS settings cannot be found.',
  },
  ERROR_CHANGING_SECURE_BOOT: {
    name: 'ERROR_CHANGING_SECURE_BOOT',
    code: 'OA00201',
    message: 'We could not change the secure boot in this device.',
  },
  BIOS_NOT_SUPPORTED: {
    name: 'BIOS_NOT_SUPPORTED',
    code: 'OA00202',
    message: 'This BIOS currently is not supported.',
  },
  UNEXPECTED_ERROR_ENABLING_BITLOCKER: {
    name: 'UNEXPECTED_ERROR_ENABLING_BITLOCKER',
    code: 'OA00203',
    message: 'Unexpected error enabling BitLocker.',
  },
  UNEXPECTED_ERROR_DISABLING_BITLOCKER: {
    name: 'UNEXPECTED_ERROR_DISABLING_BITLOCKER',
    code: 'OA00204',
    message: 'Unexpected error disabling BitLocker.',
  },
  NO_PARTITIONS_FOUNDED: {
    name: 'NO_PARTITIONS_FOUNDED',
    code: 'OA00205',
    message: 'No partitions founded to enable BitLocker.',
  },
  INVALID_BITLOCKER_MINIMUM_REQUIREMENT: {
    name: 'INVALID__MINIMUM_REQUIREMENT',
    code: 'OA00206',
    message: 'Invalid BitLocker minimum requirements.',
  },
  PARTITION_LOCKED: {
    name: 'PARTITION_LOCKED',
    code: 'OA00207',
    message: 'The partition is locked',
  },
  ERROR_CLEANING_BITLOCKER_PASSWORDS: {
    name: 'ERROR_CLEANING_BITLOCKER_PASSWORDS',
    code: 'OA00208',
    message: 'Error cleaning old BitLocker key protectors',
  },
  ERROR_DISABLING_AUTOUNLOCK: {
    name: 'ERROR_DISABLING_AUTOUNLOCK',
    code: 'OA00209',
    message: 'Error disabling auto unlock',
  },
  CCTK_TIMEOUT: {
    name: 'CCTK_TIMEOUT',
    code: 'OA00210',
    message: 'Timeout calling Dell BIOS helper.',
  },
  CCTK_ERROR: {
    name: 'CCTK_ERROR',
    code: 'OA00211',
    message: 'Error executing Dell BIOS helper.',
  },
  ERROR_ENABLING_AUTOUNLOCK: {
    name: 'ERROR_ENABLING_AUTOUNLOCK',
    code: 'OA00212',
    message: 'Error enabling auto unlock',
  },
  BITLOCKER_NOT_SUPPORTED: {
    name: 'BITLOCKER_NOT_SUPPORTED',
    code: 'OA00213',
    windows_code: '2150695002',
    message: 'BitLocker is not supported in this windows distribution.',
  },
  REMOTE_ACCESS_DENIED: {
    name: 'REMOTE_ACCESS_DENIED',
    code: 'OA00214',
    message: 'The remote connection was rejected.',
  },
  ERROR_REQUESTING_REMOTE_ACCESS: {
    name: 'ERROR_REQUESTING_REMOTE_ACCESS',
    code: 'OA00215',
    message: 'Unexpected error requesting remote access.',
  },
  ERROR_UPDATING_AGENT_DEPENDENCIES: {
    name: 'ERROR_UPDATING_AGENT_DEPENDENCIES',
    code: 'OA00216',
    message: 'Error updating agent dependencies.',
  },
  ERROR_GETTING_FOLDER_DEPENDENCIES: {
    name: 'ERROR_GETTING_FOLDER_DEPENDENCIES',
    code: 'OA00217',
    message: 'The dependencies have not been updated, please try again later.',
  },
  NO_REMOTE_DESKTOP_RESPONSE: {
    name: 'NO_REMOTE_DESKTOP_RESPONSE',
    code: 'OA00218',
    message: 'No response from remote desktop request.',
  },
  ERROR_CREATING_PROCESS_AS_CURRENT_USER: {
    name: 'ERROR_CREATING_PROCESS_AS_CURRENT_USER',
    code: 'OA00219',
    message: 'Unexpected error creating process as current user.',
  },
  NO_LOGGED_IN_USER: {
    name: 'NO_LOGGED_IN_USER',
    code: 'OA00220',
    message: 'At this time there is no user with an active session to display the user consent.',
  },
  ERROR_UNINSTALLING_ANYDESK: {
    name: 'ERROR_UNINSTALLING_ANYDESK',
    code: 'OA00221',
    message: 'We could not uninstall AnyDesk on this device.',
  },
  ANYDESK_IS_NOT_INSTALLED: {
    name: 'ANYDESK_IS_NOT_INSTALLED',
    code: 'OA00222',
    message: 'AnyDesk is not installed on this device.',
  },
  ERROR_REMOVING_ANYDESK_FILES: {
    name: 'ERROR_REMOVING_ANYDESK_FILES',
    code: 'OA00223',
    message: 'We could not remove AnyDesk files on this device.',
  },
  ANYDESK_FILES_NOT_FOUND: {
    name: 'ANYDESK_FILES_NOT_FOUND',
    code: 'OA00224',
    message: 'We didn\'t find any anydesk files.',
  },
  NOT_SCREEN_CAPTURE_PERMISSIONS_IN_MAC: {
    name: 'NOT_SCREEN_CAPTURE_PERMISSIONS_IN_MAC',
    code: 'OA00225',
    message: 'Agent does not have privacy permissions to capture the screen of macOS.',
  },
  WSUS_SERVER_UPDATES_UNREACHABLE: {
    name: 'WSUS_SERVER_UPDATES_UNREACHABLE',
    code: 'OA00226',
    message: 'We couldn\'t connect to the update service',
  },
  ERROR_CHANGING_TPM_STATUS: {
    name: 'ERROR_CHANGING_TPM_STATUS',
    code: 'OA00227',
    message: 'We could not change the TPM status.',
  },
  ERROR_LOCKING_DEVICE: {
    name: 'ERROR_LOCKING_DEVICE',
    code: 'OA00228',
    message: 'We couldn\'t lock the device',
  },
  ERROR_GETTING_RECOVERY_KEY: {
    name: 'ERROR_GETTING_RECOVERY_KEY',
    code: 'OA00229',
    message: 'We couldn\'t getting recovery key',
  },
  ERROR_DOWNLOADING_INSTALLER: {
    name: 'ERROR_DOWNLOADING_INSTALLER',
    code: 'OA00230',
    message: 'Unexpected error downloading software installer.',
  },
  ERROR_REQUESTING_INSTALLER: {
    name: 'ERROR_REQUESTING_INSTALLER',
    code: 'OA00231',
    message: 'Unexpected error getting installer.',
  },
  ERROR_RENAMING_INSTALLER: {
    name: 'ERROR_RENAMING_INSTALLER',
    code: 'OA00232',
    message: 'Unexpected error renaming installer.',
  },
  ERROR_DISABLING_AUTORESEAL: {
    name: 'ERROR_DISABLING_AUTORESEAL',
    code: 'OA00233',
    message: 'Error disabling auto-reseal in registry.',
  },
  ERROR_ENABLING_AUTORESEAL: {
    name: 'ERROR_ENABLING_AUTORESEAL',
    code: 'OA00234',
    message: 'Error enabling auto-reseal in registry.',
  },
  ERROR_GETTING_TPM_AUTORESEAL_STATUS: {
    name: 'ERROR_GETTING_TPM_AUTORESEAL_STATUS',
    code: 'OA00235',
    message: 'Error getting BitLocker auto-reseal registry status.',
  },
  WMIC_DEPRECATED_IN_WINDOWS_11: {
    name: 'WMIC_DEPRECATED_IN_WINDOWS_11',
    code: 'OA00236',
    message: 'The WMI command-line (WMIC) is deprecated in Windows 11. To execute a WMI query in newer Windows versions, use the cmdlet Get-WmiObject in the PowerShell action',
  },
  ERROR_CHECKING_RECOVERY_KEYS: {
    name: 'ERROR_CHECKING_RECOVERY_KEYS',
    code: 'OA00237',
    message: 'Unexpected error checking recovery keys.',
  },
  BOARDGENT_LOGS_FILE_NOT_FOUND: {
    name: 'BOARDGENT_LOGS_FILE_NOT_FOUND',
    code: 'OA00238',
    message: 'We could not find the Agent logs.',
  },
  ERROR_REPORTING_BITLOCKER_PERCENTAGE: {
    name: 'ERROR_REPORTING_BITLOCKER_PERCENTAGE',
    code: 'OA00239',
    message: 'Unexpected error reporting BitLocker percentage.',
  },
  ERROR_PAUSING_CONVERSION: {
    name: 'ERROR_PAUSING_CONVERSION',
    code: 'OA00240',
    message: 'Unexpected error pausing conversion.',
  },
  ERROR_RESUMING_CONVERSION: {
    name: 'ERROR_RESUMING_CONVERSION',
    code: 'OA00241',
    message: 'Unexpected error resuming conversion.',
  },
  ERROR_GETTING_CONVERSION_STATUS: {
    name: 'ERROR_RESUMING_CONVERSION',
    code: 'OA00242',
    message: 'Unexpected error getting conversion status.',
  },
  ERROR_NO_RECOVERY_PASSWORD_KEY: {
    name: 'ERROR_NO_RECOVERY_PASSWORD_KEY',
    code: 'OA00243',
    message: 'We could not find the recovery password key.',
  },
  ERROR_UNLOCKING_PARTITION: {
    name: 'ERROR_UNLOCKING_PARTITION',
    code: 'OA00244',
    message: 'Unexpected error unlocking partition.',
  },
  ERROR_FILE_IN_USE: {
    name: 'ERROR_FILE_IN_USE',
    code: 'OA00245',
    message: 'We were unable to transfer the file, please check that the file is not in use and try again.',
  },
  ERROR_DELETING_TEMP_FILE: {
    name: 'ERROR_DELETING_TEMP_FILE',
    code: 'OA00246',
    message: 'We could not delete the temporal file.',
  },
  ERROR_NO_BOOT_PARTITION: {
    name: 'ERROR_NO_BOOT_PARTITION',
    code: 'OA00247',
    message: 'Boot partition not found.',
  },
  ERROR_ADDING_TPM_KEY: {
    name: 'ERROR_ADDING_TPM_KEY',
    code: 'OA00248',
    message: 'Error adding TPM key.',
  },
  ERROR_ENABLING_PROTECTION: {
    name: 'ERROR_ENABLING_PROTECTION',
    code: 'OA00249',
    message: 'Unexpected error enabling BitLocker protection.',
  },
  ERROR_DISABLING_PROTECTION: {
    name: 'ERROR_DISABLING_PROTECTION',
    code: 'OA00250',
    message: 'Unexpected error disabling BitLocker protection.',
  },
  ERROR_GETTING_PROTECTION_STATUS: {
    name: 'ERROR_GETTING_PROTECTION_STATUS',
    code: 'OA00251',
    message: 'Unexpected error getting BitLocker protection status.',
  },
  ERROR_GETTING_DEVICE_CERTIFICATE: {
    name: 'ERROR_GETTING_DEVICE_CERTIFICATE',
    code: 'OA000252',
    message: 'Error getting client certificate or private key',
  },
  ERROR_GETTING_DEVICE_CERTIFICATE_DATA: {
    name: 'ERROR_GETTING_DEVICE_CERTIFICATE_DATA',
    code: 'OA000253',
    message: 'Error getting client certificate data',
  },
  DEVICE_CERTIFICATE_DATA_DOES_NOT_MATCH: {
    name: 'DEVICE_CERTIFICATE_DATA_DOES_NOT_MATCH',
    code: 'OA000254',
    message: 'The certificate data does not match the device to be authenticated',
  },
  ERROR_CREATING_DEVICE_CERTIFICATE: {
    name: 'ERROR_CREATING_DEVICE_CERTIFICATE',
    code: 'OA000255',
    message: 'Error creating client certificate',
  },
  ERROR_VERIFYING_CERTIFICATE_SIGNATURE: {
    name: 'ERROR_VERIFYING_CERTIFICATE_SIGNATURE',
    code: 'OA000256',
    message: 'Error verifying certificate signature',
  },
  URL_TO_DISTRIBUTE_MISSING: {
    name: 'URL_TO_DISTRIBUTE_MISSING',
    code: 'OA000257',
    message: 'Error downloading the file. Missing URL to distribute. Please try again.',
  },
  EXECUTE_SOFTWARE_ERROR: {
    name: 'EXECUTE_SOFTWARE_ERROR',
    code: 'OA000258',
    message: 'Error executing the software.',
  },
  ERROR_DOWNLOADING_DISTRIBUTE_FILE: {
    name: 'ERROR_DOWNLOADING_DISTRIBUTE_FILE',
    code: 'OA000259',
    message: 'Error downloading the file.',
  },
  ERROR_REQUESTING_DISTRIBUTION_FILE: {
    name: 'ERROR_REQUESTING_DISTRIBUTION_FILE',
    code: 'OA000260',
    message: 'Unexpected error getting distribution file.',
  },
  MACOS_NOT_SUPPORTED: {
    name: 'MACOS_NOT_SUPPORTED',
    code: 'OA000262',
    message: 'This action is not supported in MacOS.',
  },
  ERROR_DISABLING_CONTROL_PANEL: {
    name: 'ERROR_DISABLING_CONTROL_PANEL',
    code: 'OA00253',
    message: 'Error disabling control panel in registry.',
  },
  ERROR_ENABLING_CONTROL_PANEL: {
    name: 'ERROR_ENABLING_CONTROL_PANEL',
    code: 'OA00254',
    message: 'Error enabling control panel in registry.',
  },
  ERROR_GETTING_ENCRYPTION_METHOD: {
    name: 'ERROR_GETTING_ENCRYPTION_METHOD',
    code: 'OA00255',
    message: 'Unexpected error getting encryption method.',
  },
  TOO_MANY_CONNECTED_USERS: {
    name: 'TOO_MANY_CONNECTED_USERS',
    code: 'OA00256',
    message: 'There are too many users connected to the same remote computer. Wait for the other users to disconnect. ',
  },
  IS_NOT_WINDOWS_CEE_DISTRIBUTION: {
    name: 'IS_NOT_WINDOWS_CEE_DISTRIBUTION',
    code: 'OA00257',
    message: 'This Windows distribution is not supported. This action only works in enterprise, educational, and core editions.',
  },
  UNEXPECTED_ERROR_ENABLING_UWF: {
    name: 'UNEXPECTED_ERROR_ENABLING_UWF',
    code: 'OA00258',
    message: 'Unexpected error enabling UWF.',
  },
  UNEXPECTED_ERROR_DISABLING_UWF: {
    name: 'UNEXPECTED_ERROR_DISABLING_UWF',
    code: 'OA00259',
    message: 'Unexpected error disabling UWF.',
  },
  UWF_IS_NOT_ENABLED: {
    name: 'UWF_IS_NOT_ENABLED',
    code: 'OA00260',
    message: 'UWF is not enabled.',
  },
  UNEXPECTED_ERROR_RENAMING_THE_DEVICE: {
    name: 'UNEXPECTED_ERROR_RENAMING_THE_DEVICE',
    code: 'OA00261',
    message: 'Unexpected error renaming the device.',
  },
  ERROR_ENABLING_CMD: {
    name: 'ERROR_ENABLING_CMD',
    code: 'OA00262',
    message: 'Error enabling CMD.',
  },
  ERROR_DISABLING_CMD: {
    name: 'ERROR_DISABLING_CMD',
    code: 'OA00263',
    message: 'Error disabling CMD.',
  },
  ERROR_ENABLING_POWERSHELL: {
    name: 'ERROR_ENABLING_POWERSHELL',
    code: 'OA00264',
    message: 'Error enabling PowerShell.',
  },
  ERROR_DISABLING_POWERSHELL: {
    name: 'ERROR_DISABLING_POWERSHELL',
    code: 'OA00265',
    message: 'Error disabling PowerShell.',
  },
  ERROR_GETTING_REGISTRY_USERS_AND_IDS: {
    name: 'ERROR_GETTING_REGISTRY_USERS_AND_IDS',
    code: 'OA00266',
    message: 'Unexpected error getting the users.',
  },
  ERROR_DELETING_DEVICE_CERTIFICATE: {
    name: 'ERROR_DELETING_DEVICE_CERTIFICATE',
    code: 'OA000267',
    message: 'Error deleting client certificate',
  },
  ERROR_ENABLING_DISCLAIMER_ON_SIGN_IN: {
    name: 'ERROR_ENABLING_DISCLAIMER_ON_SIGN_IN',
    code: 'OA00268',
    message: 'Error enabling disclaimer on sign in.',
  },
  ERROR_DISABLING_DISCLAIMER_ON_SIGN_IN: {
    name: 'ERROR_DISABLING_DISCLAIMER_ON_SIGN_IN',
    code: 'OA00269',
    message: 'Error disabling disclaimer on sign in.',
  },
  ERROR_DISABLING_MSI_INSTALLATION: {
    name: 'ERROR_DISABLING_MSI_INSTALLATION',
    code: 'OA00270',
    message: 'Error disabling MSI installation.',
  },
  ERROR_ENABLING_MSI_INSTALLATION: {
    name: 'ERROR_ENABLING_MSI_INSTALLATION',
    code: 'OA00271',
    message: 'Error enabling MSI installation.',
  },
  ERROR_SETTING_NOT_SAVE_PASSWORD_IN_LAN_MANAGER: {
    name: 'ERROR_SETTING_NOT_SAVE_PASSWORD_IN_LAN_MANAGER',
    code: 'OA00272',
    message: 'Unexpected error setting not save password in LAN Manager.',
  },
  ERROR_SETTING_SAVE_PASSWORD_IN_LAN_MANAGER: {
    name: 'ERROR_SETTING_SAVE_PASSWORD_IN_LAN_MANAGER',
    code: 'OA00273',
    message: 'Unexpected error setting save password in LAN Manager.',
  },
  ERROR_DISABLING_LOCAL_ADMIN_USER: {
    name: 'ERROR_DISABLING_LOCAL_ADMIN_USER',
    code: 'OA00274',
    message: 'Unexpected error disabling local administrator user.',
  },
  ERROR_ENABLING_LOCAL_ADMIN_USER: {
    name: 'ERROR_ENABLING_LOCAL_ADMIN_USER',
    code: 'OA00274',
    message: 'Unexpected error enabling local administrator user.',
  },
  ERROR_SETTING_IDLE_LOCK_TIMEOUT: {
    name: 'ERROR_SETTING_IDLE_LOCK_TIMEOUT',
    code: 'OA00275',
    message: 'Error setting idle lock timeout.',
  },
  ERROR_DISABLING_IDLE_LOCK_TIMEOUT: {
    name: 'ERROR_DISABLING_IDLE_LOCK_TIMEOUT',
    code: 'OA00276',
    message: 'Error disabling idle lock timeout.',
  },
  ERROR_GETTING_SECONDS_IN_LOCKING_DEVICE: {
    name: 'ERROR_GETTING_SECONDS_IN_LOCKING_DEVICE',
    code: 'OA00277',
    message: 'Unexpected error getting the time to lock the device.',
  },
  ERROR_BLOCKING_UNSUCCESSFUL_LOGINS: {
    name: 'ERROR_BLOCKING_UNSUCCESSFUL_LOGINS',
    code: 'OA00278',
    message: 'Unexpected error blocking unsuccessful logins.',
  },
  ERROR_UNBLOCKING_UNSUCCESSFUL_LOGINS: {
    name: 'ERROR_UNBLOCKING_UNSUCCESSFUL_LOGINS',
    code: 'OA00279',
    message: 'Unexpected error unblocking unsuccessful logins.',
  },
  NOTIFICATION_NOT_ANSWERED: {
    name: 'NOTIFICATION_NOT_ANSWERED',
    code: 'OA00279',
    message: 'Notification not answered.',
  },
  COULD_NOT_TAKE_TPM_OWNERSHIP: {
    name: 'COULD_NOT_TAKE_TPM_OWNERSHIP',
    code: 'OA00280',
    message: 'Error taking TPM ownership.',
  },
  ERROR_CHECKING_IF_TPM_IS_OWNED: {
    name: 'ERROR_CHECKING_IF_TPM_IS_OWNED',
    code: 'OA00281',
    message: 'Error checking if TPM is owned.',
  },
  NO_DEVICE_USER_FOUND: {
    name: 'NO_DEVICE_USER_FOUND',
    code: 'OA00282',
    message: 'No device users found.',
  },
  ERROR_GETTING_GATEWAY_MAC_ADDRESS: {
    name: 'ERROR_GETTING_GATEWAY_MAC_ADDRESS',
    code: 'OA00283',
    message: 'Could not get a valid gateway mac address',
  },
  ERROR_GETTING_WIFI_ACCESS_POINTS_MAC_ADDRESS: {
    name: 'ERROR_GETTING_WIFI_ACCESS_POINTS_MAC_ADDRESS',
    code: 'OA00284',
    message: 'Could not get wifi access points mac address',
  },
  FILE_TRANSFER_WHILE_OFFLINE: {
    name: 'FILE_TRANSFER_WHILE_OFFLINE',
    code: 'OA00285',
    message: 'File transfer connection requested while the computer was offline.',
  },
  ERROR_INITIALIZING_TPM: {
    name: 'ERROR_INITIALIZING_TPM',
    code: 'OA00286',
    message: 'Error initializing TPM.',
  },
  TPM_NOT_READY: {
    name: 'TPM_NOT_READY',
    code: 'OA00287',
    message: 'TPM is not ready.',
  },
  ERROR_INSUFFICIENT_DISK_SPACE: {
    name: 'ERROR_INSUFFICIENT_DISK_SPACE',
    code: 'OA00288',
    message: 'The destination disk does not have enough space to receive the file you are trying to send.',
  },
  ERROR_ADDING_EXTERNAL_KEY: {
    name: 'ERROR_ADDING_EXTERNAL_KEY',
    code: 'OA00289',
    message: 'Error adding external key.',
  },
  ERROR_DELETING_REAGENT_FILE: {
    name: 'ERROR_DELETING_REAGENT_FILE',
    code: 'OA00290',
    message: 'Error deleting corrupted reagent file.',
  },
  ONLY_DELL_SUPPORTED: {
    name: 'ONLY_DELL_SUPPORTED ',
    code: 'OA00291',
    message: 'This action is only supported on Dell devices.',
  },
  DELL_BIOS_VERSION_NOT_SUPPORTED: {
    name: 'DELL_BIOS_VERSION_NOT_SUPPORTED',
    code: 'OA00292',
    message: 'BIOS version is not compatible with Dell Command | Monitor version',
  },
  ERROR_CONNECTING_TO_WEBRTC_SERVER: {
    name: 'ERROR_CONNECTING_TO_WEBRTC_SERVER',
    code: 'OA00293',
    message: 'Agent was not able to connect to the WebRTC Boardgent server.',
  },
  ERROR_CREATING_THE_WRTC_CONNECTION: {
    name: 'ERROR_CREATING_THE_WRTC_CONNECTION',
    code: 'OA00294',
    message: 'The peer-to-peer connection is taking longer than expected, please try again in a moment.',
  },
  UNEXPECTED_ERROR_ENABLING_FILEVAULT: {
    name: 'UNEXPECTED_ERROR_ENABLING_FILEVAULT',
    code: 'OA00295',
    message: 'Unexpected error enabling FileVault.',
  },
  UNEXPECTED_ERROR_DISABLING_FILEVAULT: {
    name: 'UNEXPECTED_ERROR_DISABLING_FILEVAULT',
    code: 'OA00296',
    message: 'Unexpected error disabling FileVault.',
  },
  ENCRYPTION_IN_PROGRESS: {
    name: 'ENCRYPTION_IN_PROGRESS',
    code: 'OA00297',
    message: 'Encryption in progress. Unable to decrypt at this moment.',
  },
  DECRYPTION_IN_PROGRESS: {
    name: 'DECRYPTION_IN_PROGRESS',
    code: 'OA00298',
    message: 'Decryption in progress. Unable to encrypt at this moment.',
  },
  UNEXPECTED_ERROR_VALIDATING_FILEVAULT_KEYS: {
    name: 'DECRYPTION_IN_PROGRESS',
    code: 'OA00299',
    message: 'Unexpected error validating FileVault recovery keys.',
  },
  UNEXPECTED_ERROR_VALIDATING_TPM_KEY: {
    name: 'UNEXPECTED_ERROR_VALIDATING_TPM_KEY',
    code: 'OA00300',
    message: 'Unexpected error validating if a TPM key exist.',
  },
  SCREEN_TRANSMISSION_WAIT_TIMEOUT: {
    name: 'SCREEN_TRANSMISSION_WAIT_TIMEOUT',
    code: 'OA00301',
    message: 'The remote desktop session ended due to an unexpected error occurred during the screen capture.',
  },
});

exports.programmerAgentErrors = new Enum({
  UNDEFINED_FEATHERS_INSTANCE: {
    name: 'UNDEFINED_FEATHERS_INSTANCE',
    code: 'PA00001',
    message: 'Feathers client is not created.',
  },
  NOT_AMT_MODIFY: {
    name: 'NOT_AMT_MODIFY',
    code: 'PA00002',
    message: 'Intel® AMT Profile cannot be modified.',
  },
  ERROR_PARSE_XML: {
    name: 'ERROR_PARSE_XML',
    code: 'PA00003',
    message: 'Unable to parse XML.',
  },
  INVALID_RESPONSE: {
    name: 'INVALID_RESPONSE',
    code: 'PA00004',
    message: 'Unexpected error, invalid response.',
  },
  EXECUTION_ERROR: {
    name: 'EXECUTION_ERROR',
    code: 'PA00005',
    message: 'Unexpected error during the execution.',
  },
  EXECUTE_COMMAND_ERROR: {
    name: 'EXECUTE_COMMAND_ERROR',
    code: 'PA00006',
    message: 'Unexpected error executing the command.',
  },
  ERROR_CREATE_USER_PROCESS: {
    name: 'ERROR_CREATE_USER_PROCESS',
    code: 'PA00007',
    message: 'Unexpected error creating user process.',
  },
  ERROR_EXTRACTING_FILES: {
    name: 'ERROR_EXTRACTING_FILES',
    code: 'PA00008',
    message: 'Unexpected error extracting the required files.',
  },
  EXECUTE_POWERSHELL_COMMAND_ERROR: {
    name: 'EXECUTE_POWERSHELL_COMMAND_ERROR',
    code: 'PA00009',
    message: 'Unexpected error executing the PowerShell command.',
  },
  EXECUTE_REGISTRY_UTIL_ERROR: {
    name: 'EXECUTE_REGISTRY_UTIL_ERROR',
    code: 'PA00010',
    message: 'Unexpected error calling registry utils.',
  },
  CHECK_IF_BIOS_HAS_PASSWORD_ERROR: {
    name: 'CHECK_IF_BIOS_HAS_PASSWORD_ERROR',
    code: 'PA00011',
    message: 'Unexpected error validating if BIOS has a password set.',
  },
  ERROR_VALIDATING_CURRENT_BIOS_PASSWORD: {
    name: 'ERROR_VALIDATING_CURRENT_BIOS_PASSWORD',
    code: 'PA00012',
    message: 'Unexpected error validating current BIOS password.',
  },
  ERROR_CONNECTING_TO_AD: {
    name: 'ERROR_CONNECTING_TO_AD',
    code: 'PA00013',
    message: 'Unexpected error connecting to Active Directory.',
  },
  ERROR_REMOVING_PASSWORD_MANAGER_REGISTRY_KEYS: {
    name: 'ERROR_REMOVING_PASSWORD_MANAGER_REGISTRY_KEYS',
    code: 'PA00014',
    message: 'Unexpected error removing the required registries.',
  },
  ERROR_GETTING_AD_INFORMATION: {
    name: 'ERROR_GETTING_AD_INFORMATION',
    code: 'PA00015',
    message: 'Unexpected error getting information from AD.',
  },
  ERROR_REPORTING_BIOS_PASSWORD_HISTORY: {
    name: 'ERROR_REPORTING_BIOS_PASSWORD_HISTORY',
    code: 'PA00016',
    message: 'Unexpected error reporting BIOS password history.',
  },
  ERROR_GETTING_DEVICE_FROM_API: {
    name: 'ERROR_GETTING_DEVICE_FROM_API',
    code: 'PA00017',
    message: 'Error getting the device information from the API.',
  },
  ERROR_GETTING_STARTUP_TIME: {
    name: 'ERROR_GETTING_STARTUP_TIME ',
    code: 'PA00018',
    message: 'Error getting startup time of this device.',
  },
  ERROR_GETTING_BOOT_DEVICES: {
    name: 'ERROR_GETTING_BOOT_DEVICES ',
    code: 'PA00019',
    message: 'Unexpected error getting boot devices.',
  },
  ERROR_BUILDING_BOOT_ORDER: {
    name: 'ERROR_BUILDING_BOOT_ORDER ',
    code: 'PA00020',
    message: 'Error building new boot order.',
  },
  ERROR_APPLYING_NEW_BOOT_ORDER: {
    name: 'ERROR_APPLYING_NEW_BOOT_ORDER ',
    code: 'PA00021',
    message: 'Error applying the new boot order.',
  },
  ERROR_CHANGING_BIOS_PASSWORD: {
    name: 'ERROR_CHANGING_BIOS_PASSWORD ',
    code: 'PA00022',
    message: 'Unexpected error changing BIOS password.',
  },
  ERROR_GETTING_ADMIN_ACCOUNT_NAME: {
    name: 'ERROR_GETTING_ADMIN_ACCOUNT_NAME ',
    code: 'PA00023',
    message: 'Error getting the name of the current administrator account.',
  },
  ERROR_CHANGING_WINDOWS_ACCOUNT_PASSWORD: { // To support old agent version 19.1.6
    name: 'ERROR_CHANGING_WINDOWS_ACCOUNT_PASSWORD ',
    code: 'PA00024',
    message: 'Unexpected error changing Windows account password.',
  },
  ERROR_CHANGING_LOCAL_ACCOUNT_PASSWORD: {
    name: 'ERROR_CHANGING_LOCAL_ACCOUNT_PASSWORD ',
    code: 'PA00024',
    message: 'Unexpected error changing local account password.',
  },
  ERROR_FINDING_BIOS_TYPE_OPTION: {
    name: 'ERROR_FINDING_BIOS_TYPE_OPTION ',
    code: 'PA00025',
    message: 'We could not detect the way to change the boot order in this computer.',
  },
  ERROR_CHANGING_BIOS_CSM_MODE: {
    name: 'ERROR_CHANGING_BIOS_CSM_MODE ',
    code: 'PA00026',
    message: 'We could not change the CSM mode in the BIOS.',
  },
  UNEXPECTED_RESPONSE_GETTING_BIOS_SETTING: {
    name: 'UNEXPECTED_RESPONSE_GETTING_BIOS_SETTING ',
    code: 'PA00027',
    message: 'Error parsing BIOS response.',
  },
  UNEXPECTED_ERROR_CONVERTING_TO_GPT: {
    name: 'UNEXPECTED_ERROR_CONVERTING_TO_GPT:',
    code: 'PA00028',
    message: 'Unexpected error converting the current disk to GPT',
  },
  UNEXPECTED_ERROR_UNINSTALLING_MCAFEE: {
    name: 'UNEXPECTED_ERROR_UNINSTALLING_MCAFEE',
    code: 'PA00029',
    message: 'Unexpected error uninstalling McAfee',
  },
  PASSWORD_CANNOT_CHANGE: {
    name: 'PASSWORD_CANNOT_CHANGE',
    code: 'PA00030',
    helpsgCode: '2243',
    message: 'The password of this user cannot change.',
  },
  COMPLEXITY_PASSWORD_ERROR: {
    name: 'COMPLEXITY_PASSWORD_ERROR',
    code: 'PA00031',
    helpmsgCode: '2245',
    message: 'The password does not meet the device password policy requirements.',
  },
  PASSWORD_TOO_RECENT_FOR_CHANGE: {
    name: 'ERROR_RECENT_PASSWORD_CHANGE',
    code: 'PA00032',
    helpmsgCode: '2246',
    message: 'The password of this user is too recent to change.',
  },
  ERROR_REPORTING_THE_NEW_BIOS_PASSWORD: {
    name: 'ERROR_REPORTING_THE_NEW_BIOS_PASSWORD',
    code: 'PA00033',
    message: 'Error saving the new BIOS password in the DB.',
  },
  UNEXPECTED_ERROR_BITLOCKER_KEYS: {
    name: 'UNEXPECTED_ERROR_BITLOCKER_KEYS',
    code: 'PA00034',
    message: 'Unexpected error getting BitLocker encryption keys.',
  },
  UNEXPECTED_ERROR_REQUESTING_MAC_PERMISSIONS: {
    name: 'UNEXPECTED_ERROR_REQUESTING_MAC_PERMISSIONS',
    code: 'PA00035',
    message: 'Unexpected error requesting MacOS permissions',
  },
  UNEXPECTED_ERROR_WINDOWS_UPDATES: {
    name: 'UNEXPECTED_ERROR_WINDOWS_UPDATES',
    code: 'PA00036',
    message: 'Unexpected error getting Windows updates.',
  },
  UNEXPECTED_ERROR_CHECKING_AGENT_UPDATES: {
    name: 'UNEXPECTED_ERROR_CHECKING_AGENT_UPDATES',
    code: 'PA00037',
    message: 'Unexpected error getting Agent updates.',
  },
  ERROR_CHANGING_MICROSOFT_ACCOUNT_PASSWORD: {
    name: 'ERROR_CHANGING_MICROSOFT_ACCOUNT_PASSWORD',
    code: 'PA00038',
    helpmsgCode: '8646',
    message: 'We can not manage Microsoft account\'s password',
  },
  ERROR_GETTING_BIOS_PASSWORDS_FROM_OLD_AGENT_VERSION: {
    name: 'ERROR_GETTING_BIOS_PASSWORDS_FROM_OLD_AGENT_VERSION',
    code: 'PA00039',
    message: 'Unexpected error getting the BIOS passwords from the old agent version.',
  },
  ERROR_GETTING_LAST_BIOS_PASSWORD_FROM_LOGS: {
    name: 'ERROR_GETTING_LAST_BIOS_PASSWORD_FROM_LOGS',
    code: 'PA00040',
    message: 'Unexpected error getting the last BIOS password from the old agent version.',
  },
  UNEXPECTED_ERROR_REMOVING_FILES: {
    name: 'UNEXPECTED_ERROR_REMOVING_FILES',
    code: 'PA00041',
    message: 'Unexpected error removing files.',
  },
  UNEXPECTED_ERROR_EXECUTING_REMOVING_FILES: {
    name: 'UNEXPECTED_ERROR_EXECUTING_REMOVING_FILES',
    code: 'PA00042',
    message: 'Unexpected error removing files.',
  },
  LOCAL_ACCOUNT_NOT_FOUND: {
    name: 'LOCAL_ACCOUNT_NOT_FOUND',
    code: 'PA00043',
    message: 'The user name could not be found',
  },
  UNEXPECTED_ERROR_CHANGING_ADMIN_PASSWORD: {
    name: 'UNEXPECTED_ERROR_CHANGING_ADMIN_PASSWORD',
    code: 'PA00044',
    message: 'Unexpected error changing the password of the current account',
  },
  TOO_LONG_LOCAL_ACCOUNT_NAME: {
    name: 'TOO_LONG_LOCAL_ACCOUNT_NAME',
    code: 'PA00045',
    message: 'The local account name must not be longer than',
  },
  UNEXPECTED_ERROR_EXTRACTING_FILES: {
    name: 'UNEXPECTED_ERROR_EXTRACTING_FILES',
    code: 'PA00046',
    message: 'Unexpected error extracting files',
  },
});
