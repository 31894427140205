const Enum = require('enum');

exports.operationalUiErrors = new Enum({
  PAYMENT_CARD_ERROR: {
    name: 'PAYMENT_CARD_ERROR',
    code: 'OU00001',
    message: 'The card cannot be added.',
  },
  ERROR_CHANGE_PAYMENT_METHOD: {
    name: 'ERROR_CHANGE_PAYMENT_METHOD',
    code: 'OU00002',
    message: 'The default payment method cannot be changed.',
  },
  REMOVE_CARD_ERROR: {
    name: 'REMOVE_CARD_ERROR',
    code: 'OU00003',
    message: 'The card cannot be removed.',
  },
  UPDATE_COMPANY_ERROR: {
    name: 'UPDATE_COMPANY_ERROR',
    code: 'OU00004',
    message: 'Cannot update company data.',
  },
  CANCEL_SUBCRIPTION_ERROR: {
    name: 'CANCEL_SUBCRIPTION_ERROR',
    code: 'OU00005',
    message: 'The subscription cannot be canceled.',
  },
  RETRIVE_TRANSACTION_ERROR: {
    name: 'RETRIVE_TRANSACTION_ERROR',
    code: 'OU00006',
    message: 'Can not retrieve transactions.',
  },
  RETRIVE_BILLING_ERROR: {
    name: 'RETRIVE_BILLING_ERROR',
    code: 'OU00007',
    message: 'Can not retrieve billing information.',
  },
  ERROR_SEND_INVITATIONS: {
    name: 'ERROR_SEND_INVITATIONS',
    code: 'OU00008',
    message: 'No invitation could be sent, please try again later.',
  },
  ERROR_CANCEL_INVITATION: {
    name: 'ERROR_CANCEL_INVITATION',
    code: 'OU00009',
    message: 'Unable to cancel the invitation, please try again later.',
  },
  ERROR_RESENT_INVITATION: {
    name: 'ERROR_RESENT_INVITATION',
    code: 'OU00010',
    message: 'Unable to resent the invitation, please try again later.',
  },
  ERROR_FIND_INVITED_USERS: {
    name: 'ERROR_FIND_INVITED_USERS',
    code: 'OU00011',
    message: 'Unable to find the invited members, please try again later.',
  },
  UPDATE_PASSWORD_ERROR: {
    name: 'UPDATE_PASSWORD_ERROR',
    code: 'OU00012',
    message: 'Cannot update your password at this moment.',
  },
  UNRESPONSE_DEVICE_ERROR: {
    name: 'UNRESPONSE_DEVICE_ERROR',
    code: 'OU00013',
    message: 'The device didn\'t respond.',
  },
  ERROR_CREATE_CONNECTION: {
    name: 'ERROR_CREATE_CONNECTION',
    code: 'OU00014',
    message: 'Error creating the connection:',
  },
  UNEXPECTED_CONNECTION_ERROR: {
    name: 'UNEXPECTED_CONNECTION_ERROR',
    code: 'OU00015',
    message: 'Unexpected error connecting to the device.',
  },
  UNCREATED_COMPANY: {
    name: 'UNCREATED_COMPANY',
    code: 'OU00016',
    message: 'The company cannot be created.',
  },
  GET_USER_ROLE_ERROR: {
    name: 'GET_USER_ROLE_ERROR',
    code: 'OU00017',
    message: 'Error getting the user role.',
  },
  UNMADE_PAYMENT: {
    name: 'UNMADE_PAYMENT',
    code: 'OU00018',
    message: 'The payment could not be made.',
  },
  ERROR_REMOVE_USER: {
    name: 'ERROR_REMOVE_USER',
    code: 'OU00019',
    message: 'Cannot remove the selected user, please try again later.',
  },
  AUTHENTICATION_ERROR: {
    name: 'AUTHENTICATION_ERROR',
    code: 'OU00020',
    message: 'An authentication error has occurred.',
  },
  UNEXIST_ACCOUNT: {
    name: 'UNEXIST_ACCOUNT',
    code: 'OU00021',
    message: 'That Boardgent account doesn\'t exist. Enter a different account or create one.',
  },
  GET_USER_INFO_ERROR: {
    name: 'GET_USER_INFO_ERROR',
    code: 'OU00023',
    message: 'Unable to get current user information, please refresh the page.',
  },
  UNCOPYRIGHTABLE_CLIPBOARD_EXECUTION: {
    name: 'UNCOPYRIGHTABLE_CLIPBOARD_EXECUTION',
    code: 'OU00024',
    message: 'Cannot copy the execution to clipboard.',
  },
  UNEXPECTED_ERROR: {
    name: 'UNEXPECTED_ERROR',
    code: 'OU00025',
    message: 'Unexpected error, please try again later.',
  },
  DATA_VALIDATION_ERROR: {
    name: 'DATA_VALIDATION_ERROR',
    code: 'OU00026',
    message: 'Error, please validate the data.',
  },
  UNEXPECTED_UPLOAD_ERROR: {
    name: 'UNEXPECTED_UPLOAD_ERROR',
    code: 'OU00027',
    message: 'Unexpected Error uploading the profile.',
  },
  CONNECTION_ALREADY_ESTABLISHED: {
    name: 'CONNECTION_ALREADY_ESTABLISHED',
    code: 'OU00028',
    message: 'Error connecting, another user is already connected to this device.',
  },
  ERROR_GETTING_WEBRTC_CONFIG: {
    name: 'ERROR_GETTING_WEBRTC_CONFIG',
    code: 'OA00029',
    message: 'Boardgent could not get the WebRTC signaling information.',
  },
  ERROR_GETTING_API_DATA: {
    name: 'ERROR_GETTING_API_DATA',
    code: 'OA00030',
    message: 'Error getting API data.',
  },
  UNEXIST_OR_DISABLED_ACCOUNT: {
    name: 'UNEXIST_OR_DISABLED_ACCOUNT',
    code: 'OU00031',
    message: 'That Boardgent account doesn\'t exist or it is disabled.',
  },
  REMOVE_PROFILE_ERROR: {
    name: 'REMOVE_PROFILE_ERROR',
    code: 'OU000032',
    message: 'The group cannot be deleted because it is in use',
  },
  UNCREATED_OR_UNUPDATED_EMPLOYEE: {
    name: 'UNCREATED_OR_UNUPDATED_EMPLOYEE',
    code: 'OU000033',
    message: 'The employee could not be created or updated.',
  },
  GET_AGENT_VERSIONS_ERROR: {
    name: 'GET_AGENT_VERSIONS_ERROR',
    code: 'OU000034',
    message: 'Unable to get all the agent versions, please try again later.',
  },
  ERROR_GETTING_STRIPE_SUBSCRIPTION: {
    name: 'ERROR_GETTING_STRIPE_SUBSCRIPTION',
    code: 'OU000035',
    message: 'We could not get your subscription data, please try again later.',
  },
});

exports.programmerUiErrors = new Enum({
  INVALID_RESPONSE: {
    name: 'INVALID_RESPONSE',
    code: 'PU00001',
    message: 'Invalid response.',
  },
  UPDATE_NAME_ERROR: {
    name: 'UPDATE_NAME_ERROR',
    code: 'PU00002',
    message: 'Cannot update your name at this moment.',
  },
  UPDATE_PHONE_ERROR: {
    name: 'UPDATE_PHONE_ERROR',
    code: 'PU00003',
    message: 'Cannot update your phone number at this moment.',
  },
  ERROR_SEND_ACTION: {
    name: 'ERROR_SEND_ACTION',
    code: 'PU00004',
    message: 'Error sending the action.',
  },
  GENERAL_ERROR: {
    name: 'GENERAL_ERROR',
    code: 'PU00005',
    message: 'A general error has ocurred.',
  },
  REMOVE_DEVICE_ERROR: {
    name: 'REMOVE_DEVICE_ERROR',
    code: 'PU00006',
    message: 'Error removing the device at this moment.',
  },
  REMOVE_DEVICES_ERROR: {
    name: 'REMOVE_DEVICES_ERROR',
    code: 'PU00007',
    message: 'Error removing selected devices at this moment.',
  },
});
