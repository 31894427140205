import feathersClient, { makeServicePlugin, BaseModel } from '@/store/feathersClient';

class Devices extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Devices'
}

const servicePath = 'devices';

const state = {
  companySelectedDevices: [],
};
const getters = {
  getSelectedDevices: (currentState) => (companyId) => {
    const result = currentState.companySelectedDevices.find((x) => x.companyId === companyId);
    if (result) {
      return result;
    }
    return { companyId: null, selectedDevices: [] };
  },
};
const actions = {
  updateSelectedDevices(context, { devices, companyId }) {
    context.commit('updateSelectedDevices', { devices, companyId });
  },
  deleteStoredSelectedDevices(context, { device, companyId }) {
    context.commit('deleteStoredSelectedDevices', { device, companyId });
  },
  deleteStore(context) {
    context.commit('deleteStore');
  },
};
const mutations = {
  updateSelectedDevices(currentState, { devices, companyId }) {
    const company = currentState.companySelectedDevices.find((x) => x.companyId === companyId);
    if (company) {
      company.selectedDevices = devices;
    } else {
      currentState.companySelectedDevices.push({ companyId, selectedDevices: devices });
    }
  },
  deleteStoredSelectedDevices(currentStateParam, { device, companyId }) {
    const currentState = currentStateParam;
    if (device && companyId) {
      if (device !== Object(device)) return;
      const company = currentState.companySelectedDevices.find((x) => x.companyId === companyId);
      if (company) {
        company.selectedDevices = company.selectedDevices.filter((item) => item !== device.id);
      }
    } else {
      currentState.companySelectedDevices = [];
    }
  },
  deleteStore(currentStateParam) {
    const currentState = currentStateParam;
    currentState.keyedById = {};
  },
};

const servicePlugin = makeServicePlugin({
  Model: Devices,
  service: feathersClient.service(servicePath),
  servicePath,
  state,
  getters,
  actions,
  mutations,
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
