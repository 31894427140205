const Enum = require('enum');

exports.managementAction = new Enum({
  SEND_RESET_PWD: 0,
  VALIDATE_RESET_PWD: 1,
  REQUEST_2FA: 2,
  ENABLE_2FA: 3,
  DISABLE_2FA: 4,
  REGENERATE_2FA_RECOVERY: 5,
  CHANGE_EMAIL: 6,
  VERIFY_EMAIL: 7,
  VALIDATE_VERIFY_EMAIL: 8,
});
