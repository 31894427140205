<template>
  <card
    internal-card="container card-container"
  >
    <card
      slot="body"
      external-card="card container material-card card-modal"
      internal-card="material-card-content"
      url-help=""
    >
      <div
        slot="header"
        class="columns"
        style="flex:1;flex-direction: row;justify-content: space-between;margin-bottom:0px"
      >
        <card
          internal-card="modal-card-body column"
          style="flex:2.5;"
          style-internal-card="padding-bottom:0px"
        >
          <p
            slot="header"
            class="subtitle"
          >
            <b>{{ title }}</b>
          </p>
          <div slot="body">
            <p v-html="message" />
            <b-field
              style="margin-top:6px"
              :type="displayRequiredInformationError ? 'is-danger' : ''"
              :message="displayRequiredInformationError ? 'This information is required' : ''"
            >
              <b-input
                v-if="expectedText"
                v-model="comparisonText"
                type="text"
                autocomplete="off"
              />
              <b-input
                v-if="field"
                ref="field"
                v-model="information.text"
                :type="passwordField ? 'password' : 'text'"
                :password-reveal="passwordField && information.text !== undefined
                  && information.text != ''"
                autocomplete="off"
              />
              <b-input
                v-if="getInformationFromTextArea"
                ref="getInformationFromTextArea"
                v-model="information.text"
                maxlength="200"
                type="textarea"
                autocomplete="off"
              />
            </b-field>
            <b-field
              style="margin-top:16px"
            >
              <b-checkbox
                v-if="showCheck"
                v-model="information.checkSelected"
              >
                {{ checkText }}
              </b-checkbox>
              <div
                v-if="radioOptions.length > 0"
              >
                <b-radio
                  v-for="option in radioOptions"
                  :key="option.name"
                  v-model="information.autounlock"
                  :name="option.name"
                  :native-value="option.value"
                >
                  {{ option.name }}
                </b-radio>
              </div>
            </b-field>
          </div>
        </card>
      </div>
      <footer
        v-if="!informationOnly"
        slot="external-footer"
        class="card-footer has-text-centered padding-card-footer"
      >
        <b-button
          v-if="$parent.canCancel"
          class="button is-outlined is-primary downloadButton force-right"
          @click="$parent.close()"
        >
          {{ cancelText }}
        </b-button>

        <b-button
          :icon-left="confirmIcon"
          class="button is-primary downloadButton force-right"
          :disabled="(expectedText && comparisonText.toLowerCase() !== expectedText.toLowerCase())"
          @click="confirm"
        >
          {{ confirmText }}
        </b-button>
      </footer>
    </card>
  </card>
</template>
<script>
import card from '@/components/cross/Card.vue';

export default {
  name: 'ModalConfirmation',
  components: {
    card,
  },
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    message: {
      type: String,
      default: 'Do you want to save changes?',
    },
    confirmText: {
      type: String,
      default: 'Accept',
    },
    confirmIcon: {
      type: String,
      default: null,
    },
    expectedText: {
      type: String,
      default: null,
    },
    field: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    informationOnly: {
      type: Boolean,
      default: false,
    },
    getInformationFromTextArea: {
      type: Boolean,
      default: false,
    },
    informationIsRequired: {
      type: Boolean,
      default: false,
    },
    showCheck: {
      type: Boolean,
      default: false,
    },
    checkText: {
      type: String,
      default: '',
    },
    radioOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    passwordField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      plusInterval: null,
      comparisonText: '',
      information: {},
      failedAttempToGetInformation: false,
    };
  },
  computed: {
    displayRequiredInformationError() {
      return this.failedAttempToGetInformation && !this.information.text;
    },
  },
  mounted() {
    if (this.getInformationFromTextArea) {
      this.$refs.getInformationFromTextArea.focus();
    } else if (this.field) {
      this.$refs.field.focus();
    }
  },
  methods: {
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.value += 1;
        if (this.value === 100) {
          clearInterval(this.plusInterval);
          this.value = 0;
          this.$parent.close();
          this.onConfirm();
        }
      }, 20);
    },
    onPlusStop() {
      clearInterval(this.plusInterval);
      this.value = 0;
    },
    confirm() {
      if (this.informationIsRequired && !this.information.text) {
        this.failedAttempToGetInformation = true;
        return;
      }
      if (this.radioOptions.length > 0 && this.information.autounlock === undefined) {
        this.failedAttempToGetInformation = true;
        return;
      }
      this.$parent.close();
      this.onConfirm(this.information);
    },
  },
};
</script>

<style>
.modal .card-container{
    max-width: 326px;
}
.modal footer button:nth-child(2){
    margin-left: 1rem;
}
@media (max-width: 768px) {
 .modal {
    padding: 1rem;
 }
}
.progress-container {
  margin: auto;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
}
</style>
