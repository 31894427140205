export default class Cross {
  static openExternalLink(url, createNewTab) {
    if (createNewTab) {
      const newTab = window.open(url, '_blank');
      if (!newTab) {
        // Old browsers do not support '_blank' or do not have it enabled to call it from JS.
        window.location.href = url;
      }
    } else {
      window.location.assign(url);
    }
  }
}
