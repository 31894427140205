<template>
  <b-breadcrumb
    ref="breadcrumb"
    :class="breadcrumbClass"
    :size="size"
  >
    <slot />
  </b-breadcrumb>
</template>
<script>

import { mapState } from 'vuex';

export default {
  name: 'Breadcrumb',
  data() {
    return {
      elements: 1,
      size: 'is-medium',
      hideFirstItem: false,
      hideSecondItem: false,
    };
  },
  computed: {
    ...mapState(['mainLayoutStyle']),
    breadcrumbClass() {
      return {
        hideFirstItem: this.hideFirstItem,
        hideSecondItem: this.hideSecondItem,
      };
    },
  },
  watch: {
    elements() {
      this.changeSizeOfBreadcrumb();
    },
    mainLayoutStyle() {
      this.changeSizeOfBreadcrumb();
    },
  },
  mounted() {
    this.countChildElements();
  },
  updated() {
    this.countChildElements();
  },
  methods: {
    countChildElements() {
      this.elements = this.$refs.breadcrumb.$children.length;
    },
    changeSizeOfBreadcrumb() {
      this.size = 'is-medium';
      this.hideFirstItem = false;
      this.hideSecondItem = false;

      if (this.elements === 2) {
        if (this.mainLayoutStyle.mainLayoutWidth < 550) this.size = 'is-small';
        if (this.mainLayoutStyle.mainLayoutWidth < 400) this.hideFirstItem = true;
      } else if (this.elements === 3 || this.elements === 4) {
        if (this.mainLayoutStyle.mainLayoutWidth < 720) this.size = 'is-small';
        if (this.mainLayoutStyle.mainLayoutWidth < 550) this.hideFirstItem = true;
        if (this.mainLayoutStyle.mainLayoutWidth < 400) this.hideSecondItem = true;
      }
    },
  },
};
</script>
<style>
.hideFirstItem > ul>li:nth-of-type(1) { display:none; }
.hideFirstItem > ul>li:nth-of-type(2):before { content:''; }

.hideSecondItem > ul>li:nth-of-type(2) { display:none; }
.hideSecondItem > ul>li:nth-of-type(3):before { content:''; }

</style>
