<template>
  <a
    class="navbar-item"
    href="https://status.boardgent.com/"
    target="_blank"
  >
    <span class="navbar-margin-to-icons">
      {{ description | lowercase | capitalize({ onlyFirstLetter: true }) }}</span>
    <status-indicator :status="statusColor" />
  </a>
</template>

<script>
export default {
  name: 'Status',
  data() {
    return {
      description: 'All systems operational',
      statusColor: '',
    };
  },
  created() {
    this.fetchStatus();
    // window.setInterval(() => { this.fetchStatus(); }, 30 * 1000);
  },
  methods: {
    async fetchStatus() {
      // const response = await this.axios.get('https://status.boardgent.com/api/v2/status.json');
      // if (response.status === 200) {
      //   this.description = response.data.status.description;
      //   switch (response.data.status.indicator) {
      //     case 'none':
      //       this.statusColor = 'positive';
      //       break;
      //     case 'minor':
      //       this.statusColor = 'intermediary';
      //       break;
      //     case 'major':
      //     case 'critical':
      //       this.statusColor = 'negative';
      //       break;
      //     default:
      //       this.statusColor = '';
      //       break;
      //   }
      // }
    },
  },
};
</script>
