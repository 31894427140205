const Enum = require('enum');

exports.shellAction = new Enum({
  PING: 0,
  PONG: 1,
  SET_AGENT_CONFIGURATIONS: 2,
  EXECUTE_ACTION: 3,
  ACTION_RESULT: 4,
});

exports.serviceMethods = new Enum({
  CREATE: 1,
  PATCH: 2,
  REMOVE: 3,
});
