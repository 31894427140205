const Enum = require('enum');

exports.passwordComplexity = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unknown',
  },
  UPPERCASE: {
    dbValue: 1,
    name: 'Uppercase letters',
  },
  LOWERCASE: {
    dbValue: 2,
    name: 'Lowercase letters',
  },
  NUMBERS: {
    dbValue: 3,
    name: 'Numbers',
  },
  SPECIAL_CHARACTERS: {
    dbValue: 4,
    name: 'Special characters',
  },
});

exports.passwordType = new Enum({
  BIOS_PASSWORD: {
    dbValue: 1,
    name: 'Bios pasword',
    table: 'bios-password',
    column: 'biosPasswordLastSuccessResultTimeStamp',
  },
  LOCAL_ACCOUNT_PASSWORD: {
    dbValue: 2,
    name: 'Local account password',
    table: 'local-user-password',
    column: 'passwordChangeLastSuccessResultTimeStamp',
  },
});
