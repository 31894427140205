const Enum = require('enum');
const { planCapabilities } = require('./planCapability.enum');

exports.dependencies = new Enum({
  BOARDGENT_NOTIFICATIONS: {
    id: 1,
    name: 'Boardgent notifications',
    folderName: 'boardgentNotifications',
    fileName: 'Boardgent-notifications',
    availableArchitectures: {
      win64: {
        name: 'Boardgent-notifications',
        hashaFilePath: 'resources/app.asar',
        type: 'zip',
        mainFile: 'Boardgent-notifications.exe',
      },
      win86: {
        name: 'Boardgent-notifications',
        hashaFilePath: 'resources/app.asar',
        type: 'zip',
        mainFile: 'Boardgent-notifications.exe',
      },
      mac64: {
        name: 'Boardgent-notifications',
        hashaFilePath: 'Boardgent-notifications.app/Contents/Resources/app.asar',
        type: 'zip',
        mainFile: 'Boardgent-notifications.app',
      },
      linux64: {
        name: 'Boardgent-notifications',
        hashaFilePath: 'resources/app.asar',
        type: 'zip',
        mainFile: 'Boardgent-notifications',
      },
    },
    requiredCapability: [
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
  },
  BOARDGENT_SCREEN_CAPTURE: {
    id: 2,
    name: 'Boardgent screen capture',
    folderName: 'boardgentScreenCapture',
    fileName: 'BoardgentScreenCapture',
    availableArchitectures: {
      win64: {
        name: 'Boardgent-screen-capture',
        hashaFilePath: 'BoardgentScreenCapture.exe',
        type: 'zip',
        mainFile: 'BoardgentScreenCapture.exe',
      },
      win86: {
        name: 'Boardgent-screen-capture',
        hashaFilePath: 'BoardgentScreenCapture.exe',
        type: 'zip',
        mainFile: 'BoardgentScreenCapture.exe',
      },
      mac64: {
        name: 'Boardgent-screen-capture',
        hashaFilePath: 'BoardgentScreenCapture',
        type: 'zip',
        mainFile: 'BoardgentScreenCapture',
      },
      linux64: {
        name: 'Boardgent-screen-capture',
        hashaFilePath: 'BoardgentScreenCapture',
        type: 'zip',
        mainFile: 'BoardgentScreenCapture',
      },
    },
    requiredCapability: [
      planCapabilities.REMOTE_DESKTOP.value,
    ],
  },
});

exports.auvikDependencies = new Enum({
  SIGNIFI: {
    id: 1,
    name: 'Signifi',
    folderName: 'netmon',
    availableArchitectures: {
      windows: {
        compressedFileName: 'AuvikEndpointNetMon.zip',
        mainFile: 'AuvikEndpointNetMon/AuvikEndpointNetMon.exe',
      },
      macos: {
        executable: 'AuvikEndpointNetMon.app',
        mainFile: 'AuvikEndpointNetMon.app/Contents/MacOS/AuvikEndpointNetMon',
      },
    },
  },
  SHELL: {
    id: 2,
    name: 'Agent-shell',
    folderName: 'shell',
    availableArchitectures: {
      macos: {
        mainFile: 'AuvikEndpointShell',
      },
      windows: {
        compressedFileName: 'AuvikEndpointShell.zip',
        mainFile: 'AuvikEndpointShell.exe',
      },
    },
  },
});
