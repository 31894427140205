<template>
  <section>
    <b-modal
      style="overflow: none"
      :active.sync="isCardModalActive"
      :can-cancel="[false,false,false]"
      scroll="clip"
    >
      <card
        internal-card="card-container"
        style="display: flex; justify-content:center"
      >
        <card
          slot="body"
          external-card="card material-card card-modal"
          internal-card="material-card-content"
          url-help=""
          style="width: 28rem;"
        >
          <p
            slot="header"
            class="subtitle"
          >
            <b>Lost connection with Boardgent</b>
          </p>
          <div
            slot="body"
            v-html="
              `<p>Please check your connection to continue.</p>`
            "
          />
        </card>
      </card>
    </b-modal>
  </section>
</template>

<script>
import card from '@/components/cross/Card.vue';

export default {
  name: 'ApiLost',
  components: {
    card,
  },
  data() {
    return {
      isCardModalActive: true,
    };
  },
};
</script>
