import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import createPersistedState from 'vuex-persistedstate';
import { FeathersVuex } from './feathersClient';
import auth from './store.auth';
import preferencesModule from './preferences';

Vue.use(FeathersVuex);
Vue.use(Vuex);

const requireContext = require.context('@/services', false, /.js$/);
const services = requireContext.keys().map((modulePath) => requireContext(modulePath).default);

export default createStore(Vuex.Store, {
  state: {
    mainLayoutStyle: {},
  },
  modules: {
    preferencesModule,
  },
  plugins: [
    ...services,
    auth,
    createPersistedState({
      key: 'boardgent-settings',
      paths: ['devices.companySelectedDevices', 'preferencesModule'],
    }),
  ],
  mutations: {
    clearCurrentSelections() {
      this.commit('companies/deleteStoredCurrentCompany');
      this.commit('memberships/deleteStoredCurrentRole');
    },
    setMainLayoutStyle(state, style) {
      state.mainLayoutStyle = style;
    },
  },
});
