export default {
  name: 'AnalyticsMixin',
  methods: {
    startOrStopHighlight(start) {
      if (this.$highlight) {
        if (start) this.$highlight.start({ silent: true });
        else this.$highlight.stop();
      }
    },
    trackEvent(shouldSend, event, name, data) {
      try {
        // Highlight logic
        if (this.$highlight) {
          switch (event) {
            case 'track':
              this.$highlight.track(name, data);
              break;
            case 'identify':
              this.$highlight.identify(data.email, data);
              break;
            default:
              break;
          }
        }

        // Segment logic
        if (!shouldSend) return;
        // eslint-disable-next-line no-undef
        const localAnalytics = window.analytics;
        if (localAnalytics) {
          localAnalytics[event](name, data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
};
