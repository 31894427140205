const Enum = require('enum');

exports.hardwareTypes = new Enum({
  BIOS: {
    dbValue: 1,
    name: 'BIOS',
    sortPosition: 3,
  },
  BOARD: {
    dbValue: 2,
    name: 'Board',
    sortPosition: 2,
  },
  CPU: {
    dbValue: 3,
    name: 'CPU',
    sortPosition: 4,
  },
  GRAPHICS_CONTROLLER: {
    dbValue: 4,
    name: 'Graphics controller',
    sortPosition: 6,
  },
  MEMORY: {
    dbValue: 5,
    name: 'Memory',
    sortPosition: 7,
  },
  STORAGE: {
    dbValue: 6,
    name: 'Storage',
    sortPosition: 8,
  },
  CHASSIS: {
    dbValue: 7,
    name: 'Chassis',
    sortPosition: 1,
  },
  TPM: {
    dbValue: 8,
    name: 'TPM',
    sortPosition: 5,
  },
  BATTERY: {
    dbValue: 9,
    name: 'Battery',
    sortPosition: 9,
  },
  PRINTER: {
    dbValue: 10,
    name: 'Printer',
  },
  AUDIO: {
    dbValue: 11,
    name: 'Audio',
  },
  KEYBOARD: {
    dbValue: 12,
    name: 'Keyboard',
  },
  MOUSE: {
    dbValue: 12,
    name: 'Mouse',
  },
  DISPLAY: {
    dbValue: 13,
    name: 'Display',
  },
});
