const Enum = require('enum');

exports.windowsLicense = new Enum({
  UNKNOWN: {
    dbValue: -1,
    name: 'Unknown',
  },
  UNLICENSED: {
    dbValue: 0,
    name: 'Unlicensed',
  },
  LICENSED: {
    dbValue: 1,
    name: 'Licensed',
  },
  OUT_OF_BOX_GRACE_PERIOD: {
    dbValue: 2,
    name: 'Out of box grace period',
  },
  OUT_OF_TOLERANCE_GRACE_PERIOD: {
    dbValue: 3,
    name: 'Out of tolerance grace period',
  },
  NON_GENUINE_GRACE_PERIOD: {
    dbValue: 4,
    name: 'Non genuine grace period',
  },
  NOT_ACTIVATED: {
    dbValue: 5,
    name: 'No activated',
  },
  EXTENDED_GRACE: {
    dbValue: 6,
    name: 'Extended Grace',
  },
});

exports.officeLicense = new Enum({
  UNKNOWN: {
    dbValue: -1,
    name: 'Unknown',
  },
  UNLICENSED: {
    dbValue: 0,
    name: 'Unlicensed',
  },
  LICENSED: {
    dbValue: 1,
    name: 'Licensed',
  },
  OUT_OF_BOX_GRACE_PERIOD: {
    dbValue: 2,
    name: 'Out of box grace period',
  },
  OUT_OF_TOLERANCE_GRACE_PERIOD: {
    dbValue: 3,
    name: 'Out of tolerance grace period',
  },
  NON_GENUINE_GRACE_PERIOD: {
    dbValue: 4,
    name: 'Non genuine grace period',
  },
  NOTIFICATION: {
    dbValue: 5,
    name: 'Notification',
  },
  EXTENDED_GRACE: {
    dbValue: 6,
    name: 'Extended_Grace',
  },
});
