const Enum = require('enum');

exports.fileVaultStatus = new Enum({
  UNKNOWN: {
    dbValue: 0,
    name: 'Unkown',
    message: '',
  },
  FULLY_DECRYPTED: {
    dbValue: 1,
    name: 'Fully decrypted',
    message: 'FileVault is Off.',
  },
  FULLY_ENCRYPTED: {
    dbValue: 2,
    name: 'Fully encrypted',
    message: 'FileVault is On.',
  },
  ENCRYPTION_IN_PROGRESS: {
    dbValue: 3,
    name: 'Encryption in progress',
    message: 'Encryption in progress: Percent completed = (100|[1-9][0-9]|[0-9])',
  },
  DECRYPTION_IN_PROGRESS: {
    dbValue: 4,
    name: 'Descryption in progress',
    message: 'Decryption in progress: Percent completed = (100|[1-9][0-9]|[0-9])',
  },
  DEFERRED_ENABLEMENT: {
    dbValue: 5,
    name: 'Deferred enablement',
    message: 'Deferred enablement appears to be active for user',
  },
});
