<template>
  <div :class="externalCard">
    <slot name="Pre-header">
      <div :class="middleCard">
        <slot name="external-header" />
        <slot name="external-body">
          <div
            :class="internalCard"
            :style="styleInternalCard"
          >
            <div
              :style="styleInternalHeader"
            >
              <slot name="header" />
              <learn-more
                :url="urlHelp"
                :help-text="helpText"
              />
            </div>
            <slot name="body" />
            <slot name="footer" />
          </div>
        </slot>
        <slot name="external-footer" />
      </div>
    </slot>
  </div>
</template>

<script>

import learnMore from '@/components/cross/LearnMore.vue';

export default {
  name: 'Card',
  components: {
    learnMore,
  },
  props: {
    urlHelp: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    externalCard: {
      type: String,
      default: '',
    },
    middleCard: {
      type: String,
      default: '',
    },
    internalCard: {
      type: String,
      default: '',
    },
    styleInternalHeader: {
      type: String,
      default: 'display: flex',
    },
    styleInternalCard: {
      type: String,
      default: '',
    },
  },
};
</script>
